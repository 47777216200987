import { useParams, useSearchParams } from 'react-router-dom';
import {
  Analytics as AnalyticsType,
  ANALYTICS_PROVIDER,
  PAYMENT_SYSTEMS,
  Payments,
  PAYWALL_TYPE,
  Paywalls,
} from '@organicapps/organictypes';
import Analytics, { IEventPayload, UserProperties } from '../api/analytics';
import {
  conversionMapper,
  EVENT_TYPES,
  EVENTS,
  fbqMapper,
  googleEventsMapper,
  ONE_TIME_PURCHASE_EVENT,
  pinterestEventsMapper,
  secondaryProductsMapper,
  tiktokEventsMapper,
  upsellsMapper,
  upsellsPurchaseEvents,
} from '../constants/analytics';
import { usePaywallsStore, useUserStore } from '../store';
import { IProductPageParams } from '../HOCs/withProductGuard';
import ApiClients from '../api';
import { ANALYTICS_KEYS, INTERCOM_API, INTERCOM_APP_ID } from '../constants/envKeysMapper';
import { IPagesParams } from '../pages/Offer';
import { filterObject } from '../helpers/filterObject';
import { useLogger } from './useLogger';
import getCookieValue from '../helpers/getCookieValue';
import { includes } from '../helpers/includes';
import { SEARCH_PARAMS } from '../constants/searchParams';

export const useAnalytics = () => {
  const [searchParams] = useSearchParams();
  const { productName } = useParams<keyof IProductPageParams>() as IProductPageParams;
  const { onboardingId } = useParams<keyof IPagesParams>() as IPagesParams;

  const analyticsClient = ApiClients.getAnalyticsClient();
  const logger = useLogger();

  const { slug, selectedPaywallList, settings, paywallId } = usePaywallsStore((state) => ({
    slug: state.slug,
    selectedPaywallList: state.selectedPaywallList as Paywalls.IPaywallList,
    paywallType: state.settings.paywallType,
    settings: state.settings,
    paywallId: state._id,
    upsellsList: state.upsellsList,
  }));

  const { localCurrency, exchangeRate } = useUserStore((state) => ({
    localCurrency: state.localCurrency,
    exchangeRate: state.exchangeRate,
  }));

  const analyticsInstance = Analytics(analyticsClient);

  const getAnalyticsKey = (type: ANALYTICS_PROVIDER, settings?: AnalyticsType.UserAnalyticsSettings) => {
    const defaultKey = ANALYTICS_KEYS[type]?.[productName];
    return settings?.[type]?.pixelId || defaultKey;
  };

  const reInitFacebookAnalytics = () => {
    const settings = useUserStore.getState()?.analyticsSettings;
    const key = getAnalyticsKey(ANALYTICS_PROVIDER.FACEBOOK, settings);
    if (productName && key) {
      analyticsInstance.initFbPixel(key, true);
    }
  };

  const logEvent = async (_payload: IEventPayload) => {
    const logger = [];

    // todo: temporary solution
    //  (добавлено переопределение свойств ивента изза необходимости апдейта полей пользователя для дальнейших ребиллов)
    let payload = _payload;
    if (fbqMapper[_payload.name as keyof typeof fbqMapper]) {
      const fbp = getCookieValue('_fbp');
      const fbc = getCookieValue('_fbc');
      payload = {
        ...payload,
        properties: { ...payload.properties, fbp, fbc },
        userProperties: { ...payload.userProperties, fbp, fbc },
      };
    }

    if (tiktokEventsMapper[_payload.name as keyof typeof tiktokEventsMapper]) {
      const ttp = getCookieValue('_ttp');
      const ttclid = searchParams.get(SEARCH_PARAMS.TIKTOK_CLICK_ID);
      payload = {
        ...payload,
        properties: { ...payload.properties, ttp, ttclid },
        userProperties: { ...payload.userProperties, ttp, ttclid },
      };
    }

    if (payload.userProperties && Object.entries(payload.userProperties).length) {
      logger.push(analyticsInstance.updateUserProperties(payload.userProperties));
    }

    // only to handle upsells purchase events
    if (includes(upsellsPurchaseEvents, payload.name)) {
      logger.push(analyticsInstance.logToAmplitude(payload));
      Promise.all(logger);
      return;
    }

    if (
      settings.paywallType !== PAYWALL_TYPE.OFFER &&
      !payload.name.includes('Clicked') &&
      !payload.name.includes('PageView')
    ) {
      // eslint-disable-next-line no-param-reassign
      payload.name = `${secondaryProductsMapper[settings.paywallType]} ${payload.name}`.trim();
    }
    if (fbqMapper[payload.name as keyof typeof fbqMapper]) {
      if (payload.name === EVENTS.FINISH_PURCHASE) {
        reInitFacebookAnalytics();
      }
      logger.push(analyticsInstance.logToFb(payload));
    }
    if (googleEventsMapper[payload.name as keyof typeof googleEventsMapper]) {
      logger.push(analyticsInstance.logToGoogle(payload));
    }
    if (tiktokEventsMapper[payload.name as keyof typeof tiktokEventsMapper]) {
      logger.push(analyticsInstance.logToTiktok(payload));
    }
    if (pinterestEventsMapper[payload.name as keyof typeof pinterestEventsMapper]) {
      logger.push(analyticsInstance.logToPinterest(payload));
    }
    if (conversionMapper[payload.name as keyof typeof conversionMapper]) {
      const eventName = conversionMapper[payload.name as keyof typeof conversionMapper];
      // Because of not all non-offer events should send one-time purchase event
      if (!(eventName === ONE_TIME_PURCHASE_EVENT && selectedPaywallList.price.interval)) {
        logger.push(analyticsInstance.logToConversion(payload));
      }
    }
    logger.push(analyticsInstance.logUserEvent(payload));
    logger.push(analyticsInstance.logToAmplitude(payload));
    logger.push(analyticsInstance.logToIntercom(payload));
    logger.push(analyticsInstance.logToVWO(payload, productName));

    Promise.all(logger);
  };

  const logUserProperties = async (properties: UserProperties) => {
    analyticsInstance.updateUserProperties(properties);
  };

  const logUtmParams = async (payload: Record<string, string | null>) => {
    try {
      if (onboardingId) {
        const filteredPayload = filterObject<Record<string, string>>(payload);
        await analyticsClient.updateUtmProperties(filteredPayload, onboardingId);

        logEvent({
          type: EVENT_TYPES.ACTION,
          name: EVENTS.UTM_SOURCE_CHANGE,
          properties: {
            ...filteredPayload,
          },
          userProperties: {
            ...filteredPayload,
            'Utm Source': filteredPayload.utmSource,
            // Bug in amplitude here
            'Utm  Source': filteredPayload.utmSource,
          },
        });
      }
    } catch (e) {
      logger.logError(e as Error);
    }
  };

  const initAnalytics = () => {
    const bindings = {
      [ANALYTICS_PROVIDER.AMPLITUDE]: analyticsInstance.initAmplitude.bind(analyticsInstance),
      [ANALYTICS_PROVIDER.FACEBOOK]: analyticsInstance.initFbPixel.bind(analyticsInstance),
      [ANALYTICS_PROVIDER.GOOGLE]: analyticsInstance.initGoogleTag.bind(analyticsInstance),
      [ANALYTICS_PROVIDER.TIKTOK]: analyticsInstance.iniTiktok.bind(analyticsInstance),
      [ANALYTICS_PROVIDER.PINTEREST]: analyticsInstance.initPinterest.bind(analyticsInstance),
    };
    // To avoid closure, while using initAnalytics,
    // coz initAnalytics in useInitState, useInitDivkitState, etc..., has initial version of store (executes in same useEffect)
    const settings = useUserStore.getState()?.analyticsSettings;
    (Object.keys(bindings) as ANALYTICS_PROVIDER[]).forEach((type) => {
      const analyticsKey = getAnalyticsKey(type, settings);
      const boundFunction = bindings[type];
      if (productName && analyticsKey && boundFunction) {
        boundFunction(analyticsKey);
      }
    });

    analyticsInstance.initIntercom(INTERCOM_APP_ID, INTERCOM_API);
  };

  const mapIfUpsellPayment = <T extends keyof typeof upsellsMapper>(eventName: T): T | typeof upsellsMapper[T] => {
    return selectedPaywallList.options.upsells ? upsellsMapper[eventName] : eventName;
  };

  const logStartPurchase = async ({ provider, system }: Payments.IPaymentSystemData) => {
    const { firstName, lastName } = useUserStore.getState();
    logEvent({
      name: mapIfUpsellPayment(EVENTS.START_PURCHASE),
      type: EVENT_TYPES.ACTION,
      properties: {
        ProductId: selectedPaywallList.price.data
          ? selectedPaywallList.price.data[system as Exclude<PAYMENT_SYSTEMS, PAYMENT_SYSTEMS.AUTHORIZE>]
          : null,
        Price: selectedPaywallList.price.price,
        Interval: selectedPaywallList.price.interval,
        'Interval Count': selectedPaywallList.price.intervalCount,
        Description: slug,
        paywallVariant: settings.variant,
        paywallVersion: settings.version,
        PaymentSystem: system,
        currency: selectedPaywallList.price.currency,
        localCurrency,
        exchangeRate,
        provider,
        trial: !!selectedPaywallList.price?.trial,
        trialPrice: selectedPaywallList.price.trial?.price,
        trialInterval: selectedPaywallList.price.trial?.interval,
        trialIntervalCount: selectedPaywallList.price.trial?.intervalCount,
        priceId: selectedPaywallList.price._id,
        oldPrice: !!selectedPaywallList?.priceDetails,
        oldPriceDiscount: selectedPaywallList?.priceDetails?.discount,
        oldPriceOriginalPrice: selectedPaywallList?.priceDetails?.originalPrice,
        popupVariant: slug,
        paywallId,
      },
      userProperties: {
        firstName,
        lastName,
        popupVariant: slug,
        webPopupVariant: slug,
      },
    });
  };

  const logFinishPurchase = async ({ provider, system }: Payments.IPaymentSystemData) => {
    logEvent({
      name: mapIfUpsellPayment(EVENTS.FINISH_PURCHASE),
      type: EVENT_TYPES.ACTION,
      properties: {
        ProductId: selectedPaywallList.price.data
          ? selectedPaywallList.price.data[system as Exclude<PAYMENT_SYSTEMS, PAYMENT_SYSTEMS.AUTHORIZE>]
          : null,
        Price: selectedPaywallList.price.price,
        Interval: selectedPaywallList.price.interval,
        'Interval Count': selectedPaywallList.price.intervalCount,
        Description: slug,
        PaymentSystem: system,
        currency: selectedPaywallList.price.currency,
        localCurrency,
        exchangeRate,
        provider,
        paywallVariant: settings.variant,
        paywallVersion: settings.version,
        trial: !!selectedPaywallList.price?.trial,
        trialPrice: selectedPaywallList.price.trial?.price,
        trialInterval: selectedPaywallList.price.trial?.interval,
        trialIntervalCount: selectedPaywallList.price.trial?.intervalCount,
        priceId: selectedPaywallList.price._id,
        oldPrice: !!selectedPaywallList?.priceDetails,
        oldPriceDiscount: selectedPaywallList?.priceDetails?.discount,
        oldPriceOriginalPrice: selectedPaywallList?.priceDetails?.originalPrice,
        popupVariant: slug,
        paywallId,
      },
      userProperties: {
        popupVariant: slug,
        webPopupVariant: slug,
      },
    });
  };

  const logPaymentError = async ({ provider, system }: Payments.IPaymentSystemData, message: unknown) => {
    logEvent({
      name: mapIfUpsellPayment(EVENTS.PAYMENT_TRANSACTION_ERROR),
      type: EVENT_TYPES.ACTION,
      properties: {
        ProductId: selectedPaywallList.price.data
          ? selectedPaywallList.price.data[system as Exclude<PAYMENT_SYSTEMS, PAYMENT_SYSTEMS.AUTHORIZE>]
          : null,
        Description: slug,
        PaymentSystem: system,
        Error: JSON.stringify(message),
        currency: selectedPaywallList.price.currency,
        localCurrency,
        exchangeRate,
        provider,
        Price: selectedPaywallList.price.price,
        Interval: selectedPaywallList.price.interval,
        'Interval Count': selectedPaywallList.price.intervalCount,
        paywallVariant: settings.variant,
        paywallVersion: settings.version,
        trial: !!selectedPaywallList.price?.trial,
        trialPrice: selectedPaywallList.price.trial?.price,
        trialInterval: selectedPaywallList.price.trial?.interval,
        trialIntervalCount: selectedPaywallList.price.trial?.intervalCount,
        priceId: selectedPaywallList.price._id,
        oldPrice: !!selectedPaywallList?.priceDetails,
        oldPriceDiscount: selectedPaywallList?.priceDetails?.discount,
        oldPriceOriginalPrice: selectedPaywallList?.priceDetails?.originalPrice,
        popupVariant: slug,
        paywallId,
      },
    });
  };

  return {
    logEvent,
    logUserProperties,
    logStartPurchase,
    logFinishPurchase,
    logPaymentError,
    initAnalytics,
    logUtmParams,
    analyticsInstance,
  };
};
