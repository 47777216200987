import React, { createContext, FC, PropsWithChildren, useMemo } from 'react';
import { useLogger } from '../hooks/useLogger';
import GlobalVariablesFactory, { type GlobalVariables } from '../modules/global-variables';

export const GlobalVariablesContext = createContext<GlobalVariables>(GlobalVariablesFactory());

export const GlobalVariablesProvider: FC<PropsWithChildren> = ({ children }) => {
  const logger = useLogger();
  const globalVariables = useMemo(() => GlobalVariablesFactory({ logger }), [logger]);

  return <GlobalVariablesContext.Provider value={globalVariables}>{children}</GlobalVariablesContext.Provider>;
};
