import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { LINKS } from '../../constants/links';

const Container = styled(Box)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const NotFound = () => {
  useEffect(() => {
    window.location.href = LINKS.WEBSITE;
  }, []);

  return (
    <Container>
      <Typography variant='h2'>404</Typography>
      <Typography variant='body1'>Oops! This page was not found.</Typography>
    </Container>
  );
};

export default NotFound;
