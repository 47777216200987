import React from 'react';

const Walk = () => {
  return (
    <>
      <path
        d='M7.84323 16.2053C6.63576 16.2053 5.46363 16.4701 4.46335 17.0387C3.38783 17.6501 2.50641 18.6036 1.85727 19.6855C1.30044 20.66 0.882812 21.9129 0.882812 23.1657C2.13569 23.1657 3.38856 22.7481 4.36302 22.1913C6.45115 20.9384 7.84323 18.8503 7.84323 16.2053Z'
        fill='#777DF5'
      />
      <path
        className='title'
        d='M7.84323 16.2053C6.63576 16.2053 5.46363 16.4701 4.46335 17.0387C3.38783 17.6501 2.50641 18.6036 1.85727 19.6855C1.30044 20.66 0.882812 21.9129 0.882812 23.1657C2.13569 23.1657 3.38856 22.7481 4.36302 22.1913C6.45115 20.9384 7.84323 18.8503 7.84323 16.2053Z'
        fill='black'
        fillOpacity='0.25'
      />
      <path
        d='M14.8037 2.28442H7.84323C5.33748 2.28442 3.11015 3.67651 1.85727 5.76463C1.30044 6.73909 0.882812 7.99197 0.882812 9.24485V16.2053C0.882812 14.9524 1.30044 13.6995 1.85727 12.7251C2.41411 11.7506 3.38856 10.7761 4.36302 10.2193C5.33748 9.66247 6.59036 9.24485 7.84323 9.24485C10.349 9.24485 12.5763 7.85276 13.8292 5.76463C14.386 4.79018 14.8037 3.5373 14.8037 2.28442Z'
        fill='#78E17C'
      />
      <path
        d='M7.84323 9.24487C6.59036 9.24487 5.33748 9.6625 4.36302 10.2193C3.38856 10.7762 2.41411 11.7506 1.85727 12.7251C1.30044 13.6995 0.882812 14.9524 0.882812 16.2053V23.1657C0.882812 21.9128 1.30044 20.66 1.85727 19.6855C3.11015 17.5974 5.19827 16.2053 7.84323 16.2053C7.84323 16.2053 8.81769 16.2053 9.23532 16.0661C11.1842 15.6485 12.8547 14.3956 13.8292 12.7251C14.386 11.7506 14.8037 10.4977 14.8037 9.24487H7.84323Z'
        fill='#777DF5'
      />
      <path
        className='title'
        d='M65.8032 14.3279C65.8032 16.3484 67.4459 17.6924 69.9934 17.6924C71.4341 17.6924 72.7693 17.2356 73.6039 16.5153L74.3154 17.2532C74.535 17.4816 74.8601 17.6221 75.1675 17.6221C75.7649 17.6221 76.248 17.1302 76.248 16.5416C76.248 16.2693 76.1514 16.0234 75.9581 15.8213L75.1675 14.9956C75.8 14.161 76.1689 12.9312 76.1689 11.5784V11.227C76.1689 10.5857 75.7034 10.1026 75.0884 10.1026C74.4823 10.1026 74.0255 10.5857 74.0255 11.2446V11.4291C74.0255 12.3339 73.9025 12.9927 73.6478 13.4407L71.5922 11.2094C73.1998 10.331 73.9728 9.32077 73.9728 8.09973C73.9728 6.4131 72.5585 5.26233 70.5381 5.26233C68.5264 5.26233 67.0331 6.47459 67.0331 8.15243C67.0331 9.03088 67.4284 9.76878 68.5089 10.8756C68.2629 11.0074 67.9203 11.1919 67.7534 11.3061C66.4269 12.1055 65.8032 13.0981 65.8032 14.3279ZM70.336 10.0411C69.5454 9.27685 69.2819 8.85519 69.2819 8.29298C69.2819 7.56387 69.8002 7.05437 70.5117 7.05437C71.2233 7.05437 71.7152 7.55509 71.7152 8.30177C71.7152 9.0221 71.3199 9.49646 70.336 10.0411ZM69.2819 12.5008C69.3697 12.4217 69.6069 12.2812 69.7035 12.246L72.2423 14.9868C71.803 15.4699 71.0651 15.7686 70.3009 15.7686C69.115 15.7686 68.3156 15.101 68.3156 14.1083C68.3156 13.4758 68.6231 12.94 69.2819 12.5008Z'
        fill='black'
      />
      <path
        className='title'
        d='M37.8782 5.07725C37.3446 5.07725 36.8465 5.37964 36.722 5.84211L34.4808 13.8642L31.6348 5.8599C31.4214 5.27291 30.9767 5.07725 30.5498 5.07725C30.1051 5.07725 29.7138 5.27291 29.5003 5.8599L26.6544 13.8642L24.3954 5.84211C24.2709 5.37964 23.7728 5.07725 23.257 5.07725C22.5988 5.07725 22.083 5.5753 22.083 6.16228C22.083 6.41131 22.1719 6.64254 22.2431 6.83821L25.2847 16.7814C25.4804 17.3861 25.9607 17.6707 26.5654 17.6707C27.1702 17.6707 27.5971 17.3861 27.8105 16.7814L30.5676 9.31065L33.3068 16.7814C33.5203 17.3861 33.9472 17.6707 34.5698 17.6707C35.1745 17.6707 35.6548 17.3861 35.8327 16.7814L38.8921 6.83821C38.9455 6.64254 39.0344 6.41131 39.0344 6.16228C39.0344 5.5753 38.5363 5.07725 37.8782 5.07725Z'
        fill='black'
      />
      <path
        className='title'
        d='M47.1629 8.27898C46.5226 8.27898 45.989 8.8126 45.989 9.43516V9.73755C45.6332 8.90154 44.6194 8.22562 43.3564 8.22562C41.4176 8.22562 39.0519 9.79091 39.0519 12.9571C39.0519 16.1944 41.3998 17.7063 43.3564 17.7063C44.9039 17.7063 45.8467 16.7458 45.989 16.1944V16.5323C45.989 17.1727 46.5226 17.6885 47.1629 17.6885C47.7855 17.6885 48.3547 17.1727 48.3547 16.5323V9.43516C48.3547 8.8126 47.7855 8.27898 47.1629 8.27898ZM43.7833 15.6963C42.6094 15.6963 41.382 14.7892 41.382 12.9571C41.382 11.1428 42.6094 10.2178 43.7833 10.2178C44.7794 10.2178 46.0957 10.9471 46.0957 12.9571C46.0957 14.967 44.7794 15.6963 43.7833 15.6963Z'
        fill='black'
      />
      <path
        className='title'
        d='M52.5207 16.5145V6.03777C52.5207 5.41521 51.9871 4.88159 51.3645 4.88159C50.7242 4.88159 50.1906 5.41521 50.1906 6.03777V16.5145C50.1906 17.1549 50.7242 17.6707 51.3645 17.6707C51.9871 17.6707 52.5207 17.1549 52.5207 16.5145Z'
        fill='black'
      />
      <path
        className='title'
        d='M62.499 15.9453L59.3151 12.459L61.8765 10.0755C62.0543 9.87985 62.1611 9.63082 62.1611 9.32844C62.1611 8.77703 61.6986 8.27898 61.1116 8.27898C60.8092 8.27898 60.5246 8.42128 60.329 8.61694L56.807 12.0677V6.03777C56.807 5.41521 56.2734 4.88159 55.6509 4.88159C55.0105 4.88159 54.4769 5.41521 54.4769 6.03777V16.5145C54.4769 17.1549 55.0105 17.6707 55.6509 17.6707C56.2734 17.6707 56.807 17.1549 56.807 16.5145V14.807L57.8743 13.8109L61.0582 17.3506C61.2183 17.5462 61.4851 17.6707 61.7697 17.6707C62.3567 17.6707 62.8014 17.1905 62.8014 16.6568C62.8014 16.3545 62.6591 16.1054 62.499 15.9453Z'
        fill='black'
      />
      <path
        className='title'
        d='M89.0614 6.1623C89.0614 5.59311 88.5633 5.13063 87.9941 5.13063H82.0887C81.3239 5.13063 80.7725 5.68204 80.7725 6.4469V16.479C80.7725 17.1371 81.3239 17.7063 81.982 17.7063C82.6401 17.7063 83.1738 17.1371 83.1738 16.479V12.6547H87.1226C87.6918 12.6547 88.172 12.21 88.172 11.6408C88.172 11.0538 87.6918 10.6091 87.1226 10.6091H83.1738V7.22954H87.9941C88.5633 7.22954 89.0614 6.74929 89.0614 6.1623Z'
        fill='black'
      />
      <path
        className='title'
        d='M91.757 7.0161C92.3796 7.0161 92.931 6.48247 92.931 5.85992C92.931 5.23736 92.3796 4.70374 91.757 4.70374C91.1167 4.70374 90.5653 5.23736 90.5653 5.85992C90.5653 6.48247 91.1167 7.0161 91.757 7.0161ZM91.757 8.279C91.1167 8.279 90.583 8.81262 90.583 9.45297V16.4968C90.583 17.1549 91.1167 17.6885 91.757 17.6885C92.3796 17.6885 92.9132 17.1549 92.9132 16.4968V9.45297C92.9132 8.81262 92.3796 8.279 91.757 8.279Z'
        fill='black'
      />
      <path
        className='title'
        d='M99.2087 15.5896C99.013 15.5896 98.7284 15.6608 98.5328 15.6608C98.1414 15.6608 97.9636 15.394 97.9636 14.8781V10.1645H99.1375C99.7245 10.1645 100.08 9.79093 100.08 9.25731C100.08 8.7059 99.7245 8.33236 99.1375 8.33236H97.9636V7.12282C97.9636 6.48247 97.4477 5.94885 96.8074 5.94885C96.167 5.94885 95.6334 6.48247 95.6334 7.12282V8.33236H95.1176C94.5484 8.33236 94.1748 8.7059 94.1748 9.25731C94.1748 9.79093 94.5484 10.1645 95.1176 10.1645H95.6334V14.807C95.6334 17.2794 96.8607 17.6707 98.0703 17.6707C99.2798 17.6707 100.08 17.2438 100.08 16.4256C100.08 15.8742 99.7423 15.554 99.2087 15.5896Z'
        fill='black'
      />
    </>
  );
};

export default Walk;
