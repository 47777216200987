import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Products } from '@organicapps/organictypes';
import { usePaywallsStore, useSettingsStore, useUserStore } from '../store';
import ApiClients from '../api';
import { IPagesParams } from '../pages/Offer';
import { useAnalytics } from './useAnalytics';
import { PAGES } from '../constants/pages';
import { useRouterWrapper } from './useRouterWrapper';
import { SEARCH_PARAMS } from '../constants/searchParams';
import { useLogger } from './useLogger';

export const useInitState = (productName: Products.Names) => {
  const [isLoading, setIsLoading] = useState(true);
  const { onboardingId } = useParams<keyof IPagesParams>() as IPagesParams;
  const go = useRouterWrapper();
  const [searchParams] = useSearchParams();
  const logger = useLogger();

  const { initAnalytics } = useAnalytics();

  const { setUserData, sessionOnboardingId, appUserId, email, popupVariant, onboardingVariant } = useUserStore(
    (state) => ({
      setUserData: state.setUserData,
      sessionOnboardingId: state._id,
      appUserId: state.appUserId,
      email: state.email,
      popupVariant: state.popupVariant,
      onboardingVariant: state.onboardingVariant,
    })
  );

  const { sessionProductName, setProductName, setLayout, setForceGatewayApiUrl } = useSettingsStore((state) => ({
    sessionProductName: state.productName,
    setProductName: state.setProductName,
    setLayout: state.setLayout,
    setForceGatewayApiUrl: state.setForceGatewayApiUrl,
  }));

  const { setPaywallData } = usePaywallsStore((state) => ({
    setPaywallData: state.setPaywallData,
  }));

  useEffect(() => {
    (async () => {
      try {
        if (onboardingId) {
          // ToDo: TEMP ALTERNATIVE API COZ OF TWO BACKENDS
          const forceGatewayApiUrl = searchParams.has(SEARCH_PARAMS.ENABLE_GATEWAY_API_URL);
          setForceGatewayApiUrl(forceGatewayApiUrl);
          if (sessionOnboardingId !== onboardingId || sessionProductName !== productName) {
            setProductName(productName);
            const popupVariantToChange = searchParams.get(SEARCH_PARAMS.POPUP_VARIANT);
            const userClient = ApiClients.init({ logger }).getUserClient();
            const paymentsClient = ApiClients.getPaymentsClient();
            const userData = await userClient.getUserData(onboardingId, popupVariantToChange);
            if (!userData?.popupVariant) {
              throw new Error('No userData');
            }

            const paywallData = await paymentsClient.getPaywallData(userData.popupVariant.toLowerCase());

            if (!paywallData) {
              throw new Error('No paywallData');
            }
            if (paywallData.secondaryList?.length && paywallData.layouts.modalLayoutId) {
              const layout = await paymentsClient.getLayoutData(paywallData.layouts.modalLayoutId);
              if (layout) {
                setLayout(layout);
              }
            }

            setUserData({ ...userData, popupVariant: paywallData.slug });
            setPaywallData(paywallData);
          } else {
            ApiClients.init({ logger });
          }
          initAnalytics();
        } else {
          go({ routeChangedPart: { page: PAGES.NOT_FOUND }, replace: true });
        }
      } catch (e) {
        logger.logError(e as Error);
        go({ routeChangedPart: { page: PAGES.NOT_FOUND }, replace: true });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    logger.setUser({ appUserId, email });
    logger.setContext({ productName, popupVariant, onboardingVariant });
  }, [appUserId, email, productName, popupVariant, onboardingVariant]);

  return [isLoading];
};
