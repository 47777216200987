import { PAYWALL_TYPE, Paywalls, PRODUCT_NAMES, Products } from '@organicapps/organictypes';

export const APPS: { [key in Products.Names]: string } = {
  [PRODUCT_NAMES.FIT]: 'OrganicFit',
  [PRODUCT_NAMES.DANCE]: 'OrganicDance',
  [PRODUCT_NAMES.WALK]: 'OrganicWalk',
  [PRODUCT_NAMES.MAN]: 'OrganicManFit',
  [PRODUCT_NAMES.KETO]: 'OrganicMeals',
  [PRODUCT_NAMES.FASTING]: 'OrganicMeals',
  [PRODUCT_NAMES.VEGAN]: 'OrganicMeals',
};

export const SECONDARY_PRODUCTS: { [key in Exclude<Paywalls.PaywallType, 'offer'>]: string } = {
  [PAYWALL_TYPE.KETO]: 'KetoGuide',
  [PAYWALL_TYPE.MEALPLAN]: 'MealPlan',
  [PAYWALL_TYPE.CALORIE]: 'CalorieTracker',
  [PAYWALL_TYPE.VEGAN]: 'VeganGuide',
  [PAYWALL_TYPE.GROCERY]: 'GroceryList',
  [PAYWALL_TYPE.EXPRESS_WORKOUTS]: 'ExpressWorkouts',
  [PAYWALL_TYPE.UPPER_BODY_EXERCISES]: 'UpperBodyExercises',
  [PAYWALL_TYPE.WORKOUT_PLAN]: 'WorkoutPlan',
  [PAYWALL_TYPE.ABS_EXERCISES]: 'AbsExercises',
  [PAYWALL_TYPE.LEGS_EXERCISES]: 'LegsExercises',
  [PAYWALL_TYPE.HEALTHY_DESERTS]: 'HealthyDeserts',
  [PAYWALL_TYPE.BREAKFAST_PLAN]: 'BreakfastPlan',
  [PAYWALL_TYPE.LUNCH_PLAN]: 'LunchPlan',
  [PAYWALL_TYPE.DINNER_PLAN]: 'DinnerPlan',
  [PAYWALL_TYPE.SNACK_LIST]: 'SnackList',
  [PAYWALL_TYPE.HEALTHY_DRINKS_GUIDE]: 'HealthyDrinksGuide',
  [PAYWALL_TYPE.LOW_CARB_GUIDE]: 'LowCarbGuide',
  [PAYWALL_TYPE.RECIPE_BOOK]: 'RecipeBook',
  [PAYWALL_TYPE.FASTING_PLAN]: 'FastingPlan',
  [PAYWALL_TYPE.BRAZILIAN_BUTT_WORKOUTS]: 'BrazilianButtWorkouts',
  [PAYWALL_TYPE.ANTI_CELLULITE_WORKOUTS]: 'AntiCelluliteWorkouts',
  [PAYWALL_TYPE.BURN_FAT_WORKOUTS]: 'BurnFatWorkouts',
  [PAYWALL_TYPE.LOST_WEIGHT_WORKOUTS]: 'LoseWeightWorkouts',
  [PAYWALL_TYPE.FULL_BODY_WORKOUTS]: 'FullBodyWorkouts',
  [PAYWALL_TYPE.STRETCHING_EXERCISES]: 'StretchingExercises',
};
