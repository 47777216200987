import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PAYWALL_TYPE, Paywalls, Products } from '@organicapps/organictypes';
import { DYNAMIC_ROUTE_PARAMS, PAGES } from '../constants/pages';
import { usePaywallsStore } from '../store';

type Keys = keyof typeof PAGES;
export interface IRouteParamsToChange {
  [DYNAMIC_ROUTE_PARAMS.LOCALE]?: string;
  [DYNAMIC_ROUTE_PARAMS.PRODUCT_NAME]?: Products.Names;
  [DYNAMIC_ROUTE_PARAMS.VERSION]?: Paywalls.PaywallVersion | string;
  [DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID]?: string;
  '*'?: string;
  page?: typeof PAGES[Keys];
}

const paywallPages: Set<string> = new Set(Object.values(PAYWALL_TYPE));
const pageRegEx = new RegExp(`${Object.values(PAGES).join('|')}`);

export const useRouterWrapper = () => {
  const { locale = '', productName = '', version = '', onboardingId = '' } = useParams();
  const { pathname } = useLocation();

  const result = pathname.match(pageRegEx);
  const currentPage = result?.length ? (result[0] as typeof PAGES[Keys]) : '';

  const { paywallVersion } = usePaywallsStore((state) => ({
    paywallVersion: state.settings.version,
  }));

  const { search } = useLocation();
  const navigate = useNavigate();

  const go = ({ routeChangedPart, replace }: { routeChangedPart?: IRouteParamsToChange; replace?: boolean }) => {
    if (routeChangedPart?.page === PAGES.NOT_FOUND) {
      navigate(PAGES.NOT_FOUND, { replace });
      return;
    }
    const pathToGo = generatePath(
      `/:locale/:productName/:page/:version/${routeChangedPart?.['*'] ? '*' : ':onboardingId'}`,
      {
        locale,
        productName,
        version: (paywallPages.has(currentPage) && paywallVersion) || version,
        onboardingId,
        page: currentPage,
        '*': routeChangedPart?.['*'] || '',
        ...routeChangedPart,
      }
    );
    navigate(`${pathToGo}${search}`, { replace, state: { prevPage: currentPage } });
  };

  return go;
};
