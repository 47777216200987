import type { DivExtensionClass } from '@divkitframework/divkit/typings/common';
import type { Logger } from 'modules/logger';
import type { IExtensionsProviderOptions } from './types';
import type { EXTENSION_NAMES, IExtensionBuilder } from './builders';

export default class ExtensionsProviderFactory {
  readonly providers: Map<EXTENSION_NAMES, DivExtensionClass> = new Map();

  private readonly logger?: Logger;

  constructor(providers: IExtensionBuilder[], opts?: IExtensionsProviderOptions) {
    providers.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.extension.logger = opts?.logger;
      this.providers.set(el.name, el.extension);
    });
    this.logger = opts?.logger;
    this.logger?.logInfo({ extensions: [...this.providers.keys()] }, 'extensionsInitialized');
  }
}
