import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Products } from '@organicapps/organictypes';
import { useAnalytics } from './useAnalytics';
import { PAGES } from '../constants/pages';
import { useSettingsStore, useSubscriptionStore, useUserStore } from '../store';
import ApiClients from '../api';
import { SEARCH_PARAMS } from '../constants/searchParams';
import { useRouterWrapper } from './useRouterWrapper';
import { useLogger } from './useLogger';

export const useInitSubscriptionState = (productName: Products.Names) => {
  const [isLoading, setIsLoading] = useState(true);
  const go = useRouterWrapper();
  const { initAnalytics } = useAnalytics();
  const [searchParams] = useSearchParams();
  const appUserToken = searchParams.get(SEARCH_PARAMS.ACCESS_TOKEN)!;
  const logger = useLogger();

  const { setSubscriptionData, sessionAppUserToken } = useSubscriptionStore((state) => ({
    setSubscriptionData: state.setSubscriptionData,
    sessionAppUserToken: state.appUserToken,
  }));

  const { sessionProductName, setProductName, setForceGatewayApiUrl } = useSettingsStore((state) => ({
    sessionProductName: state.productName,
    setProductName: state.setProductName,
    setForceGatewayApiUrl: state.setForceGatewayApiUrl,
  }));

  const { setUserData, userData, appUserId, email } = useUserStore((state) => ({
    setUserData: state.setUserData,
    userData: state,
    appUserId: state.appUserId,
    email: state.email,
  }));

  useEffect(() => {
    (async () => {
      try {
        if (sessionAppUserToken !== appUserToken || sessionProductName !== productName) {
          // ToDo: TEMP ALTERNATIVE API COZ OF TWO BACKENDS
          const forceGatewayApiUrl = searchParams.has(SEARCH_PARAMS.ENABLE_GATEWAY_API_URL);
          setForceGatewayApiUrl(forceGatewayApiUrl);
          setProductName(productName);
          const ApiInstance = ApiClients.init({ logger }).getUserClient();
          const analyticsSettings = await ApiInstance.getUserAnalyticsSettings(appUserToken);
          const subscriptionData = await ApiInstance.getSubscription(appUserToken);
          if (!subscriptionData) {
            throw new Error('No subscriptionData');
          }
          setSubscriptionData({ ...subscriptionData, appUserToken });
          setUserData({
            ...userData,
            appUserId: subscriptionData.appUserId,
            email: subscriptionData.email,
            analyticsSettings: analyticsSettings || userData.analyticsSettings,
            appUserToken,
          });
        } else {
          ApiClients.init({ logger });
        }
        initAnalytics();
      } catch (e) {
        logger.logError(e as Error);
        go({
          routeChangedPart: {
            page: PAGES.NOT_FOUND,
          },
          replace: true,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    logger.setUser({ appUserId, email });
    logger.setContext({ productName });
  }, [appUserId, email, productName]);

  return [isLoading];
};
