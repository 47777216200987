import React, { ComponentType, FC } from 'react';
import { IProductPageParams } from './withProductGuard';
import { useInitSubscriptionState } from '../hooks/useInitSubscriptionState';

export interface IWithSubscriptionStateProps extends Object {
  isLoading: boolean;
}

const withSubscriptionState = <P extends IWithSubscriptionStateProps>(
  Component: ComponentType<P>
): FC<Omit<P, keyof IWithSubscriptionStateProps> & IProductPageParams> => {
  return (props: Omit<P, keyof IWithSubscriptionStateProps> & IProductPageParams) => {
    const { productName } = props;
    const [isLoading] = useInitSubscriptionState(productName);
    const newProps = { ...props, isLoading };

    return <Component {...(newProps as P & IWithSubscriptionStateProps & IProductPageParams)} />;
  };
};

export default withSubscriptionState;
