import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { Layouts, Products } from '@organicapps/organictypes';
import logger from './middlewares/loggerMiddleware';
import { ENV, NODE_ENV } from '../constants/envKeysMapper';

interface ISettingsState {
  productName: Products.Names | null;
  layout: Layouts.IPopup | null;
  forceMissedKeysUpdate: boolean;
  // ToDo: TEMP ALTERNATIVE API COZ OF TWO BACKENDS
  forceGatewayApiUrl: boolean;
  setProductName: (productName: Products.Names) => void;
  setLayout: (layout: Layouts.IPopup) => void;
  setForceMissedKeysUpdate: (status: boolean) => void;
  setForceGatewayApiUrl: (status: boolean) => void;
}

export const useSettingsStore = create<ISettingsState>()(
  logger(
    devtools(
      persist(
        (set, get) => ({
          productName: null,
          layout: null,
          forceMissedKeysUpdate: false,
          forceGatewayApiUrl: false,
          setForceMissedKeysUpdate: (status: boolean) => set({ forceMissedKeysUpdate: status }),
          setForceGatewayApiUrl: (status: boolean) => set({ forceGatewayApiUrl: status }),
          setProductName: (productName: Products.Names) => set({ productName }, false, 'settings/setProductName'),
          setLayout: (layout: Layouts.IPopup) => set({ layout }, false, 'settings/setLayout'),
        }),
        {
          name: 'settingsState',
          getStorage: () => sessionStorage,
        }
      ),
      { name: 'useSettingsStore', enabled: NODE_ENV === ENV.DEVELOPMENT }
    ),
    '[settingsStore]'
  )
);
