import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';
import ErrorIcon from '@mui/icons-material/Error';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette.error.main}`,
  borderRadius: '10px',
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(2),
}));

const ErrorMessage: FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Container {...rest}>
      <IconWrapper>
        <ErrorIcon color='error' />
      </IconWrapper>
      <Typography sx={{ overflowWrap: 'anywhere' }} component='span' variant='body2'>
        {children}
      </Typography>
    </Container>
  );
};

export default ErrorMessage;
