import { createTheme } from '@mui/material/styles';
import { DARK_THEME_COLORS, GREY_THEME_COLORS, LIGHT_THEME_COLORS } from '../constants/colors';
import { darkTheme, greyTheme, lightTheme, THEME_MODES } from './common';

const veganDarkTheme = createTheme({
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    primary: {
      main: DARK_THEME_COLORS.PRIMARY_VEGAN,
      dark: DARK_THEME_COLORS.PRIMARY_VEGAN_DARK,
      light: DARK_THEME_COLORS.PRIMARY_VEGAN_LIGHT,
    },
    secondary: {
      main: DARK_THEME_COLORS.SECONDARY_VEGAN,
      light: DARK_THEME_COLORS.SECONDARY_VEGAN_LIGHT,
      dark: DARK_THEME_COLORS.SECONDARY_VEGAN_DARK,
    },
    gradient: {
      main: DARK_THEME_COLORS.SPECIAL_OFFER_VEGAN_GRADIENT,
    },
  },
});

const veganGreyTheme = createTheme({
  ...greyTheme,
  palette: {
    ...greyTheme.palette,
    primary: {
      main: GREY_THEME_COLORS.PRIMARY_VEGAN,
      dark: GREY_THEME_COLORS.PRIMARY_VEGAN_DARK,
      light: GREY_THEME_COLORS.PRIMARY_VEGAN_LIGHT,
    },
    secondary: {
      main: GREY_THEME_COLORS.SECONDARY_VEGAN,
      light: GREY_THEME_COLORS.SECONDARY_VEGAN_LIGHT,
      dark: GREY_THEME_COLORS.SECONDARY_VEGAN_DARK,
    },
    gradient: {
      main: GREY_THEME_COLORS.SPECIAL_OFFER_VEGAN_GRADIENT,
    },
  },
});

const veganLightTheme = createTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    primary: {
      main: LIGHT_THEME_COLORS.PRIMARY_VEGAN,
      dark: LIGHT_THEME_COLORS.PRIMARY_VEGAN_DARK,
      light: LIGHT_THEME_COLORS.PRIMARY_VEGAN_LIGHT,
    },
    secondary: {
      main: LIGHT_THEME_COLORS.SECONDARY_VEGAN,
      light: LIGHT_THEME_COLORS.SECONDARY_VEGAN_LIGHT,
      dark: LIGHT_THEME_COLORS.SECONDARY_VEGAN_DARK,
    },
    gradient: {
      main: LIGHT_THEME_COLORS.SPECIAL_OFFER_VEGAN_GRADIENT,
    },
  },
});

export const veganThemes = {
  [THEME_MODES.DARK]: veganDarkTheme,
  [THEME_MODES.LIGHT]: veganLightTheme,
  [THEME_MODES.GREY]: veganGreyTheme,
};
