import type { Logger } from 'modules/logger';
import { DivExtension, IExtensionBuilder } from './types';
import { EXTENSION_NAMES } from './constants';

class StickyExtension implements DivExtension {
  static logger?: Logger;

  async mountView(node: HTMLElement) {
    node.style.setProperty('position', 'sticky');
    node.style.setProperty('bottom', '0');
  }
}

export default class StickyExtensionBuilder implements IExtensionBuilder {
  readonly name = EXTENSION_NAMES.STICKY;

  readonly extension: IExtensionBuilder['extension'];

  constructor() {
    this.extension = StickyExtension;
  }
}
