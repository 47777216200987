import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import CheckIcon from '../../atoms/CheckIcon/CheckIcon';

const Container = styled.li`
  display: flex;
`;

export interface IProps {
  /** Text to display */
  text: string;
  /** Optional styles override */
  style?: React.CSSProperties;
  customIcon?: React.ReactNode;
}

const CheckListItem: FC<IProps> = ({ text, customIcon, style }) => {
  return (
    <Container style={{ ...style }}>
      {customIcon || <CheckIcon sx={{ mr: 3 }} />}
      <Typography variant='body1'>{text}</Typography>
    </Container>
  );
};

export default CheckListItem;
