import React, { FC, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Locales, PAYWALL_TYPE, PAYWALL_VERSION, PRODUCT_NAMES } from '@organicapps/organictypes';
import { IProductPageParams } from '../../HOCs/withProductGuard';
import { EVENT_TYPES, EVENTS } from '../../constants/analytics';
import { useAnalytics } from '../../hooks/useAnalytics';
import { DYNAMIC_ROUTE_PARAMS, PAGES } from '../../constants/pages';
import { usePaywallsStore, useUserStore } from '../../store';
import withPaywallTypeGuard from '../../HOCs/withPaywallTypeGuard';
import { useRouterWrapper } from '../../hooks/useRouterWrapper';
import { includes } from '../../helpers/includes';
import ApiClients from '../../api';
import { useLogger } from '../../hooks/useLogger';
import { useFingerprint } from '../../hooks/useFingerprint';

const v1 = React.lazy(() => import('./v1/index'));
const v2 = React.lazy(() => import('./v2/index'));
const v3 = React.lazy(() => import('./v3/index'));
const abFit = React.lazy(() => import('./ab/fit'));
const abDance = React.lazy(() => import('./ab/dance'));
const abMan = React.lazy(() => import('./ab/man'));
const abWalk = React.lazy(() => import('./ab/walk'));
const abVegan = React.lazy(() => import('./ab/vegan'));
const abFasting = React.lazy(() => import('./ab/fasting'));
const abKeto = React.lazy(() => import('./ab/keto'));
const thematicMan = React.lazy(() => import('./thematic/man'));
const thematicFit = React.lazy(() => import('./thematic/fit'));

export interface IRoutes {
  [key: string]:
    | FC
    | {
        [key in PAYWALL_VERSION]?: FC;
      };
}

export interface IPagesParams extends IProductPageParams {
  [DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID]: string;
  [DYNAMIC_ROUTE_PARAMS.LOCALE]: Locales.List;
}

const routes: IRoutes = {
  [PAYWALL_VERSION.V1]: v1,
  [PAYWALL_VERSION.V2]: v2,
  [PAYWALL_VERSION.V3]: v3,
  [PRODUCT_NAMES.FIT]: {
    [PAYWALL_VERSION.AB]: abFit,
    [PAYWALL_VERSION.THEMATIC]: thematicFit,
  },
  [PRODUCT_NAMES.DANCE]: {
    [PAYWALL_VERSION.AB]: abDance,
  },
  [PRODUCT_NAMES.WALK]: {
    [PAYWALL_VERSION.AB]: abWalk,
  },
  [PRODUCT_NAMES.MAN]: {
    [PAYWALL_VERSION.THEMATIC]: thematicMan,
    [PAYWALL_VERSION.AB]: abMan,
  },
  [PRODUCT_NAMES.KETO]: {
    [PAYWALL_VERSION.AB]: abKeto,
  },
  [PRODUCT_NAMES.FASTING]: {
    [PAYWALL_VERSION.AB]: abFasting,
  },
  [PRODUCT_NAMES.VEGAN]: {
    [PAYWALL_VERSION.AB]: abVegan,
  },
};

const OfferRouter = () => {
  const { logEvent } = useAnalytics();
  const go = useRouterWrapper();
  const { productName } = useParams<keyof IPagesParams>() as IPagesParams;
  const userClient = ApiClients.getUserClient();
  const logger = useLogger();
  const fingerprint = useFingerprint();

  const { isPayed, appUserToken } = useUserStore((state) => ({
    isPayed: state.paid,
    appUserToken: state.appUserToken,
  }));
  const { paywallVersion } = usePaywallsStore((state) => ({
    paywallVersion: state.settings.version,
  }));
  const isProductSpecific = includes([PAYWALL_VERSION.AB, PAYWALL_VERSION.THEMATIC], paywallVersion);
  const Page: FC = isProductSpecific ? (routes[productName] as any)?.[paywallVersion] : routes[paywallVersion];

  useEffect(() => {
    logEvent({
      type: EVENT_TYPES.VIEW,
      name: EVENTS.PAYWALL,
      properties: {
        version: paywallVersion,
      },
    });
  }, []);

  useEffect(() => {
    if (isPayed) {
      fingerprint
        .createFingerprint()
        .then((fingerprint) => {
          userClient.sendUserFingerprint(appUserToken, fingerprint).catch((e) => logger.logError(e as Error));
        })
        .catch((e) => logger.logError(e as Error));
      go({ routeChangedPart: { page: PAGES.SUCCESS } });
    }
  }, [isPayed, appUserToken, userClient, logger]);

  return <> {Page ? <Page /> : <Navigate to={PAGES.NOT_FOUND} replace />}</>;
};

export default withPaywallTypeGuard(OfferRouter, PAYWALL_TYPE.OFFER);
