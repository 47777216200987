import React from 'react';
import { Container } from '@mui/system';
import { Outlet } from 'react-router';
import { SPACINGS } from '../constants/spacings';

const DefaultLayout = () => {
  return (
    <Container
      maxWidth='xs'
      sx={{ maxWidth: `${SPACINGS.MAX_SCREEN_WIDTH_PX} !important`, padding: `0 ${SPACINGS.LAYOUT}` }}
      disableGutters
    >
      <Outlet />
    </Container>
  );
};

export default DefaultLayout;
