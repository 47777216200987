import { createTheme } from '@mui/material/styles';
import { DARK_THEME_COLORS, GREY_THEME_COLORS, LIGHT_THEME_COLORS } from '../constants/colors';
import { darkTheme, greyTheme, lightTheme, THEME_MODES } from './common';

const fastingDarkTheme = createTheme({
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    primary: {
      main: DARK_THEME_COLORS.PRIMARY_FASTING,
      dark: DARK_THEME_COLORS.PRIMARY_FASTING_DARK,
      light: DARK_THEME_COLORS.PRIMARY_FASTING_LIGHT,
    },
    secondary: {
      main: DARK_THEME_COLORS.SECONDARY_FASTING,
      light: DARK_THEME_COLORS.SECONDARY_FASTING_LIGHT,
      dark: DARK_THEME_COLORS.SECONDARY_FASTING_DARK,
    },
    gradient: {
      main: DARK_THEME_COLORS.SPECIAL_OFFER_FASTING_GRADIENT,
    },
  },
});

const fastingGreyTheme = createTheme({
  ...greyTheme,
  palette: {
    ...greyTheme.palette,
    primary: {
      main: GREY_THEME_COLORS.PRIMARY_FASTING,
      dark: GREY_THEME_COLORS.PRIMARY_FASTING_DARK,
      light: GREY_THEME_COLORS.PRIMARY_FASTING_LIGHT,
    },
    secondary: {
      main: GREY_THEME_COLORS.SECONDARY_FASTING,
      light: GREY_THEME_COLORS.SECONDARY_FASTING_LIGHT,
      dark: GREY_THEME_COLORS.SECONDARY_FASTING_DARK,
    },
    gradient: {
      main: GREY_THEME_COLORS.SPECIAL_OFFER_FASTING_GRADIENT,
    },
  },
});

const fastingLightTheme = createTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    primary: {
      main: LIGHT_THEME_COLORS.PRIMARY_FASTING,
      dark: LIGHT_THEME_COLORS.PRIMARY_FASTING_DARK,
      light: LIGHT_THEME_COLORS.PRIMARY_FASTING_LIGHT,
    },
    secondary: {
      main: LIGHT_THEME_COLORS.SECONDARY_FASTING,
      light: LIGHT_THEME_COLORS.SECONDARY_FASTING_LIGHT,
      dark: LIGHT_THEME_COLORS.SECONDARY_FASTING_DARK,
    },
    gradient: {
      main: LIGHT_THEME_COLORS.SPECIAL_OFFER_FASTING_GRADIENT,
    },
  },
});

export const fastingThemes = {
  [THEME_MODES.DARK]: fastingDarkTheme,
  [THEME_MODES.LIGHT]: fastingLightTheme,
  [THEME_MODES.GREY]: fastingGreyTheme,
};
