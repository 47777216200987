import EventEmitterFactory from './factory';
import type { IEventEmitterOptions, IEventProcessor } from './types';

export type { IEventEmitterOptions, EventEmitter, IEventProcessor, IEventProcessorOpts } from './types';

export { default as ShowCardEventProcessor } from './processors/show-card';

export default function factory(
  eventProcessors: IEventProcessor[],
  opts?: IEventEmitterOptions
): InstanceType<typeof EventEmitterFactory> {
  return new EventEmitterFactory(eventProcessors, opts);
}
