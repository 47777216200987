import type { Logger } from 'modules/logger';
import BaseApi from './baseApi';
import AnalyticsClient from './analyticsClient';
import PaymentsClient from './paymentsClient';
import UserClient from './userClient';
import { useSettingsStore } from '../store';
import LayoutsClient from './layoutsClient';

interface ApiClientsOpts {
  logger?: Logger;
}

export default class ApiClients {
  private static baseApi: BaseApi;

  private static paymentsClient: PaymentsClient;

  private static userClient: UserClient;

  private static analyticsClient: AnalyticsClient;

  private static layoutsClient: LayoutsClient;

  private static initialized: boolean;

  private static logger?: Logger;

  public static init(opts?: ApiClientsOpts) {
    this.logger = opts?.logger;
    this.initialized = true;
    return this;
  }

  public static getBaseApi() {
    if (!this.baseApi && this.initialized) {
      // ToDo: TEMP ALTERNATIVE API COZ OF TWO BACKENDS
      const { productName, forceGatewayApiUrl } = useSettingsStore.getState();
      if (productName) {
        this.baseApi = new BaseApi(productName, { forceGatewayApiUrl, logger: this.logger });
      }
    }
    return this.baseApi;
  }

  public static getPaymentsClient(): PaymentsClient {
    const baseApi = this.getBaseApi();
    if (!this.paymentsClient && baseApi) {
      this.paymentsClient = new PaymentsClient(baseApi);
    }
    return this.paymentsClient;
  }

  public static getUserClient(): UserClient {
    const baseApi = this.getBaseApi();
    if (!this.userClient && baseApi) {
      this.userClient = new UserClient(baseApi);
    }
    return this.userClient;
  }

  public static getAnalyticsClient(): AnalyticsClient {
    const baseApi = this.getBaseApi();
    if (!this.analyticsClient && baseApi) {
      this.analyticsClient = new AnalyticsClient(baseApi);
    }
    return this.analyticsClient;
  }

  public static getLayoutsClient(): LayoutsClient {
    const baseApi = this.getBaseApi();
    if (!this.analyticsClient && baseApi) {
      this.layoutsClient = new LayoutsClient(baseApi, { logger: this.logger });
    }
    return this.layoutsClient;
  }
}
