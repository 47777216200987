import React, { FC, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { PAYMENT_PROVIDERS } from '@organicapps/organictypes/lib/modules/payments';
import CheckList from '../../../components/organisms/CheckList/CheckList';
import Button from '../../../components/atoms/Button/Button';
import OfferPaymentDrawer from '../../../components/drawers/OfferPaymentDrawer/OfferPaymentDrawer';
import PriceList from '../../../components/organisms/PriceList/PriceList';
import { usePaywallsStore } from '../../../store';
import Stepper from '../../../components/molecules/Stepper/Stepper';
import UpsellMainImage from '../../../components/atoms/UpsellMainImage/UpsellMainImage';

import PaperPlaneImg from '../../../assets/icons/paper-plane.svg';
import ErrorMessage from '../../../components/molecules/ErrorMessage/ErrorMessage';
import BackdropLoader from '../../../components/molecules/BackdropLoader/BackdropLoader';
import useContinuePayment from '../../../hooks/useContinuedPayment';

const CONTENT = [
  'paywall.upsells.fasting_dance_checklist.list0',
  'paywall.upsells.fasting_dance_checklist.list1',
  'paywall.upsells.fasting_dance_checklist.list2',
  'paywall.upsells.fasting_dance_checklist.list3',
];

export const STEPPER_LIST = [
  'paywall.upsells.stepper.list0',
  'paywall.upsells.stepper.list1',
  'paywall.upsells.stepper.list2',
];

const ColoredText = styled.span(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 5),
}));

interface IProps {
  onSkip: () => void;
  onPaid: () => void;
}

const FastingDancePrograms: FC<IProps> = ({ onSkip, onPaid }) => {
  const { t } = useTranslation();
  const { selectedPaywallList, upsellsList, paymentSystems } = usePaywallsStore((state) => ({
    selectedPaywallList: state.selectedPaywallList,
    upsellsList: state.upsellsList,
    paywallType: state.settings.paywallType,
    paymentSystems: state.settings.paymentSystems,
  }));
  const [{ open: openPayment }, setOpenPayment] = useState({
    open: false,
    touched: false,
  });

  const handleClosePayment = () => {
    setOpenPayment({ open: false, touched: true });
  };

  const handleOpenPayment = () => {
    setOpenPayment({ open: true, touched: false });
  };

  const handleSkipUpsell = () => {
    onSkip();
  };

  const handlePaidUpsell = () => {
    onPaid();
  };

  const cardPaymentSystemData = useMemo(
    () => paymentSystems.find((item) => item.provider === PAYMENT_PROVIDERS.CARD),
    [paymentSystems]
  );

  const { continuePay, isLoading, error } = useContinuePayment();

  const handlePay = async () => {
    if (!error && cardPaymentSystemData) {
      await continuePay(handlePaidUpsell, cardPaymentSystemData);
    } else {
      handleOpenPayment();
    }
  };

  return (
    <>
      <Stepper activeStep={0} steps={STEPPER_LIST} />
      <Typography mt={5} variant='h2' align='center'>
        <Trans t={t} i18nKey='paywall.upsells.fasting_dance_main_title'>
          Add Fasting & Dance <br />
          Programs to Your <br />
          Pilates Plan
        </Trans>
      </Typography>
      <Typography variant='body1' align='center' mt={5}>
        <Trans t={t} i18nKey='paywall.upsells.fasting_dance_main_subtitle'>
          Based on your answers{' '}
          <ColoredText>
            you&apos;ll reach <br />
            your weight goal x2
          </ColoredText>{' '}
          faster by adding <br />
          this activities to your plan
        </Trans>
      </Typography>
      <UpsellMainImage mt={5} />
      {error && <ErrorMessage mt={5}>{error}</ErrorMessage>}
      <Button
        disabled={!selectedPaywallList || isLoading}
        withAnimation
        sx={{ mt: 5 }}
        onClick={handlePay}
        data-name='Get 60% off add-ons now'
        data-page='Upsell'
        data-position='1'
      >
        {t('button.get_60_off')}
      </Button>
      <Typography
        variant='body1'
        align='center'
        sx={{ cursor: 'pointer' }}
        color='text.secondary'
        my={6}
        onClick={handleSkipUpsell}
      >
        <Trans t={t} i18nKey='paywall.upsells.fasting_dance_skip_upsell'>
          {`I don't want to boost my progress`}
        </Trans>
      </Typography>
      <Typography variant='h2' mt={10} align='center'>
        {t('paywall.upsells.fasting_dance_exclusive_offer')}
      </Typography>
      <PriceList customList={upsellsList} variant='upsells' />
      {error && <ErrorMessage mt={5}>{error}</ErrorMessage>}
      <Button
        disabled={!selectedPaywallList || isLoading}
        withAnimation
        sx={{ my: 5 }}
        onClick={handlePay}
        data-name='Get 60% off add-ons now'
        data-page='Upsell'
        data-position='2'
      >
        {t('button.get_60_off')}
      </Button>
      <Typography
        variant='body1'
        align='center'
        sx={{ cursor: 'pointer' }}
        color='text.secondary'
        my={6}
        onClick={handleSkipUpsell}
      >
        <Trans t={t} i18nKey='paywall.upsells.fasting_dance_skip_upsell'>
          {`I don't want to boost my progress`}
        </Trans>
      </Typography>
      <Container my={6}>
        <img src={PaperPlaneImg} alt='paper-plane-img' />
        <Typography variant='body2' ml={2} color='text.secondary' sx={{ weight: '500', width: '80%' }}>
          <Trans t={t} i18nKey='paywall.upsells.fasting_dance_immediately_message'>
            Your plan will be added in the <br /> App and you will receive an email
            <br /> with your E-Books immediately
            <br /> after purchase
          </Trans>
        </Typography>
      </Container>
      <CheckList my={5} customContent={CONTENT} />
      <OfferPaymentDrawer open={openPayment} onClose={handleClosePayment} onPaid={handlePaidUpsell} />
      <BackdropLoader sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading} />
    </>
  );
};

export default FastingDancePrograms;
