import React, { FC } from 'react';
import { Backdrop as BackdropElement } from '@mui/material';
import { BackdropProps } from '@mui/material/Backdrop/Backdrop';
import Loader from '../Loader/Loader';

interface IProps extends BackdropProps {}

const BackdropLoader: FC<IProps> = ({ open, ...rest }) => {
  return (
    <BackdropElement open={open} {...rest}>
      <Loader />
    </BackdropElement>
  );
};

export default BackdropLoader;
