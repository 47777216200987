import type { Action, DisappearAction, VisibilityAction } from '@divkitframework/divkit/typings/common';

export enum DIVKIT_EVENT_TYPES {
  CLICK = 'click',
  VISIBLE = 'visible',
}

export type StatCallbackType = {
  type: DIVKIT_EVENT_TYPES | string;
  action: Action | VisibilityAction | DisappearAction;
};

export type StatCallbackActionType = StatCallbackType['action'];

export const enum ACTION_LOG_ID_TYPES {
  SHOW_CARD = 'show_card',
  PAYWALL_CTA_CLICK = 'paywall_cta_click',
  PAYWALL_CTA_SCROLL = 'paywall_cta_scroll',
  ACTIVE_STATE_TOGGLE = 'active_state_toggle',
}
