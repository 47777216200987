import { CommonTypes } from '@organicapps/organictypes';
import { ANDROID_PLATFORMS, APPLE_PLATFORMS, PLATFORM } from '../constants/platforms';

export const getUserPlatform = (): CommonTypes.Nullable<PLATFORM> => {
  const userAgent = navigator.userAgent || navigator.vendor;

  const platform = [...(userAgent.match(/iPad|iPhone|iPod|android/) || [])][0];

  if (APPLE_PLATFORMS.has(platform)) {
    return PLATFORM.APPLE;
  }

  if (ANDROID_PLATFORMS.has(platform)) {
    return PLATFORM.GOOGLE;
  }

  return undefined;
};
