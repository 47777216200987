import { type CommonTypes, type Layouts, LOCALES_LIST } from '@organicapps/organictypes';
import BaseApi from './baseApi';
import { useSettingsStore } from '../store';
import type { Logger } from '../modules/logger';

interface ILayoutClientOpts {
  logger?: Logger;
}

export default class LayoutsClient {
  private readonly baseApi: BaseApi;

  private readonly logger?: Logger;

  constructor(baseApi: BaseApi, opts?: ILayoutClientOpts) {
    this.baseApi = baseApi;
    this.logger = opts?.logger;
  }

  async getPaywallLayout(slug: string) {
    // try to get requested layout with requested lang locally
    const localLayoutWithRequestedSlug = await this.importLayout<Layouts.IPaywall>(slug);
    if (localLayoutWithRequestedSlug) {
      return localLayoutWithRequestedSlug;
    }

    // try to get requested layout remotely (should return requested layout or default layout)
    const response = await this.baseApi.get<Layouts.IPaywall>(`/v1/paywall/${slug}/layouts`);
    return response.data;
  }

  private async importLayout<T>(slug: string): Promise<CommonTypes.Nullable<T>> {
    const { productName } = useSettingsStore.getState();
    const path = `/assets/layouts/${productName}/${slug}.json`;

    try {
      const response = await fetch(path);
      const layout = await response.json();
      this.logger?.logInfo({ path }, 'Requested layout loaded from local');
      return layout;
    } catch (e) {
      this.logger?.logDebug({ path }, 'Requested layout was not found in local');
      return null;
    }
  }
}
