import React, { FC } from 'react';
import styled from '@emotion/styled';
import { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';
import LoadingButton from '../LoadingButton/LoadingButton';

const vendorIcons: Record<string, any> = {
  apple: (
    <svg width='22' height='28' viewBox='0 0 22 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.5011 14.838C18.5271 12.81 19.612 10.891 21.333 9.82883C20.2473 8.27216 18.4288 7.28519 16.5368 7.22576C14.5188 7.01312 12.5624 8.43801 11.5341 8.43801C10.4859 8.43801 8.90257 7.24687 7.19764 7.28209C4.97534 7.35417 2.9036 8.62258 1.82241 10.573C-0.501734 14.6126 1.23187 20.5495 3.4582 23.8148C4.57208 25.4137 5.87389 27.1998 7.57728 27.1364C9.24415 27.067 9.8667 26.0694 11.8787 26.0694C13.8721 26.0694 14.4562 27.1364 16.1942 27.0962C17.9828 27.067 19.1097 25.4901 20.1845 23.8761C20.9848 22.7368 21.6007 21.4776 22.0092 20.1452C19.9073 19.2527 18.5036 17.1291 18.5011 14.838Z'
        fill='white'
      />
      <path
        d='M15.2184 5.07834C16.1936 3.90303 16.6741 2.39238 16.5577 0.867188C15.0678 1.02429 13.6915 1.73916 12.7031 2.86937C11.7366 3.97365 11.2336 5.45772 11.3287 6.92462C12.8191 6.94002 14.2844 6.24453 15.2184 5.07834Z'
        fill='white'
      />
    </svg>
  ),
  google: (
    <svg width='23' height='26' viewBox='0 0 23 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_ii_6630_25822)'>
        <path
          d='M0.619511 1.03883C0.341237 1.33451 0.176757 1.79339 0.176757 2.38763V23.619C0.176757 24.2142 0.341237 24.6721 0.619511 24.9678L0.690274 25.0369L12.5375 13.1435V13.0033V12.8631L0.690274 0.96875L0.619511 1.03883Z'
          fill='url(#paint0_linear_6630_25822)'
        />
        <path
          d='M16.4855 17.1093L12.5371 13.1436V13.0034V12.8632L16.4865 8.89844L16.5754 8.94932L21.2544 11.6181C22.5904 12.3804 22.5904 13.6274 21.2544 14.3906L16.5754 17.0594L16.4855 17.1093Z'
          fill='url(#paint1_linear_6630_25822)'
        />
        <g filter='url(#filter1_i_6630_25822)'>
          <path
            d='M16.5754 17.055L12.5361 13L0.619141 24.9645C1.05902 25.433 1.78674 25.4906 2.60626 25.024L16.5754 17.055Z'
            fill='url(#paint2_linear_6630_25822)'
          />
        </g>
        <path
          d='M16.5754 8.94688L2.60626 0.978882C1.78674 0.511362 1.05902 0.569922 0.619141 1.0384L12.5371 13.0029L16.5754 8.94688Z'
          fill='url(#paint3_linear_6630_25822)'
        />
      </g>
      <defs>
        <filter
          id='filter0_ii_6630_25822'
          x='0.176758'
          y='0.65625'
          width='22.0801'
          height='24.6875'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='-0.795' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_6630_25822' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='0.795' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='effect1_innerShadow_6630_25822' result='effect2_innerShadow_6630_25822' />
        </filter>
        <filter
          id='filter1_i_6630_25822'
          x='0.619141'
          y='13'
          width='15.9561'
          height='12.3438'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='-0.795' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_6630_25822' />
        </filter>
        <linearGradient
          id='paint0_linear_6630_25822'
          x1='11.4873'
          y1='2.16299'
          x2='-4.6233'
          y2='18.211'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00A0FF' />
          <stop offset='0.0066' stopColor='#00A1FF' />
          <stop offset='0.2601' stopColor='#00BEFF' />
          <stop offset='0.5122' stopColor='#00D2FF' />
          <stop offset='0.7604' stopColor='#00DFFF' />
          <stop offset='1' stopColor='#00E3FF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_6630_25822'
          x1='22.9934'
          y1='13.0034'
          x2='-0.144338'
          y2='13.0034'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFE000' />
          <stop offset='0.4087' stopColor='#FFBD00' />
          <stop offset='0.7754' stopColor='#FFA500' />
          <stop offset='1' stopColor='#FF9C00' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_6630_25822'
          x1='14.3818'
          y1='15.2021'
          x2='-7.46615'
          y2='36.9652'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF3A44' />
          <stop offset='1' stopColor='#C31162' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_6630_25822'
          x1='-2.3822'
          y1='-6.02931'
          x2='7.37353'
          y2='3.6885'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#32A071' />
          <stop offset='0.0685' stopColor='#2DA771' />
          <stop offset='0.4762' stopColor='#15CF74' />
          <stop offset='0.8009' stopColor='#06E775' />
          <stop offset='1' stopColor='#00F076' />
        </linearGradient>
      </defs>
    </svg>
  ),
};

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 500,
  '&.MuiLoadingButton-root': {
    backgroundColor: theme.palette.common.black,
  },
  alignSelf: 'center',
}));
export interface IExtendedButtonProps extends LoadingButtonProps {
  'data-name'?: string;
  'data-position'?: string;
  'data-page'?: string;
  onClick: () => void;
  vendor: string;
}

const VendorButton: FC<IExtendedButtonProps> = ({ children, vendor, onClick, ...rest }) => {
  const icon = vendorIcons[vendor];

  return (
    <StyledButton startIcon={icon} onClick={onClick} {...rest}>
      {children}
    </StyledButton>
  );
};

export default VendorButton;
