import { createTheme } from '@mui/material/styles';
import { DARK_THEME_COLORS, GREY_THEME_COLORS, LIGHT_THEME_COLORS } from '../constants/colors';
import { darkTheme, greyTheme, lightTheme, THEME_MODES } from './common';

const ketoDarkTheme = createTheme({
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    primary: {
      main: DARK_THEME_COLORS.PRIMARY_KETO,
      dark: DARK_THEME_COLORS.PRIMARY_KETO_DARK,
      light: DARK_THEME_COLORS.PRIMARY_KETO_LIGHT,
    },
    secondary: {
      main: DARK_THEME_COLORS.SECONDARY_KETO,
      light: DARK_THEME_COLORS.SECONDARY_KETO_LIGHT,
      dark: DARK_THEME_COLORS.SECONDARY_KETO_DARK,
    },
    gradient: {
      main: DARK_THEME_COLORS.SPECIAL_OFFER_KETO_GRADIENT,
    },
  },
});

const ketoGreyTheme = createTheme({
  ...greyTheme,
  palette: {
    ...greyTheme.palette,
    primary: {
      main: GREY_THEME_COLORS.PRIMARY_KETO,
      dark: GREY_THEME_COLORS.PRIMARY_KETO_DARK,
      light: GREY_THEME_COLORS.PRIMARY_KETO_LIGHT,
    },
    secondary: {
      main: GREY_THEME_COLORS.SECONDARY_KETO,
      light: GREY_THEME_COLORS.SECONDARY_KETO_LIGHT,
      dark: GREY_THEME_COLORS.SECONDARY_KETO_DARK,
    },
    gradient: {
      main: GREY_THEME_COLORS.SPECIAL_OFFER_KETO_GRADIENT,
    },
  },
});

const ketoLightTheme = createTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    primary: {
      main: LIGHT_THEME_COLORS.PRIMARY_KETO,
      dark: LIGHT_THEME_COLORS.PRIMARY_KETO_DARK,
      light: LIGHT_THEME_COLORS.PRIMARY_KETO_LIGHT,
    },
    secondary: {
      main: LIGHT_THEME_COLORS.SECONDARY_KETO,
      light: LIGHT_THEME_COLORS.SECONDARY_KETO_LIGHT,
      dark: LIGHT_THEME_COLORS.SECONDARY_KETO_DARK,
    },
    gradient: {
      main: LIGHT_THEME_COLORS.SPECIAL_OFFER_KETO_GRADIENT,
    },
  },
});

export const ketoThemes = {
  [THEME_MODES.DARK]: ketoDarkTheme,
  [THEME_MODES.LIGHT]: ketoLightTheme,
  [THEME_MODES.GREY]: ketoGreyTheme,
};
