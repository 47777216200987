import React, { ComponentType, FC, useEffect, useState } from 'react';
import { Paywalls } from '@organicapps/organictypes';
import { usePaywallsStore } from '../store';
import { useRouterWrapper } from '../hooks/useRouterWrapper';

const withPaywallTypeGuard = <P extends object>(
  Component: ComponentType<P>,
  validPaywallType: Paywalls.PaywallType
): FC<P> => {
  return (props: P) => {
    const go = useRouterWrapper();

    const [isRouteValid, setIsRouteValid] = useState<boolean>(false);

    const { paywallType } = usePaywallsStore((state) => ({
      paywallType: state.settings.paywallType,
    }));

    useEffect(() => {
      if (paywallType !== validPaywallType) {
        go({ routeChangedPart: { page: paywallType } });
      } else {
        setIsRouteValid(true);
      }
    }, [paywallType, validPaywallType]);

    return <>{isRouteValid && <Component {...props} />}</>;
  };
};

export default withPaywallTypeGuard;
