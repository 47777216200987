import React, { FC } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog/Dialog';
import { PRODUCT_NAMES, Products } from '@organicapps/organictypes';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { Transition } from '../Transition';
import ManCancelModalImg from '../../../assets/images/cancel/cancel-modal-header-man.png';
import DanceCancelModalImg from '../../../assets/images/cancel/cancel-modal-header-dance.png';
import FitCancelModalImg from '../../../assets/images/cancel/cancel-modal-header-fit.png';
import Button, { IExtendedButtonProps } from '../../atoms/Button/Button';

const IMAGES: { [key in PRODUCT_NAMES]: string } = {
  [PRODUCT_NAMES.FIT]: FitCancelModalImg,
  [PRODUCT_NAMES.KETO]: FitCancelModalImg,
  [PRODUCT_NAMES.FASTING]: FitCancelModalImg,
  [PRODUCT_NAMES.DANCE]: DanceCancelModalImg,
  [PRODUCT_NAMES.MAN]: ManCancelModalImg,
  [PRODUCT_NAMES.WALK]: DanceCancelModalImg,
  [PRODUCT_NAMES.VEGAN]: FitCancelModalImg,
};

export interface IProps extends DialogProps {
  onClick: (e: React.MouseEvent) => void;
  productName: Products.Names;
  title: string;
  description: JSX.Element | string;
  image?: string;
  buttonProps: {
    buttonText: string;
    props?: IExtendedButtonProps;
  };
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 0;
  }
`;

const CustomModal: FC<IProps> = ({
  onClick,
  productName,
  onClose,
  title,
  description,
  image,
  buttonProps: { buttonText, ...buttonRestProps },
  ...rest
}) => {
  const handleClose = (e: React.MouseEvent) => {
    if (onClose) {
      onClose(e, 'backdropClick');
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <StyledDialog {...rest} TransitionComponent={Transition} onClose={handleClose}>
      <img src={image ?? IMAGES[productName]} alt='modal-header' />
      <Box m={5}>
        <Typography align='center' variant='h3'>
          {title}
        </Typography>
        <Typography align='center' mt={5} variant='body1'>
          {description}
        </Typography>
        <Button sx={{ mt: 5 }} {...buttonRestProps} onClick={handleClick}>
          {buttonText}
        </Button>
      </Box>
    </StyledDialog>
  );
};

export default CustomModal;
