import { PAYWALL_TYPE } from '@organicapps/organictypes';

export const PAGES = {
  SUCCESS: 'success',
  UPSELLS: 'upsells',
  // ToDo: ManFit Upsells Test Temporary
  MANFIT_UPSELLS: 'manfit-upsells',
  REGISTER: 'register',
  DOWNLOAD: 'download',
  KETO: PAYWALL_TYPE.KETO,
  D_OFFER: PAYWALL_TYPE.D_OFFER,
  OFFER: PAYWALL_TYPE.OFFER,
  MEALPLAN: PAYWALL_TYPE.MEALPLAN,
  CALORIE: PAYWALL_TYPE.CALORIE,
  VEGAN: PAYWALL_TYPE.VEGAN,
  GROCERY: PAYWALL_TYPE.GROCERY,
  EXPRESS_WORKOUTS: PAYWALL_TYPE.EXPRESS_WORKOUTS,
  UPPER_BODY_EXERCISES: PAYWALL_TYPE.UPPER_BODY_EXERCISES,
  WORKOUT_PLAN: PAYWALL_TYPE.WORKOUT_PLAN,
  ABS_EXERCISES: PAYWALL_TYPE.ABS_EXERCISES,
  LEGS_EXERCISES: PAYWALL_TYPE.LEGS_EXERCISES,
  HEALTHY_DESERTS: PAYWALL_TYPE.HEALTHY_DESERTS,
  BREAKFAST_PLAN: PAYWALL_TYPE.BREAKFAST_PLAN,
  LUNCH_PLAN: PAYWALL_TYPE.LUNCH_PLAN,
  DINNER_PLAN: PAYWALL_TYPE.DINNER_PLAN,
  SNACK_LIST: PAYWALL_TYPE.SNACK_LIST,
  HEALTHY_DRINKS_GUIDE: PAYWALL_TYPE.HEALTHY_DRINKS_GUIDE,
  LOW_CARB_GUIDE: PAYWALL_TYPE.LOW_CARB_GUIDE,
  RECIPE_BOOK: PAYWALL_TYPE.RECIPE_BOOK,
  FASTING_PLAN: PAYWALL_TYPE.FASTING_PLAN,
  BRAZILIAN_BUTT_WORKOUTS: PAYWALL_TYPE.BRAZILIAN_BUTT_WORKOUTS,
  ANTI_CELLULITE_WORKOUTS: PAYWALL_TYPE.ANTI_CELLULITE_WORKOUTS,
  BURN_FAT_WORKOUTS: PAYWALL_TYPE.BURN_FAT_WORKOUTS,
  LOSE_WEIGHT_WORKOUTS: PAYWALL_TYPE.LOST_WEIGHT_WORKOUTS,
  FULL_BODY_WORKOUTS: PAYWALL_TYPE.FULL_BODY_WORKOUTS,
  STRETCHING_EXERCISES: PAYWALL_TYPE.STRETCHING_EXERCISES,
  CANCEL: 'cancel',
  NOT_FOUND: '/404',
} as const;

export const DYNAMIC_ROUTE_PARAMS = {
  LOCALE: 'locale',
  PRODUCT_NAME: 'productName',
  VERSION: 'version',
  ONBOARDING_ID: 'onboardingId',
} as const;
