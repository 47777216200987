import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';
import { useTranslation } from 'react-i18next';

const PriceTagArrow = styled(Box)(({ theme }) => ({
  width: 0,
  height: 0,
  position: 'relative',
  '& .outer': {
    position: 'absolute',
    top: '1px',
    left: '-16px',
    width: '20px',
    height: '26px',
    overflow: 'hidden',
  },
  '& .inner': {
    transform: 'rotate(55deg) skew(20deg, 0)',
    backgroundColor: theme.palette.secondary.light,
    position: 'relative',
    top: '-13px',
    left: '3px',
    width: '176px',
    height: '105px',
    borderRadius: '5px',
  },
}));

const PriceTagBackground = styled(Box)(({ theme }) => ({
  position: 'relative',
  minWidth: '55px',
  height: '28px',
  backgroundColor: theme.palette.secondary.light,
  borderBottomRightRadius: '5px',
  borderTopRightRadius: '5px',
  borderTopLeftRadius: '5px',
  borderBottomLeftRadius: '5px',
  padding: theme.spacing(2, 1, 2, 0),
  display: 'flex',
  alignItems: 'center',
}));

export interface IProps extends BoxProps {
  specialOfferDiscount: number;
}

const DiscountArrow: FC<IProps> = ({ specialOfferDiscount, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Box {...rest}>
      <PriceTagArrow>
        <Box className='outer'>
          <Box className='inner' />
        </Box>
      </PriceTagArrow>
      <PriceTagBackground>
        <Typography variant='h4' color='secondary.dark' sx={{ textTransform: 'uppercase' }}>
          {t('paywall.save_discount')} {specialOfferDiscount}%
        </Typography>
      </PriceTagBackground>
    </Box>
  );
};

export default DiscountArrow;
