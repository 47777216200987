import type { IEventEmitterOptions } from './types';
import type { IEventProcessor } from './processors';
import type { ACTION_LOG_ID_TYPES, StatCallbackType } from '../../types';
import type { Logger } from '../logger';

export default class EventEmitterFactory {
  private readonly logger?: Logger;

  private processors: Map<ACTION_LOG_ID_TYPES, IEventProcessor> = new Map();

  constructor(eventProcessors: IEventProcessor[], opts?: IEventEmitterOptions) {
    this.logger = opts?.logger;
    eventProcessors.forEach((item) => {
      this.processors.set(item.binding, item);
    });
    this.logger?.logInfo({ processors: [...this.processors.keys()] }, 'eventEmitterInitialized');
  }

  process(action: StatCallbackType) {
    try {
      const logIdType = action.action.log_id as ACTION_LOG_ID_TYPES;
      const actionData = action.action;
      const processor = this.processors.get(logIdType);
      if (processor) {
        processor.process(actionData);
      }
    } catch (e) {
      this?.logger?.logError(e as Error);
    }
  }
}
