import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PRODUCT_NAMES, Products } from '@organicapps/organictypes';
import Dance from './images/Dance';
import Walk from './images/Walk';
import Man from './images/Man';
import Fit from './images/Fit';
import Vegan from './images/Vegan';
import Keto from './images/Keto';
import Fasting from './images/Fasting';

const Container = styled.svg(({ theme }) => ({
  minWidth: '120px',
  height: '24px',
  '& .title': {
    fill: theme.palette.text.primary,
  },
}));

const LOGOS = {
  [PRODUCT_NAMES.DANCE]: <Dance />,
  [PRODUCT_NAMES.WALK]: <Walk />,
  [PRODUCT_NAMES.MAN]: <Man />,
  [PRODUCT_NAMES.FIT]: <Fit />,
  [PRODUCT_NAMES.VEGAN]: <Vegan />,
  [PRODUCT_NAMES.KETO]: <Keto />,
  [PRODUCT_NAMES.FASTING]: <Fasting />,
};

const Logo: FC<{ productName: Products.Names }> = ({ productName }) => {
  return <Container viewBox='0 0 120 24'>{LOGOS[productName]}</Container>;
};

export default Logo;
