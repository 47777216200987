import { createTheme } from '@mui/material/styles';
import { COMMON_COLORS, DARK_THEME_COLORS, GREY_THEME_COLORS, LIGHT_THEME_COLORS } from '../constants/colors';
import { MAX_SCREEN_WIDTH, SPACINGS } from '../constants/spacings';

export enum THEME_MODES {
  LIGHT = 'light',
  DARK = 'dark',
  GREY = 'grey',
}

export const THEME_MODS_LIST = Object.values(THEME_MODES);

export const commonTheme = createTheme({
  spacing: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80, 84, 88, 92, 96, 100],
  shape: {
    borderRadius: 20,
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h0: {
      fontSize: 42,
      fontWeight: 700,
      lineHeight: 1.28,
    },
    h1: {
      fontSize: 34,
      fontWeight: 700,
    },
    h2: {
      fontSize: 28,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: 22,
      fontWeight: 700,
    },
    h4: {
      fontSize: 15,
      fontWeight: 700,
    },
    h5: {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: 1.1,
    },
    h6: {
      fontSize: 9,
      fontWeight: 600,
      lineHeight: 1.3,
    },
    body1: {
      fontSize: 17,
      fontWeight: 400,
      lineHeight: 1.3,
    },
    body2: {
      fontSize: 13,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 17,
      fontWeight: 700,
      lineHeight: 1.3,
    },
    subtitle2: {
      fontSize: 11,
      fontWeight: 700,
    },
  },
  breakpoints: {
    values: {
      xs: MAX_SCREEN_WIDTH,
    },
  },
  palette: {
    common: {
      black: COMMON_COLORS.BLACK,
      white: COMMON_COLORS.WHITE,
    },
    info: {
      main: COMMON_COLORS.INFO_MAIN,
    },
    warning: {
      main: COMMON_COLORS.WARNING_MAIN,
    },
    success: {
      main: COMMON_COLORS.SUCCESS_MAIN,
    },
    error: {
      main: COMMON_COLORS.ERROR_MAIN,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: {
            variant: 'payButton',
          },
          style: ({ theme }) => ({
            flex: '1 0 auto',
            minWidth: 'calc(50% - 8px)',
            borderRadius: '6px',
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.grey['400']}`,
            color: theme.palette.text.primary,
            '& .pay-icon': {
              fill: theme.palette.text.primary,
            },
          }),
        },
        {
          props: {
            variant: 'quizButton',
          },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            boxShadow: theme.palette.shadow.main,
            fontSize: '17px',
            fontWeight: 400,
            height: 'auto',
            textTransform: 'initial',
            padding: theme.spacing(4),
            justifyContent: 'space-between',
            textAlign: 'start',
            transition: 'none',
            '& svg.MuiSvgIcon-root': {
              fontSize: '26px',
              visibility: 'hidden',
            },
            '&[active="1"]': {
              fontWeight: 700,
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              '& .MuiSvgIcon-root': {
                visibility: 'visible',
              },
            },
          }),
        },
      ],
      defaultProps: {
        variant: 'contained',
        fullWidth: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '20px',
          lineHeight: 1.25,
          fontWeight: 700,
          color: theme.palette.common.white,
          borderRadius: '40px',
          height: '48px',
          textTransform: 'capitalize',
          ':disabled': {
            backgroundColor: theme.palette.grey['300'],
            color: theme.palette.common.white,
          },
          '&.MuiLoadingButton-root': {
            backgroundColor: theme.palette.primary.main,
            ':disabled': {
              backgroundColor: theme.palette.grey['300'],
            },
          },
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          minWidth: '22px',
          margin: theme.spacing(0, 3, 0, 0),
          color: theme.palette.common.white,
          fontSize: '13px',
          fontWeight: 700,
        }),
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: theme.spacing(5),
        }),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        anchor: 'bottom',
      },
      styleOverrides: {
        modal: ({ theme }) => ({
          '.MuiBackdrop-root': {
            backgroundColor: theme.palette.backdrop.main,
          },
        }),
        paperAnchorBottom: ({ theme }) => ({
          padding: theme.spacing(6),
          height: '90%',
          maxWidth: '480px',
          margin: '0 auto',
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px',
          background: theme.palette.background.default,
        }),
      },
    },
    MuiDialog: {
      defaultProps: {
        variant: 'static',
      },
      styleOverrides: {
        paper: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
          position: 'relative',
          maxWidth: `calc(${theme.breakpoints.values.xs}px - ${SPACINGS.LAYOUT}*2)`,
        }),
      },
      variants: [
        {
          props: { variant: 'static' },
          style: ({ theme }) => ({
            '.MuiPaper-root': {
              padding: theme.spacing(5),
            },
          }),
        },
        {
          props: { variant: 'dynamic' },
          style: {
            '.MuiPaper-root': {
              padding: 0,
              width: '100%',
            },
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        margin: 'none',
        fullWidth: true,
        size: 'small',
      },
      variants: [
        {
          props: {
            multiline: true,
          },
          style: {
            '.MuiInputBase-root': {
              height: 'auto',
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: '56px',
          '.MuiInputBase-root': {
            height: '56px',
            fontSize: '17px',
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '10px',
            borderColor: theme.palette.grey[400],
          },
          '.MuiInputBase-root:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[600],
          },
          '.MuiFormLabel-root, .MuiInputBase-input::placeholder': {
            fontSize: '17px',
            color: theme.palette.grey[400],
          },
          '.MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
            lineHeight: '2em',
          },
          '.MuiOutlinedInput-input': {
            color: theme.palette.text.primary,
          },
          // disabled
          '.MuiInputLabel-root.Mui-disabled': {
            color: theme.palette.grey[400],
          },
          '.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[400],
          },
        }),
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiStepConnector-root': {
            left: `calc(-65% + ${theme.spacing(10)})`,
            right: `calc(38% + ${theme.spacing(10)})`,
          },
          '& .MuiStepConnector-line': {
            marginTop: theme.spacing(5),
          },
          '& .MuiStepLabel-root .Mui-active .MuiStepIcon-root': {
            color: theme.palette.grey[400],
          },
          '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
            fill: theme.palette.text.primary,
            fontWeight: 700,
          },
          '& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-root': {
            color: theme.palette.grey[300],
          },
          '& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text': {
            fill: theme.palette.text.secondary,
            fontWeight: 400,
          },
          '& .MuiStepLabel-root .Mui-completed .MuiStepIcon-root': {
            color: theme.palette.grey[400],
          },
          '& .MuiTypography-root': {
            textAlign: 'center',
          },
          backgroundColor: theme.palette.grey[100],
          padding: theme.spacing(5, 3),
          marginBottom: theme.spacing(6),
          position: 'relative',
        }),
      },
    },
  },
});

export const darkTheme = createTheme({
  ...commonTheme,
  palette: {
    ...commonTheme.palette,
    mode: 'dark',
    background: {
      paper: DARK_THEME_COLORS.BACKGROUND_PAPER,
      default: DARK_THEME_COLORS.BACKGROUND_DEFAULT,
    },
    text: {
      primary: DARK_THEME_COLORS.TEXT_PRIMARY,
      secondary: DARK_THEME_COLORS.TEXT_SECONDARY,
      disabled: DARK_THEME_COLORS.TEXT_DISABLED,
    },
    grey: {
      50: DARK_THEME_COLORS.GREY_50,
      100: DARK_THEME_COLORS.GREY_100,
      200: DARK_THEME_COLORS.GREY_200,
      300: DARK_THEME_COLORS.GREY_300,
      400: DARK_THEME_COLORS.GREY_400,
      500: DARK_THEME_COLORS.GREY_500,
      600: DARK_THEME_COLORS.GREY_600,
      700: DARK_THEME_COLORS.GREY_700,
      800: DARK_THEME_COLORS.GREY_800,
      900: DARK_THEME_COLORS.GREY_900,
    },
    shadow: {
      main: DARK_THEME_COLORS.SHADOW,
    },
    backdrop: {
      main: DARK_THEME_COLORS.BACKDROP,
    },
  },
});

export const lightTheme = createTheme({
  ...commonTheme,
  palette: {
    ...commonTheme.palette,
    mode: 'light',
    background: {
      paper: LIGHT_THEME_COLORS.BACKGROUND_PAPER,
      default: LIGHT_THEME_COLORS.BACKGROUND_DEFAULT,
    },
    text: {
      primary: LIGHT_THEME_COLORS.TEXT_PRIMARY,
      secondary: LIGHT_THEME_COLORS.TEXT_SECONDARY,
      disabled: LIGHT_THEME_COLORS.TEXT_DISABLED,
    },
    grey: {
      50: LIGHT_THEME_COLORS.GREY_50,
      100: LIGHT_THEME_COLORS.GREY_100,
      200: LIGHT_THEME_COLORS.GREY_200,
      300: LIGHT_THEME_COLORS.GREY_300,
      400: LIGHT_THEME_COLORS.GREY_400,
      500: LIGHT_THEME_COLORS.GREY_500,
      600: LIGHT_THEME_COLORS.GREY_600,
      700: LIGHT_THEME_COLORS.GREY_700,
      800: LIGHT_THEME_COLORS.GREY_800,
      900: LIGHT_THEME_COLORS.GREY_900,
    },
    shadow: {
      main: LIGHT_THEME_COLORS.SHADOW,
    },
    backdrop: {
      main: LIGHT_THEME_COLORS.BACKDROP,
    },
  },
});

export const greyTheme = createTheme({
  ...commonTheme,
  palette: {
    ...commonTheme.palette,
    mode: 'light',
    background: {
      paper: GREY_THEME_COLORS.BACKGROUND_PAPER,
      default: GREY_THEME_COLORS.BACKGROUND_DEFAULT,
    },
    text: {
      primary: GREY_THEME_COLORS.TEXT_PRIMARY,
      secondary: GREY_THEME_COLORS.TEXT_SECONDARY,
      disabled: GREY_THEME_COLORS.TEXT_DISABLED,
    },
    grey: {
      50: GREY_THEME_COLORS.GREY_50,
      100: GREY_THEME_COLORS.GREY_100,
      200: GREY_THEME_COLORS.GREY_200,
      300: GREY_THEME_COLORS.GREY_300,
      400: GREY_THEME_COLORS.GREY_400,
      500: GREY_THEME_COLORS.GREY_500,
      600: GREY_THEME_COLORS.GREY_600,
      700: GREY_THEME_COLORS.GREY_700,
      800: GREY_THEME_COLORS.GREY_800,
      900: GREY_THEME_COLORS.GREY_900,
    },
    shadow: {
      main: GREY_THEME_COLORS.SHADOW,
    },
    backdrop: {
      main: GREY_THEME_COLORS.BACKDROP,
    },
  },
});
