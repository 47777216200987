import React from 'react';

const Fasting = () => {
  return (
    <>
      <path
        d='M7.8437 16.1553C6.63615 16.1553 5.46394 16.42 4.46358 16.9887C3.388 17.6001 2.50652 18.5537 1.85734 19.6357C1.30047 20.6102 0.882812 21.8632 0.882812 23.1162C2.13577 23.1162 3.38873 22.6985 4.36326 22.1416C6.45152 20.8887 7.8437 18.8004 7.8437 16.1553Z'
        fill='#01C1AC'
      />
      <path
        d='M7.8437 16.1553C6.63615 16.1553 5.46394 16.42 4.46358 16.9887C3.388 17.6001 2.50652 18.5537 1.85734 19.6357C1.30047 20.6102 0.882812 21.8632 0.882812 23.1162C2.13577 23.1162 3.38873 22.6985 4.36326 22.1416C6.45152 20.8887 7.8437 18.8004 7.8437 16.1553Z'
        fill='black'
        fillOpacity='0.25'
      />
      <path
        d='M14.8046 2.2334H7.8437C5.33778 2.2334 3.1103 3.62558 1.85734 5.71384C1.30047 6.68837 0.882812 7.94133 0.882812 9.19428V16.1552C0.882812 14.9022 1.30047 13.6493 1.85734 12.6747C2.41421 11.7002 3.38873 10.7257 4.36326 10.1688C5.33778 9.61194 6.59074 9.19428 7.8437 9.19428C10.3496 9.19428 12.5771 7.80211 13.8301 5.71384C14.3869 4.73932 14.8046 3.48636 14.8046 2.2334Z'
        fill='#01C1AC'
      />
      <path
        d='M7.8437 9.19434C6.59074 9.19434 5.33778 9.61199 4.36326 10.1689C3.38873 10.7257 2.41421 11.7003 1.85734 12.6748C1.30047 13.6493 0.882812 14.9023 0.882812 16.1552V23.1161C0.882812 21.8631 1.30047 20.6102 1.85734 19.6357C3.1103 17.5474 5.19856 16.1552 7.8437 16.1552C7.8437 16.1552 8.81822 16.1552 9.23588 16.016C11.1849 15.5984 12.8555 14.3454 13.8301 12.6748C14.3869 11.7003 14.8046 10.4473 14.8046 9.19434H7.8437Z'
        fill='#01C1AC'
      />
      <path
        className='title'
        d='M28.542 4.95621C25.429 4.95621 22.3516 7.55335 22.3516 11.3067C22.3516 15.0601 25.429 17.6573 28.542 17.6573C31.6728 17.6573 34.7502 15.0601 34.7502 11.3067C34.7502 7.55335 31.6728 4.95621 28.542 4.95621ZM28.542 7.23315C30.5343 7.23315 32.242 8.8697 32.242 11.3067C32.242 13.7438 30.5165 15.3803 28.542 15.3803C26.5852 15.3803 24.8598 13.7438 24.8598 11.3067C24.8598 8.8697 26.5852 7.23315 28.542 7.23315Z'
        fill='black'
      />
      <path
        className='title'
        d='M41.6322 8.19373C40.0312 8.19373 38.9283 9.6524 38.8571 10.2928V9.40336C38.8571 8.76297 38.3235 8.22931 37.7009 8.22931C37.0605 8.22931 36.5268 8.76297 36.5268 9.40336V16.4654C36.5268 17.088 37.0605 17.6217 37.7009 17.6217C38.3235 17.6217 38.8571 17.088 38.8571 16.4654V12.1784C38.9461 11.449 39.8355 10.3639 41.2408 10.3639C42.237 10.3639 42.735 9.91923 42.735 9.26105C42.735 8.69182 42.3615 8.19373 41.6322 8.19373Z'
        fill='black'
      />
      <path
        className='title'
        d='M52.5753 9.38557C52.5753 8.76297 52.0061 8.22931 51.3835 8.22931C50.7431 8.22931 50.2094 8.76297 50.2094 9.38557V9.68798C49.8537 8.85191 48.8397 8.17595 47.5767 8.17595C45.6378 8.17595 43.2719 9.74134 43.2719 12.9077C43.2719 16.1452 45.62 17.6573 47.5767 17.6573C49.1243 17.6573 50.0671 16.6967 50.2094 16.1452V17.3549C50.2094 18.4044 49.409 19.1693 47.9681 19.1693C47.1676 19.1693 46.5628 18.9558 46.207 18.8669C45.9758 18.7957 45.8335 18.7779 45.7267 18.7779C45.2286 18.7779 44.8017 19.1693 44.8017 19.7029C44.8017 20.1299 45.0685 20.4856 45.5666 20.6991C45.958 20.877 46.8474 21.1794 47.9503 21.1794C49.8359 21.1794 52.5397 20.2544 52.5397 17.3549V16.75C52.5575 16.6611 52.5753 16.5722 52.5753 16.4832V9.38557ZM48.0037 15.6471C46.8296 15.6471 45.6022 14.7399 45.6022 12.9077C45.6022 11.0933 46.8296 10.1683 48.0037 10.1683C48.9998 10.1683 50.3162 10.8976 50.3162 12.9077C50.3162 14.9178 48.9998 15.6471 48.0037 15.6471Z'
        fill='black'
      />
      <path
        className='title'
        d='M62.2713 8.22931C61.631 8.22931 61.0973 8.76297 61.0973 9.38557V9.68798C60.7415 8.85191 59.7276 8.17595 58.4646 8.17595C56.5256 8.17595 54.1598 9.74134 54.1598 12.9077C54.1598 16.1452 56.5078 17.6573 58.4646 17.6573C60.0122 17.6573 60.955 16.6967 61.0973 16.1452V16.4832C61.0973 17.1236 61.631 17.6395 62.2713 17.6395C62.8939 17.6395 63.4632 17.1236 63.4632 16.4832V9.38557C63.4632 8.76297 62.8939 8.22931 62.2713 8.22931ZM58.8915 15.6471C57.7175 15.6471 56.4901 14.7399 56.4901 12.9077C56.4901 11.0933 57.7175 10.1683 58.8915 10.1683C59.8877 10.1683 61.204 10.8976 61.204 12.9077C61.204 14.9178 59.8877 15.6471 58.8915 15.6471Z'
        fill='black'
      />
      <path
        className='title'
        d='M70.7929 8.19373C68.8717 8.19373 67.8222 9.6524 67.7511 10.2928V9.40336C67.7511 8.76297 67.2174 8.22931 66.5948 8.22931C65.9544 8.22931 65.4208 8.76297 65.4208 9.40336V16.4654C65.4208 17.1058 65.9544 17.6217 66.5948 17.6217C67.2174 17.6217 67.7511 17.1058 67.7511 16.4654V12.1784C67.8222 11.6803 68.3559 10.1861 69.9568 10.1861C71.0064 10.1861 71.8424 10.8442 71.8424 12.4452V16.4654C71.8424 17.1058 72.3761 17.6217 72.9987 17.6217C73.6391 17.6217 74.1727 17.1058 74.1727 16.4654V12.4452C74.1727 9.36778 72.5896 8.19373 70.7929 8.19373Z'
        fill='black'
      />
      <path
        className='title'
        d='M77.3267 6.96632C77.9493 6.96632 78.5008 6.43267 78.5008 5.81007C78.5008 5.18747 77.9493 4.65381 77.3267 4.65381C76.6863 4.65381 76.1349 5.18747 76.1349 5.81007C76.1349 6.43267 76.6863 6.96632 77.3267 6.96632ZM77.3267 8.22931C76.6863 8.22931 76.1527 8.76297 76.1527 9.40336V16.4476C76.1527 17.1058 76.6863 17.6395 77.3267 17.6395C77.9493 17.6395 78.483 17.1058 78.483 16.4476V9.40336C78.483 8.76297 77.9493 8.22931 77.3267 8.22931Z'
        fill='black'
      />
      <path
        className='title'
        d='M87.3404 14.6688C86.9135 14.6688 86.5755 14.9178 86.4332 15.0423C86.0241 15.4337 85.4193 15.5938 84.939 15.5938C83.5337 15.5938 82.413 14.6154 82.413 12.9077C82.413 11.1822 83.5337 10.2038 84.939 10.2038C85.4193 10.2038 86.0241 10.3639 86.4332 10.7553C86.5755 10.8798 86.9135 11.1288 87.3404 11.1288C87.8207 11.1288 88.2832 10.7909 88.2832 10.2394C88.2832 9.67019 87.8563 9.38557 87.6251 9.15432C86.8779 8.49614 86.0063 8.17595 84.85 8.17595C82.6976 8.17595 80.1005 9.72355 80.1005 12.9077C80.1005 16.0741 82.6976 17.6217 84.85 17.6217C86.0063 17.6217 86.8779 17.2837 87.6251 16.6255C87.8563 16.4121 88.2832 16.1274 88.2832 15.5582C88.2832 15.0068 87.8207 14.6688 87.3404 14.6688Z'
        fill='black'
      />
      <path
        className='title'
        d='M103.104 6.11247C103.104 5.54324 102.606 5.08073 102.036 5.08073H96.1306C95.3657 5.08073 94.8143 5.63218 94.8143 6.39709V16.4298C94.8143 17.088 95.3657 17.6573 96.0239 17.6573C96.6821 17.6573 97.2157 17.088 97.2157 16.4298V12.6053H101.165C101.734 12.6053 102.214 12.1606 102.214 11.5914C102.214 11.0043 101.734 10.5596 101.165 10.5596H97.2157V7.17979H102.036C102.606 7.17979 103.104 6.69949 103.104 6.11247Z'
        fill='black'
      />
      <path
        className='title'
        d='M111.651 8.22931C111.011 8.22931 110.477 8.76297 110.477 9.38557V9.68798C110.122 8.85191 109.108 8.17595 107.845 8.17595C105.906 8.17595 103.54 9.74134 103.54 12.9077C103.54 16.1452 105.888 17.6573 107.845 17.6573C109.392 17.6573 110.335 16.6967 110.477 16.1452V16.4832C110.477 17.1236 111.011 17.6395 111.651 17.6395C112.274 17.6395 112.843 17.1236 112.843 16.4832V9.38557C112.843 8.76297 112.274 8.22931 111.651 8.22931ZM108.271 15.6471C107.097 15.6471 105.87 14.7399 105.87 12.9077C105.87 11.0933 107.097 10.1683 108.271 10.1683C109.268 10.1683 110.584 10.8976 110.584 12.9077C110.584 14.9178 109.268 15.6471 108.271 15.6471Z'
        fill='black'
      />
      <path
        className='title'
        d='M117.967 8.17595C116.277 8.17595 114.641 9.10095 114.641 10.8798C114.641 12.1428 115.388 13.1034 117.184 13.6015L118.198 13.9039C119.052 14.1529 119.248 14.5443 119.248 14.8467C119.248 15.3447 118.768 15.7183 117.896 15.7183C117.291 15.7183 116.722 15.5404 116.206 14.9C115.993 14.6332 115.726 14.3308 115.245 14.3308C114.605 14.3308 114.356 14.8289 114.356 15.2202C114.356 15.7717 114.747 16.2164 114.836 16.3409C115.334 16.9457 116.295 17.6217 117.896 17.6217C119.746 17.6217 121.56 16.6789 121.56 14.7221C121.56 13.139 120.369 12.463 118.945 12.0716L117.86 11.7514C117.042 11.5202 116.935 11.1466 116.935 10.862C116.935 10.3639 117.344 10.026 118.038 10.026C118.554 10.026 118.963 10.2216 119.319 10.6308C119.621 11.0043 119.959 11.1288 120.297 11.1288C120.813 11.1288 121.205 10.8264 121.205 10.2572C121.205 9.88365 121.027 9.63461 120.92 9.47451C120.333 8.65624 119.177 8.17595 117.967 8.17595Z'
        fill='black'
      />
      <path
        className='title'
        d='M127.214 15.5404C127.019 15.5404 126.734 15.6116 126.538 15.6116C126.147 15.6116 125.969 15.3447 125.969 14.8289V10.1149H127.143C127.73 10.1149 128.086 9.74134 128.086 9.20768C128.086 8.65624 127.73 8.28268 127.143 8.28268H125.969V7.07305C125.969 6.43267 125.453 5.89901 124.813 5.89901C124.173 5.89901 123.639 6.43267 123.639 7.07305V8.28268H123.123C122.554 8.28268 122.18 8.65624 122.18 9.20768C122.18 9.74134 122.554 10.1149 123.123 10.1149H123.639V14.7577C123.639 17.2303 124.866 17.6217 126.076 17.6217C127.286 17.6217 128.086 17.1948 128.086 16.3765C128.086 15.825 127.748 15.5048 127.214 15.5404Z'
        fill='black'
      />
    </>
  );
};

export default Fasting;
