import type { BrowserOptions } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import type { CommonTypes } from '@organicapps/organictypes';
import type { ILoggerContext, ILoggerEngine, ILoggerUser } from './types';

enum SENTRY_LEVELS {
  DEBUG = 'debug',
  FATAL = 'fatal',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  LOG = 'log',
}

export default class SentryLogger implements ILoggerEngine {
  private context: ILoggerContext = {};

  private user: ILoggerUser = {};

  constructor(opts: BrowserOptions) {
    Sentry.init(opts);
  }

  setUser(user: ILoggerUser): CommonTypes.CanPromise<void> {
    this.user = { ...this.user, ...user };
    Sentry.configureScope((scope) => {
      scope.setUser({
        ...this.user,
        ...{ id: this.user?.appUserId ?? '', email: this.user?.email ?? '' },
      });
    });
  }

  setContext(context: ILoggerContext): CommonTypes.CanPromise<void> {
    Sentry.configureScope((scope) => {
      if (context?.onboardingVariant) {
        scope.setTag('onboardingVariant', context.onboardingVariant);
      }
      if (context?.popupVariant) {
        scope.setTag('popupVariant', context.popupVariant);
      }
      if (context?.productName) {
        scope.setTag('productName', context.productName);
      }
      if (context?.utmSource) {
        scope.setTag('utmSource', context.utmSource);
      }
      if (context?.utmChannel) {
        scope.setTag('utmChannel', context.utmChannel);
      }
      this.context = { ...this.context, ...context };
      scope.setContext('globalContext', this.context);
    });
  }

  clearContext(): CommonTypes.CanPromise<void> {
    Sentry.configureScope((scope) => scope.clear());
  }

  logError(error: Error): ReturnType<ILoggerEngine['logError']> {
    Sentry.withScope((scope) => {
      scope.setLevel(SENTRY_LEVELS.ERROR);
      scope.setExtra('errorData', error);
      Sentry.captureException(error);
    });
  }

  logFatal(error: Error): ReturnType<ILoggerEngine['logFatal']> {
    Sentry.withScope((scope) => {
      scope.setLevel(SENTRY_LEVELS.FATAL);
      scope.setExtra('fatalErrorData', error);
      Sentry.captureException(error);
    });
  }

  logDebug(data: CommonTypes.SerializableValue, message = ''): ReturnType<ILoggerEngine['logDebug']> {
    Sentry.configureScope((scope) => {
      scope.addBreadcrumb({
        message,
        level: SENTRY_LEVELS.DEBUG,
        category: 'logDebug',
        data: { data },
      });
      scope.setExtra('debugData', data);
    });
  }

  logInfo(data: CommonTypes.SerializableValue, message = ''): ReturnType<ILoggerEngine['logInfo']> {
    Sentry.configureScope((scope) => {
      scope.addBreadcrumb({
        message,
        level: SENTRY_LEVELS.INFO,
        category: 'logInfo',
        data: { data },
      });
      scope.setExtra('infoData', data);
    });
  }

  logWarning(data: CommonTypes.SerializableValue, message = ''): ReturnType<ILoggerEngine['logWarning']> {
    Sentry.configureScope((scope) => {
      scope.addBreadcrumb({
        message,
        level: SENTRY_LEVELS.WARNING,
        category: 'logWarning',
        data: { data },
      });
      scope.setExtra('warningData', data);
    });
  }

  log(data: CommonTypes.SerializableValue, message = ''): ReturnType<ILoggerEngine['log']> {
    Sentry.configureScope((scope) => {
      scope.addBreadcrumb({
        message,
        level: SENTRY_LEVELS.LOG,
        category: 'log',
        data: { data },
      });
      scope.setExtra('logData', data);
    });
  }
}
