import React from 'react';

const Keto = () => {
  return (
    <>
      <path
        d='M7.8437 16.2021C6.63615 16.2021 5.46394 16.4669 4.46358 17.0356C3.388 17.647 2.50652 18.6006 1.85734 19.6826C1.30047 20.6571 0.882812 21.9101 0.882812 23.163C2.13577 23.163 3.38873 22.7454 4.36326 22.1885C6.45152 20.9356 7.8437 18.8473 7.8437 16.2021Z'
        fill='#E2432A'
      />
      <path
        d='M14.8046 2.28027H7.8437C5.33778 2.28027 3.1103 3.67245 1.85734 5.76072C1.30047 6.73524 0.882812 7.9882 0.882812 9.24116V16.202C0.882812 14.9491 1.30047 13.6961 1.85734 12.7216C2.41421 11.7471 3.38873 10.7726 4.36326 10.2157C5.33778 9.65881 6.59074 9.24116 7.8437 9.24116C10.3496 9.24116 12.5771 7.84898 13.8301 5.76072C14.3869 4.78619 14.8046 3.53323 14.8046 2.28027Z'
        fill='#FEA170'
      />
      <path
        d='M7.8437 9.24121C6.59074 9.24121 5.33778 9.65886 4.36326 10.2157C3.38873 10.7726 2.41421 11.7471 1.85734 12.7217C1.30047 13.6962 0.882812 14.9491 0.882812 16.2021V23.163C0.882812 21.91 1.30047 20.6571 1.85734 19.6825C3.1103 17.5943 5.19856 16.2021 7.8437 16.2021C7.8437 16.2021 8.81822 16.2021 9.23588 16.0629C11.1849 15.6452 12.8555 14.3923 13.8301 12.7217C14.3869 11.7471 14.8046 10.4942 14.8046 9.24121H7.8437Z'
        fill='#FF6952'
      />
      <path
        className='title'
        d='M59.5215 14.3255C59.5215 16.3461 61.1643 17.6902 63.712 17.6902C65.1527 17.6902 66.4881 17.2334 67.3226 16.513L68.0342 17.2509C68.2539 17.4793 68.5789 17.6199 68.8864 17.6199C69.4838 17.6199 69.967 17.1279 69.967 16.5393C69.967 16.267 69.8703 16.021 69.6771 15.819L68.8864 14.9932C69.5189 14.1586 69.8879 12.9287 69.8879 11.5758V11.2244C69.8879 10.583 69.4223 10.0999 68.8073 10.0999C68.2012 10.0999 67.7443 10.583 67.7443 11.2419V11.4264C67.7443 12.3313 67.6213 12.9902 67.3666 13.4382L65.3109 11.2068C66.9185 10.3283 67.6916 9.31799 67.6916 8.09686C67.6916 6.41012 66.2772 5.25928 64.2566 5.25928C62.2449 5.25928 60.7514 6.47162 60.7514 8.14957C60.7514 9.02808 61.1467 9.76603 62.2273 10.873C61.9813 11.0047 61.6387 11.1892 61.4718 11.3034C60.1452 12.1029 59.5215 13.0956 59.5215 14.3255ZM64.0546 10.0384C63.2639 9.27407 63.0004 8.85238 63.0004 8.29013C63.0004 7.56097 63.5187 7.05144 64.2303 7.05144C64.9419 7.05144 65.4339 7.55219 65.4339 8.29892C65.4339 9.0193 65.0385 9.49369 64.0546 10.0384ZM63.0004 12.4982C63.0882 12.4191 63.3254 12.2786 63.4221 12.2434L65.961 14.9844C65.5217 15.4676 64.7838 15.7662 64.0195 15.7662C62.8335 15.7662 62.034 15.0986 62.034 14.1059C62.034 13.4733 62.3415 12.9374 63.0004 12.4982Z'
        fill='black'
      />
      <path
        className='title'
        d='M30.8188 6.15967C30.8188 5.59043 30.3207 5.12793 29.7515 5.12793H23.8457C23.0807 5.12793 22.5293 5.67938 22.5293 6.44428V16.477C22.5293 17.1352 23.0807 17.7045 23.7389 17.7045C24.3971 17.7045 24.9308 17.1352 24.9308 16.477V12.6525H28.8798C29.4491 12.6525 29.9293 12.2078 29.9293 11.6385C29.9293 11.0515 29.4491 10.6068 28.8798 10.6068H24.9308V7.22698H29.7515C30.3207 7.22698 30.8188 6.74669 30.8188 6.15967Z'
        fill='black'
      />
      <path
        className='title'
        d='M39.3663 8.27651C38.7259 8.27651 38.1923 8.81016 38.1923 9.43277V9.73517C37.8365 8.89911 36.8226 8.22314 35.5596 8.22314C33.6206 8.22314 31.2547 9.78854 31.2547 12.9549C31.2547 16.1924 33.6028 17.7045 35.5596 17.7045C37.1072 17.7045 38.05 16.7439 38.1923 16.1924V16.5304C38.1923 17.1708 38.7259 17.6867 39.3663 17.6867C39.9889 17.6867 40.5582 17.1708 40.5582 16.5304V9.43277C40.5582 8.81016 39.9889 8.27651 39.3663 8.27651ZM35.9865 15.6943C34.8124 15.6943 33.585 14.7871 33.585 12.9549C33.585 11.1405 34.8124 10.2155 35.9865 10.2155C36.9827 10.2155 38.299 10.9448 38.299 12.9549C38.299 14.965 36.9827 15.6943 35.9865 15.6943Z'
        fill='black'
      />
      <path
        className='title'
        d='M45.6821 8.22314C43.9922 8.22314 42.3556 9.14815 42.3556 10.927C42.3556 12.19 43.1028 13.1506 44.8994 13.6487L45.9134 13.9511C46.7672 14.2001 46.9629 14.5915 46.9629 14.8939C46.9629 15.3919 46.4826 15.7655 45.6109 15.7655C45.0061 15.7655 44.4369 15.5876 43.921 14.9472C43.7076 14.6804 43.4407 14.378 42.9604 14.378C42.3201 14.378 42.071 14.8761 42.071 15.2674C42.071 15.8189 42.4624 16.2636 42.5513 16.3881C43.0494 16.9929 44.01 17.6689 45.6109 17.6689C47.461 17.6689 49.2754 16.7261 49.2754 14.7693C49.2754 13.1862 48.0836 12.5102 46.6605 12.1188L45.5754 11.7986C44.7571 11.5674 44.6504 11.1938 44.6504 10.9092C44.6504 10.4111 45.0595 10.0732 45.7533 10.0732C46.2691 10.0732 46.6783 10.2688 47.034 10.678C47.3364 11.0515 47.6744 11.176 48.0124 11.176C48.5283 11.176 48.9196 10.8736 48.9196 10.3044C48.9196 9.93085 48.7417 9.6818 48.635 9.52171C48.048 8.70343 46.8917 8.22314 45.6821 8.22314Z'
        fill='black'
      />
      <path
        className='title'
        d='M54.9294 15.5876C54.7337 15.5876 54.4491 15.6588 54.2534 15.6588C53.8621 15.6588 53.6842 15.3919 53.6842 14.8761V10.1621H54.8582C55.4453 10.1621 55.801 9.78854 55.801 9.25488C55.801 8.70343 55.4453 8.32987 54.8582 8.32987H53.6842V7.12025C53.6842 6.47986 53.1683 5.9462 52.5279 5.9462C51.8875 5.9462 51.3539 6.47986 51.3539 7.12025V8.32987H50.838C50.2688 8.32987 49.8952 8.70343 49.8952 9.25488C49.8952 9.78854 50.2688 10.1621 50.838 10.1621H51.3539V14.8049C51.3539 17.2775 52.5813 17.6689 53.7909 17.6689C55.0005 17.6689 55.801 17.2419 55.801 16.4237C55.801 15.8722 55.463 15.552 54.9294 15.5876Z'
        fill='black'
      />
      <path
        className='title'
        d='M84.362 15.9429L79.9327 10.8909L83.8995 6.764C84.024 6.6039 84.1485 6.33707 84.1485 6.07024C84.1485 5.48322 83.6505 5.00293 83.0634 5.00293C82.7255 5.00293 82.4053 5.1986 82.2096 5.41207L76.5528 11.1934V6.21255C76.5528 5.55437 76.0014 5.00293 75.3432 5.00293C74.685 5.00293 74.1514 5.55437 74.1514 6.21255V16.5121C74.1514 17.1525 74.685 17.704 75.3432 17.704C76.0014 17.704 76.5528 17.1525 76.5528 16.4943V14.3953L78.4384 12.4386L82.7788 17.3482C82.9745 17.5617 83.2591 17.704 83.5615 17.704C84.1485 17.704 84.6288 17.2237 84.6288 16.6544C84.6288 16.3876 84.5221 16.1208 84.362 15.9429Z'
        fill='black'
      />
      <path
        className='title'
        d='M89.4834 8.22266C87.4556 8.22266 84.8051 9.77027 84.8051 12.9722C84.8051 16.2097 87.3132 17.6684 89.6791 17.6684C90.7998 17.6684 91.6892 17.366 92.3474 16.9035C92.7921 16.5299 92.9166 16.2097 92.9166 15.9251C92.9166 15.4804 92.6142 15.1246 92.1162 15.1246C91.8138 15.1246 91.5647 15.2669 91.2445 15.4092C90.7109 15.6405 90.4085 15.7828 89.7681 15.7828C88.4695 15.7828 87.4378 15.0891 87.2243 13.7905L92.5075 13.8261C93.4503 13.8261 93.9662 13.5059 93.9662 12.5809C93.9662 9.82363 91.5113 8.22266 89.4834 8.22266ZM89.4834 10.2506C90.4974 10.2506 91.4936 10.8198 91.6714 12.1895H87.2243C87.3844 10.8198 88.4695 10.2506 89.4834 10.2506Z'
        fill='black'
      />
      <path
        className='title'
        d='M99.5548 15.5871C99.3592 15.5871 99.0746 15.6583 98.8789 15.6583C98.4875 15.6583 98.3096 15.3915 98.3096 14.8756V10.1616H99.4837C100.071 10.1616 100.426 9.78806 100.426 9.2544C100.426 8.70295 100.071 8.32939 99.4837 8.32939H98.3096V7.11977C98.3096 6.47938 97.7938 5.94572 97.1534 5.94572C96.513 5.94572 95.9793 6.47938 95.9793 7.11977V8.32939H95.4635C94.8942 8.32939 94.5207 8.70295 94.5207 9.2544C94.5207 9.78806 94.8942 10.1616 95.4635 10.1616H95.9793V14.8044C95.9793 17.277 97.2067 17.6684 98.4164 17.6684C99.626 17.6684 100.426 17.2415 100.426 16.4232C100.426 15.8717 100.088 15.5516 99.5548 15.5871Z'
        fill='black'
      />
      <path
        className='title'
        d='M106.067 8.22266C104.021 8.22266 101.317 9.80585 101.317 12.9722C101.317 16.1564 104.021 17.704 106.067 17.704C108.095 17.704 110.816 16.1564 110.816 12.9722C110.816 9.80585 108.095 8.22266 106.067 8.22266ZM106.067 10.2506C107.312 10.2506 108.504 11.2467 108.504 12.9722C108.504 14.6799 107.312 15.6761 106.067 15.6761C104.804 15.6761 103.63 14.6799 103.63 12.9722C103.63 11.2467 104.804 10.2506 106.067 10.2506Z'
        fill='black'
      />
    </>
  );
};

export default Keto;
