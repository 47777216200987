import { Logger } from 'modules/logger';
import { FingerprintType, IFingerprintBuilder, IFingerprintOptions } from './types';

export default class FingerprintFactory {
  private fingerprint: FingerprintType = {};

  private readonly builders: IFingerprintBuilder[];

  private readonly logger?: Logger;

  constructor(opts: IFingerprintOptions) {
    this.builders = opts.builders;
    this.logger = opts?.logger;
  }

  public async createFingerprint(): Promise<FingerprintType> {
    const results = await Promise.all(this.builders.map((builder) => builder.createFingerprint()));
    this.fingerprint = Object.fromEntries(results);
    this.logger?.logInfo(this.fingerprint, 'createFingerprint');
    return this.fingerprint;
  }
}
