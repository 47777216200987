import FORM, { FORM_NAMES } from '../constants/forms';

export const validateInput = (inputName: FORM_NAMES, inputValue: string): string => {
  const isEmpty = !inputValue.length;

  if (isEmpty) {
    return FORM[inputName].requiredError;
  }

  const regEx = FORM[inputName]?.regEx;

  if (regEx) {
    const isValid = inputValue.match(regEx);
    if (!isValid) {
      return FORM[inputName].validationError;
    }
  }
  return '';
};
