import React, { FC, useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { PAYWALL_TYPE } from '@organicapps/organictypes';
import CheckList from '../../components/organisms/CheckList/CheckList';
import Button from '../../components/atoms/Button/Button';
import Stepper from '../../components/molecules/Stepper/Stepper';
import withFullWidth from '../../HOCs/withFullWidth';
import CustomModal from '../../components/modals/CustomModal/CustomModal';
import { IPagesParams } from '../Offer';
import { PAGES } from '../../constants/pages';
import { useRouterWrapper } from '../../hooks/useRouterWrapper';
import { useAnalytics } from '../../hooks/useAnalytics';
import withPaywallTypeGuard from '../../HOCs/withPaywallTypeGuard';
import { EVENT_TYPES, EVENTS } from '../../constants/analytics';

import ManfitUpsellsImage from '../../assets/images/upsells/manfit-upsells-image.png';
import ManfitUpsellsBanner from '../../assets/images/upsells/manfit-upsells-banner.png';
import ManfitUpsellsModalCover from '../../assets/images/upsells/manfit-upsells-modal-cover.png';

const CONTENT = [
  'Fat burning workouts focused on your problem zones',
  'Your personal nutritional recommendations',
  'Daily healthy tips',
];

export const STEPPER_LIST = [
  'paywall.upsells.stepper.list0',
  'paywall.upsells.stepper.list1',
  'paywall.upsells.stepper.list2',
];

const StyledImage = withFullWidth(styled.img`
  width: 100%;
`);

const ManfitUpsells: FC = () => {
  const { productName } = useParams<keyof IPagesParams>() as IPagesParams;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const go = useRouterWrapper();
  const { logEvent } = useAnalytics();

  const handleClick = () => {
    go({ routeChangedPart: { page: PAGES.REGISTER } });
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  useEffect(() => {
    logEvent({
      type: EVENT_TYPES.VIEW,
      name: EVENTS.MANFIT_UPSELLS,
      properties: {},
    });
  }, []);

  return (
    <>
      <Stepper subStepsGifts={{ count: 1, activeOnStepIndex: 0 }} activeStep={-1} steps={STEPPER_LIST} />
      <Typography mt={5} variant='h2' align='center'>
        Gift your partner app access and achieve goals as a dynamic duo
      </Typography>
      <Box mt={5}>
        <StyledImage src={ManfitUpsellsImage} alt='manfit-upsell-image' />
        <StyledImage src={ManfitUpsellsBanner} alt='manfit-upsell-banner' />
      </Box>
      <Divider />
      <Typography color='text.secondary' mt={3} variant='h4' fontWeight={500}>
        Full access to app for one-time payment
      </Typography>
      <Button
        withAnimation
        sx={{ mt: 10 }}
        onClick={toggleModal}
        data-name='Get manfit upsells'
        data-page='Manfit Upsells'
        data-position='1'
      >
        Get an offer
      </Button>
      <Button
        sx={{ mt: 5, backgroundColor: 'grey.400' }}
        onClick={handleClick}
        data-name='Skip manfit upsells'
        data-page='Manfit Upsells'
        data-position='1'
      >
        Skip
      </Button>
      <Typography variant='h2' mt={10} align='center'>
        What will you get for one time payment?
      </Typography>
      <CheckList isPlainText my={5} customContent={CONTENT} />
      <CustomModal
        onClick={handleClick}
        title='Oops...'
        image={ManfitUpsellsModalCover}
        description='The limited offer is no longer available. Please continue your registration, and stay tuned for upcoming offers'
        buttonProps={{
          buttonText: 'Ok, got it!',
        }}
        productName={productName}
        onClose={toggleModal}
        open={isModalOpen}
      />
    </>
  );
};

export default withPaywallTypeGuard(ManfitUpsells, PAYWALL_TYPE.OFFER);
