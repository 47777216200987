import amplitude from 'amplitude-js';
import { Payments, PINTEREST_EVENT_NAMES, PRODUCT_NAMES, Products } from '@organicapps/organictypes';
import { usePaywallsStore, useSettingsStore, useUserStore } from '../store';
import {
  conversionMapper,
  EVENT_TYPES,
  EVENTS,
  FBQ_PURCHASE_EVENTS,
  fbqMapper,
  googleEventsMapper,
  PAYMENT_COMMISSION_MAPPER,
  pinterestEventsMapper,
  tiktokEventsMapper,
  VWOMapper,
} from '../constants/analytics';
import AnalyticsClient from './analyticsClient';
import { FB_PIXEL_KEYS, VWO_ACCOUNT_ID, VWO_API_URL } from '../constants/envKeysMapper';
import { reInitFacebookScript } from '../helpers/reInitFacebookScript';
import { SEARCH_PARAMS } from '../constants/searchParams';
import ApiClients from './index';

export type UserProperties = Record<string, unknown>;

export interface IEventPayload {
  type: EVENT_TYPES;
  name: EVENTS | string;
  properties: Record<string, unknown>;
  userProperties?: UserProperties;
}

const Analytics = (analyticsClient: AnalyticsClient) => ({
  async logToFb(payload: IEventPayload): Promise<void> {
    const { name } = payload;

    const userStore = useUserStore.getState();
    if (name === EVENTS.FINISH_PURCHASE) {
      const { currency } = userStore;
      const priceUSD =
        (await analyticsClient.getExchangeRate(
          currency,
          payload.properties?.Price ? Number(payload.properties?.Price) : 0
        )) ?? 0;

      const paymentCommission =
        PAYMENT_COMMISSION_MAPPER?.[payload.properties?.PaymentSystem as Payments.PaymentSystems]?.[
          payload.properties?.provider as Payments.PaymentProviders
        ]?.(priceUSD) || 0;

      FBQ_PURCHASE_EVENTS.forEach((name) => {
        window.fbq(
          'track',
          name,
          {
            value: priceUSD - paymentCommission,
            currency: 'USD',
          },
          { eventID: userStore.appUserId }
        );
      });
    } else if (name && fbqMapper[name as keyof typeof fbqMapper]) {
      window.fbq('track', fbqMapper[name as keyof typeof fbqMapper], {}, { eventID: userStore.appUserId });
    }
  },

  async logToGoogle(payload: IEventPayload) {
    const { name } = payload;

    const userStore = useUserStore.getState();
    if (name === EVENTS.FINISH_PURCHASE) {
      const { currency } = userStore;
      const priceUSD =
        (await analyticsClient.getExchangeRate(
          currency,
          payload.properties?.Price ? Number(payload.properties?.Price) : 0
        )) ?? 0;

      const paymentCommission =
        PAYMENT_COMMISSION_MAPPER?.[payload.properties?.PaymentSystem as Payments.PaymentSystems]?.[
          payload.properties?.provider as Payments.PaymentProviders
        ]?.(priceUSD) || 0;

      window?.gtag('event', googleEventsMapper[name], {
        value: priceUSD - paymentCommission,
        currency: 'USD',
      });
    } else {
      window?.gtag('event', googleEventsMapper[name as keyof typeof googleEventsMapper]);
    }
  },

  async logToTiktok(payload: IEventPayload) {
    const { name } = payload;

    const userStore = useUserStore.getState();
    const settingsStore = useSettingsStore.getState();
    const productName = settingsStore.productName as Products.Names;
    const eventName = tiktokEventsMapper[name as keyof typeof tiktokEventsMapper];

    if (name === EVENTS.FINISH_PURCHASE) {
      const { currency } = userStore;
      const priceUSD =
        (await analyticsClient.getExchangeRate(
          currency,
          payload.properties?.Price ? Number(payload.properties?.Price) : 0
        )) ?? 0;

      const paymentCommission =
        PAYMENT_COMMISSION_MAPPER?.[payload.properties?.PaymentSystem as Payments.PaymentSystems]?.[
          payload.properties?.provider as Payments.PaymentProviders
        ]?.(priceUSD) || 0;

      window?.ttq?.track(eventName, {
        event_id: `${eventName}${userStore.appUserId}`,
        value: priceUSD - paymentCommission,
        currency: 'USD',
        contents: [{ content_name: productName, content_id: productName }],
      });
    } else {
      window?.ttq?.track(eventName, {
        event_id: `${eventName}${userStore.appUserId}`,
        contents: [{ content_name: productName, content_id: productName }],
      });
    }
  },

  async logToPinterest(payload: IEventPayload) {
    const { name } = payload;

    const userStore = useUserStore.getState();
    if (name === EVENTS.FINISH_PURCHASE) {
      const { currency } = userStore;
      const priceUSD =
        (await analyticsClient.getExchangeRate(
          currency,
          payload.properties?.Price ? Number(payload.properties?.Price) : 0
        )) ?? 0;

      const paymentCommission =
        PAYMENT_COMMISSION_MAPPER?.[payload.properties?.PaymentSystem as Payments.PaymentSystems]?.[
          payload.properties?.provider as Payments.PaymentProviders
        ]?.(priceUSD) || 0;

      window?.pintrk?.('track', pinterestEventsMapper[name], {
        value: priceUSD - paymentCommission,
        currency: 'USD',
      });
    } else {
      window?.pintrk?.('track', pinterestEventsMapper[name as keyof typeof pinterestEventsMapper]);
    }
  },

  async logToConversion(payload: IEventPayload): Promise<void> {
    const { name, properties } = payload;

    const userStore = useUserStore.getState();
    const selectedOffer = usePaywallsStore.getState().selectedPaywallList;

    let data: {
      event: string;
      properties: Record<string, unknown>;
      additional?: Record<string, unknown>;
    } = {
      event: conversionMapper[name as keyof typeof conversionMapper],
      properties: {},
    };
    if (selectedOffer) {
      data = {
        ...data,
        properties: {
          price: properties.Price,
          interval: properties.Interval,
          intervalCount: properties['Interval Count'],
          productId: properties.ProductId,
          provider: properties?.PaymentSystem,
          paymentProvider: properties?.provider,
        },
      };
    }

    await analyticsClient.logToConversion(
      {
        ...data,
        userAgent: window.navigator.userAgent,
        ipAddress: userStore.ip,
        location: window.location.href,
        email: userStore.email,
        gender: 'f',
        fbp: properties.fbp,
        fbc: properties.fbc,
        ttclid: properties.ttclid,
        ttp: properties.ttp,
      },
      userStore.appUserToken
    );
  },

  async logUserEvent(payload: IEventPayload): Promise<void> {
    const userStore = useUserStore.getState();
    await analyticsClient.logUserEvent(payload, userStore.appUserToken);
  },

  async updateUserProperties(properties: Record<string, unknown>): Promise<void> {
    const userStore = useUserStore.getState();
    await analyticsClient.updateUserProperties(properties, userStore.appUserToken);
  },

  async logToAmplitude(payload: IEventPayload): Promise<void> {
    if (payload.userProperties && Object.entries(payload.userProperties).length) {
      this.setAmplitudeUserProperties(payload.userProperties);
    }
    this.sendAmplitudeData(`[Web ${payload.type}] ${payload.name}`, payload.properties);
  },

  logToIntercom(payload: IEventPayload) {
    const selectedOffer = usePaywallsStore.getState().selectedPaywallList;

    if (payload.userProperties && Object.entries(payload.userProperties).length) {
      this.setIntercomUserProperties({
        ...payload.userProperties,
        ...(selectedOffer &&
          payload.name === EVENTS.FINISH_PURCHASE && {
            subscriptionCreatedDate: Intl.DateTimeFormat('en', { dateStyle: 'long' }).format(new Date()),
            subscriptionDuration: `${selectedOffer.price.intervalCount} ${selectedOffer.price.interval}`,
            subscriptionPrice: `${selectedOffer.price.price} ${selectedOffer.price.currency}`.toUpperCase(),
            subscriptionTrialDuration: `${selectedOffer.price.trial?.intervalCount} ${selectedOffer.price.trial?.interval}`,
            subscriptionTrialPrice: `${selectedOffer.price.trial?.price} ${selectedOffer.price.currency}`.toUpperCase(),
          }),
      } as Intercom_.IntercomSettings);
    }
  },

  async logToVWO(payload: IEventPayload, productName: Products.Names) {
    let eventName;
    let eventProps;
    if (payload.name === EVENTS.PAYWALL) {
      eventName = `${productName}${EVENTS.PAYWALL}`;
      eventProps = { version: payload.properties?.version };
    } else if (payload.name === EVENTS.FINISH_PURCHASE) {
      eventName = `${productName}${VWOMapper[EVENTS.FINISH_PURCHASE]}`;
      eventProps = { price: payload.properties?.Price };
    }

    if (eventName) {
      const { searchParams } = new URL(window.location.href);
      const isSearchParamsHaveVwoUuid = searchParams.has(SEARCH_PARAMS.VWO_UUID);

      if (isSearchParamsHaveVwoUuid) {
        try {
          const vwoUuid = searchParams.get(SEARCH_PARAMS.VWO_UUID);
          if (vwoUuid) {
            const baseApi = ApiClients.getBaseApi();
            const event = this.getVWOOfflineEventPayload(eventName, vwoUuid, eventProps);
            await baseApi.post(
              `${VWO_API_URL}/events/t?${new URLSearchParams({ en: eventName, a: VWO_ACCOUNT_ID })}`,
              event,
              {
                mode: 'no-cors',
              }
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  },

  getVWOOfflineEventPayload(eventName: string, vwoUuid: string, eventProps: { [key: string]: unknown } = {}) {
    const timeStamp = Date.now();
    return {
      d: {
        msgId: `${vwoUuid}-${timeStamp}`,
        visId: vwoUuid,
        event: {
          props: {
            ...eventProps,
            page: {
              title: 'WebPaywalls',
              url: window.location.href,
              referrerUrl: window.location.href,
            },
          },
          name: eventName,
          time: timeStamp,
        },
        sessionId: timeStamp / 1000,
      },
    };
  },

  setIntercomUserProperties(userProperties: Intercom_.IntercomSettings) {
    const mappedUserProperties = { ...userProperties };
    if (userProperties?.Email && typeof userProperties?.Email === 'string') {
      mappedUserProperties.email = userProperties.Email;
      delete mappedUserProperties?.Email;
    }
    if (userProperties?.firstName && typeof userProperties?.firstName === 'string') {
      mappedUserProperties.name = userProperties.firstName;
      delete mappedUserProperties?.firstName;
    }
    if (userProperties?.lastName && typeof userProperties?.lastName === 'string') {
      mappedUserProperties.name =
        (mappedUserProperties.name ? `${mappedUserProperties.name} ` : '') + userProperties.lastName;
      delete mappedUserProperties?.lastName;
    }
    window?.Intercom('update', {
      ...mappedUserProperties,
    });
  },

  initAmplitude(key: string) {
    const { appUserId } = useUserStore.getState();
    amplitude.getInstance().init(key);
    this.setAmplitudeUserId(appUserId);
  },

  setAmplitudeUserId(userId: string) {
    amplitude.getInstance().setUserId(userId);
  },

  sendAmplitudeData(eventType: string, eventProperties: Record<string, unknown>) {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  },

  setAmplitudeUserProperties(userProperties: Record<string, unknown>) {
    amplitude.getInstance().setUserProperties({
      ...userProperties,
    });
  },

  initGoogleTag(key: string) {
    const { appUserId } = useUserStore.getState();
    window?.gtag('config', key, { user_id: appUserId });
  },

  iniTiktok(key: string) {
    const { appUserId, email } = useUserStore.getState();
    window?.ttq?.load(key);
    window?.ttq?.page();
    window?.ttq?.identify({ email: email || `${appUserId}@myorganicapps.com`, external_id: appUserId });
  },

  initPinterest(key: string) {
    const { appUserId, email } = useUserStore.getState();
    window?.pintrk?.(PINTEREST_EVENT_NAMES.LOAD, key, {
      em: email || `${appUserId}@myorganicapps.com`,
      external_id: appUserId,
    });
    window?.pintrk?.(PINTEREST_EVENT_NAMES.PAGE);
  },

  initFbPixel(key: string, reloadScript = false) {
    if (reloadScript) this.reloadFacebookScript();

    const { appUserId, email, firstName, lastName, city, country } = useUserStore.getState();
    const params = {
      external_id: appUserId,
      em: email,
      ge: key === FB_PIXEL_KEYS[PRODUCT_NAMES.MAN] ? 'm' : 'f',
      fn: firstName ?? undefined,
      ln: lastName ?? undefined,
      ct: city ?? undefined,
      country: country ?? undefined,
    };
    window.fbq('init', key, params);
  },

  reloadFacebookScript() {
    window.fbq = null;
    window._fbq = null;
    const script = Array.from(document.getElementsByTagName('script')).find((script) =>
      script.src.includes('fbevents.js')
    );
    if (script) script.remove();
    reInitFacebookScript();
  },

  initIntercom(key: string, url: string) {
    const {
      appUserId,
      email,
      firstName,
      lastName,
      onboardingVariant,
      popupVariant,
      localCurrency,
      currency,
      exchangeRate,
    } = useUserStore.getState();

    const {
      settings: { variant, version, displayCurrency },
    } = usePaywallsStore.getState();

    const { productName } = useSettingsStore.getState();

    const { searchParams } = new URL(window.location.href);
    const utm_campaign = searchParams.get('utm_campaign') as string;
    const utm_content = searchParams.get('utm_content') as string;
    const utm_medium = searchParams.get('utm_medium') as string;
    const utm_source = searchParams.get('utm_source') as string;
    const utm_term = searchParams.get('utm_term') as string;

    window?.Intercom('boot', {
      api_base: url,
      app_id: key,
      name: (firstName ? `${firstName} ` : '') + lastName,
      email,
      appUserId,
      user_id: appUserId,
      onboardingVariant,
      popupVariant,
      localCurrency,
      currency,
      exchangeRate,
      paywallVersion: version,
      paywallVariant: variant,
      displayCurrency,
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      utm_term,
      app: productName,
    });
  },
});

export default Analytics;
