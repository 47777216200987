import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { keyframes } from '@emotion/react';
import { ButtonProps } from '@mui/material/Button/Button';
import styled from '@emotion/styled';
import { hexToRgb } from '@mui/system';
import withPositionFixed from '../../../HOCs/withPositionFixed';

const ButtonShadow = (themeColor: string) => {
  const rgb = hexToRgb(`${themeColor}4D`);
  return keyframes`
    from, 0%, to {
      box-shadow: 0 0 0 0 ${rgb}, 0 0 0 0 ${rgb};
    }
    18%, to {
      box-shadow: 0 0 0 0 ${rgb}, 0 0 0 0 ${rgb};
    }
    100% {
      box-shadow: 0 0 0 16px transparent, 0 0 0 27px transparent;
    }
  `;
};

const StyledButton = styled(Button)<{ animation: number }>(({ theme, animation, disabled }) => ({
  animationDuration: '3s !important',
  animationTimingFunction: 'ease',
  animationDelay: '0s',
  animationIterationCount: 'infinite !important',
  animationDirection: 'normal',
  animationFillMode: 'none',
  animationPlayState: 'running',
  animation: animation && !disabled ? ButtonShadow(theme.palette.primary.main) : 'none',
}));

export interface IExtendedButtonProps extends ButtonProps {
  withAnimation?: boolean;
  'data-name'?: string;
  'data-position'?: string;
  'data-page'?: string;
}

const MyButton: FC<IExtendedButtonProps> = React.forwardRef(({ children, withAnimation = false, ...rest }, ref) => {
  return (
    <StyledButton ref={ref} animation={+withAnimation} {...rest}>
      {children}
    </StyledButton>
  );
});

export default withPositionFixed(MyButton, false);
