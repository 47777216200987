import withProductGuard from '../HOCs/withProductGuard';
import withAnalytics from '../HOCs/withAnalytics';
import CommonLayout from './CommonLayout';
import withSubscriptionState from '../HOCs/withSubscriptionState';
import withSearchParamsGuard from '../HOCs/withSearchParamsGuard';
import { SEARCH_PARAMS } from '../constants/searchParams';

export default withProductGuard(
  withSearchParamsGuard(withSubscriptionState(withAnalytics(CommonLayout)), [SEARCH_PARAMS.ACCESS_TOKEN])
);
