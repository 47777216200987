import React from 'react';
import styled from '@emotion/styled';
import { Box, CircularProgress } from '@mui/material';

const StyledBox = styled(Box)`
  position: absolute;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loader = () => {
  return (
    <StyledBox>
      <StyledCircularProgress size={40} color='primary' />
    </StyledBox>
  );
};

export default Loader;
