import type { ICustomComponent } from './types';
import { CUSTOM_COMPONENTS } from './constants';

const NAME = 'price-list';

export default class PriceListComponent extends HTMLElement implements ICustomComponent {
  readonly binding = CUSTOM_COMPONENTS.PRICE_LIST;

  readonly element = NAME;
}

customElements.define(NAME, PriceListComponent);
