import { ResponseType } from './types';

interface IRequestConfig extends RequestInit {
  baseURL?: string;
  responseType?: ResponseType;
}

abstract class Config implements IRequestConfig {
  body?: BodyInit | null;

  integrity?: string;

  keepalive?: boolean;

  method?: string;

  mode?: RequestMode;

  redirect?: RequestRedirect;

  referrer?: string;

  referrerPolicy?: ReferrerPolicy;

  signal?: AbortSignal | null;

  window?: any;

  headers?: Record<string, string>;

  readonly baseURL?: string;

  readonly responseType?: ResponseType;

  normalizeHeaders(headers: IRequestConfig['headers']) {
    if (Array.isArray(headers)) {
      return headers.reduce((acc, el) => {
        const [key, value] = el;
        return { ...acc, [key]: value };
      }, {});
    }
    if (headers instanceof Headers) {
      const result: Record<string, string> = {};
      headers.forEach((value, key) => {
        result[key] = value;
      });
      return result;
    }
    return headers;
  }
}

export class DefaultConfig extends Config implements IRequestConfig {
  constructor(opts?: IRequestConfig) {
    super();
    Object.assign(this, opts);
    if (this.headers) {
      this.headers = this.normalizeHeaders(this.headers);
    }
  }
}

export class RequestConfig extends Config implements IRequestConfig {
  readonly url: string;

  constructor(defaultConfig: DefaultConfig, request: RequestInit, url: string) {
    super();
    Object.assign(this, defaultConfig);
    const defaultHeaders = this.headers ?? {};
    Object.assign(this, request);
    if (request?.headers) {
      const headers = this.normalizeHeaders(request.headers);
      this.headers = { ...defaultHeaders, ...headers };
    }
    this.url = this.getFullUrl(url);
  }

  private isValidUrl(url: string): boolean {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  }

  getFullUrl(url: string) {
    return url && this.isValidUrl(url) ? url : `${this.baseURL}${url || ''}`;
  }
}
