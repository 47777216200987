import BaseApi from './baseApi';
import { IEventPayload } from './analytics';

export default class AnalyticsClient {
  private baseApi: BaseApi;

  constructor(baseApi: BaseApi) {
    this.baseApi = baseApi;
  }

  async getExchangeRate(from: string, amount: number) {
    const response = await this.baseApi.get<{ value: number }>(`/exchange-rate/convert?from=${from}&amount=${amount}`);
    const data = await response.data;
    return data?.value;
  }

  async updateUtmProperties(payload: Record<string, string>, onboardingId: string) {
    await this.baseApi.patch(`/v1/onboarding/${onboardingId}/utm`, payload);
  }

  async updateUserProperties(payload: Record<string, unknown>, appUserToken: string) {
    await this.baseApi.post('/analytics/user-properties', payload, {
      headers: {
        'x-session-token': appUserToken,
      },
    });
  }

  async logUserEvent(payload: IEventPayload, appUserToken: string) {
    const { type, name, properties } = payload;
    await this.baseApi.post(
      '/analytics/user-event',
      {
        name: `[Web ${type}] ${name}`,
        properties,
      },
      {
        headers: {
          'x-session-token': appUserToken,
        },
      }
    );
  }

  async logToConversion(payload: Record<string, unknown>, appUserToken: string) {
    await this.baseApi.post('/analytics/conversion', payload, {
      headers: {
        'x-session-token': appUserToken,
      },
    });
  }
}
