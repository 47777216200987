import React, { FC } from 'react';
import styled from '@emotion/styled';
import SvgIcon from '@mui/material/SvgIcon';
import { keyframes } from '@emotion/react';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

const dashCheck = keyframes`
  from, 0%, to {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
`;

const dash = keyframes`
  from, 0%, to {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;
const StyledSvg = styled(SvgIcon)(({ theme }) => ({
  width: '100px',
  display: 'block',
  margin: theme.spacing(0, 'auto', 5, 'auto'),
  scale: '3',
  '.path': {
    strokeDasharray: 1000,
    strokeDashoffset: 0,
    '&.circle': {
      animation: `${dash} .9s ease-in-out`,
    },
    '&.line': {
      strokeDashoffset: 1000,
      animation: `${dash} .9s .35s ease-in-out forwards`,
    },
    '&.check': {
      strokeDashoffset: -100,
      animation: `${dashCheck} .9s .35s ease-in-out forwards`,
    },
  },
}));

const CheckAnimated: FC<SvgIconProps> = (props) => {
  return (
    <StyledSvg {...props} version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 130.2 130.2'>
      <circle
        className='path circle'
        fill='none'
        stroke='#73AF55'
        strokeWidth='6'
        strokeMiterlimit='10'
        cx='65.1'
        cy='65.1'
        r='62.1'
      />
      <polyline
        className='path check'
        fill='none'
        stroke='#73AF55'
        strokeWidth='6'
        strokeLinecap='round'
        strokeMiterlimit='10'
        points='100.2,40.2 51.5,88.8 29.8,67.5'
      />
    </StyledSvg>
  );
};

export default CheckAnimated;
