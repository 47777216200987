import React, { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { PAYWALL_TYPE, PAYWALL_VERSION } from '@organicapps/organictypes';
import { useAnalytics } from '../../hooks/useAnalytics';
import { PAGES } from '../../constants/pages';
import { usePaywallsStore, useUserStore } from '../../store';
import { IRoutes } from '../Offer';
import withPaywallTypeGuard from '../../HOCs/withPaywallTypeGuard';
import { EVENT_TYPES, EVENTS } from '../../constants/analytics';
import { useRouterWrapper } from '../../hooks/useRouterWrapper';

const v1 = React.lazy(() => import('./v1'));

const routes: IRoutes = {
  [PAYWALL_VERSION.V1]: v1,
};

const FullBodyWorkoutsRouter = () => {
  const { logEvent } = useAnalytics();
  const go = useRouterWrapper();

  const { isPayed } = useUserStore((state) => ({
    isPayed: state.paid,
  }));
  const { paywallVersion } = usePaywallsStore((state) => ({
    paywallVersion: state.settings.version,
  }));

  const Page = routes[paywallVersion] as FC;

  useEffect(() => {
    logEvent({
      type: EVENT_TYPES.VIEW,
      name: EVENTS.EMPTY_NAME,
      properties: {
        version: paywallVersion,
      },
    });
  }, []);

  useEffect(() => {
    if (isPayed) {
      go({ routeChangedPart: { page: PAGES.SUCCESS } });
    }
  }, [isPayed]);

  return <> {Page ? <Page /> : <Navigate to={PAGES.NOT_FOUND} replace />}</>;
};

export default withPaywallTypeGuard(FullBodyWorkoutsRouter, PAYWALL_TYPE.FULL_BODY_WORKOUTS);
