import FingerprintFactory from './factory';
import type { IFingerprintOptions } from './types';

export type {
  IFingerprintOptions,
  IFingerprintBuilder,
  IFingerprintBuilderOpts,
  Fingerprint,
  FingerprintType,
} from './types';

export default function factory(opts: IFingerprintOptions): InstanceType<typeof FingerprintFactory> {
  return new FingerprintFactory(opts);
}
