import { State, StateCreator, StoreMutatorIdentifier } from 'zustand';
import { ENV, NODE_ENV } from '../../constants/envKeysMapper';

type Logger = <
  T extends State,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = []
>(
  f: StateCreator<T, Mps, Mcs>,
  name?: string
) => StateCreator<T, Mps, Mcs>;

type LoggerImpl = <T extends State>(f: StateCreator<T, [], []>, name?: string) => StateCreator<T, [], []>;

const loggerImpl: LoggerImpl = (f, name) => (set, get, store) => {
  type T = ReturnType<typeof f>;
  const loggedSet: typeof set = (...a) => {
    set(...a);
    if (NODE_ENV === ENV.DEVELOPMENT) {
      console.log(...(name ? [`${name}:`] : []), get());
    }
  };
  // eslint-disable-next-line
  store.setState = loggedSet;

  return f(loggedSet, get, store);
};

const logger = loggerImpl as unknown as Logger;
export default logger;
