import React, { ComponentType, FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAnalytics } from '../hooks/useAnalytics';
import { EVENT_TYPES, EVENTS } from '../constants/analytics';
import { CommonLayoutProps } from '../layouts/CommonLayout';
import { SEARCH_PARAMS } from '../constants/searchParams';
import { camelCaseSearchParams } from '../helpers/parseSearchParams';
import { useLogger } from '../hooks/useLogger';

const withAnalytics = <P extends CommonLayoutProps>(Component: ComponentType<P>): FC<P> => {
  return (props: P) => {
    const { isLoading } = props;

    const [searchParams] = useSearchParams();
    const logger = useLogger();

    const { logEvent, logUtmParams } = useAnalytics();

    useEffect(() => {
      if (!isLoading) {
        if (searchParams.get(SEARCH_PARAMS.UTM_SOURCE) || searchParams.get(SEARCH_PARAMS.UTM_CHANNEL)) {
          logUtmParams({
            utmSource: searchParams.get(SEARCH_PARAMS.UTM_SOURCE),
            utmChannel: searchParams.get(SEARCH_PARAMS.UTM_CHANNEL),
            utmAdId: searchParams.get(SEARCH_PARAMS.UTM_AD_ID),
            utmEmailMessageId: searchParams.get(SEARCH_PARAMS.UTM_EMAIL_MESSAGE_ID),
            utmEmailWorkflowId: searchParams.get(SEARCH_PARAMS.UTM_EMAIL_WORKFLOW_ID),
          });
        }
      }
    }, [isLoading]);

    useEffect(() => {
      if (!isLoading) {
        logEvent({ name: EVENTS.PAGE_VIEW, type: EVENT_TYPES.VIEW, properties: {}, userProperties: {} });
      }
    }, [isLoading]);

    useEffect(() => {
      if (!isLoading) {
        window.addEventListener('click', (event: MouseEvent) => {
          if (event?.target && (event.target as HTMLButtonElement).type === 'button') {
            const button = event.target as HTMLButtonElement;
            if (button?.dataset?.name) {
              const { name, ...rest } = button.dataset;
              logEvent({
                name: `${button.dataset.name} Clicked`,
                type: EVENT_TYPES.ACTION,
                properties: rest,
                userProperties: {},
              });
            }
          }
        });
      }
    }, [isLoading]);

    useEffect(() => {
      if (!isLoading) {
        const camelCasedSearchParams = camelCaseSearchParams(window.location.search);
        // delete coz we set these params in another components
        delete camelCasedSearchParams.theme;
        delete camelCasedSearchParams.locale;
        delete camelCasedSearchParams.popupVariant;
        delete camelCasedSearchParams.onboardingVariant;
        logger.setContext(camelCasedSearchParams);
      }
    }, [isLoading]);

    return <Component {...(props as P)} />;
  };
};

export default withAnalytics;
