import React, { ComponentType, PropsWithChildren, ReactElement } from 'react';
import { GlobalVariablesProvider } from './GlobalVariablesProvider';
// import { LocaleProvider } from './LocaleProvider';
// import { Container as ThemeProvider } from './ThemeProvider';

type ProviderProps = object;
type ComponentWithProps = [ComponentType<ProviderProps>, ProviderProps];

const buildProvidersTree = (componentsWithProps: ComponentWithProps[]) => {
  const initialComponent: ComponentType<PropsWithChildren<ProviderProps>> = ({ children }) => <>{children}</>;
  return componentsWithProps.reduce((AccumulatedComponents, [Provider, props = {}]) => {
    return ({ children }: PropsWithChildren<ProviderProps>): ReactElement => {
      return (
        <AccumulatedComponents>
          <Provider {...props}>{children}</Provider>
        </AccumulatedComponents>
      );
    };
  }, initialComponent);
};

// Order important
export const ProvidersTree = buildProvidersTree([
  // [ProductNameProvider, {}],
  // [LocaleProvider, {}],
  // [ThemeProvider, {}],
  [GlobalVariablesProvider, {}],
]);
