import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type { CommonTypes, Layouts } from '@organicapps/organictypes';
import logger from './middlewares/loggerMiddleware';
import { ENV, NODE_ENV } from '../constants/envKeysMapper';

interface ILayoutsState {
  paywallLayout: CommonTypes.Nullable<Layouts.IPaywall>;
  setPaywallLayout: (layout: Layouts.IPaywall) => void;
}

export const useLayoutsStore = create<ILayoutsState>()(
  logger(
    devtools(
      persist(
        (set, get) => ({
          paywallLayout: null,
          setPaywallLayout: (layout: Layouts.IPaywall) =>
            set({ paywallLayout: layout }, false, 'layouts/setPaywallLayout'),
        }),
        {
          name: 'layoutsState',
          getStorage: () => sessionStorage,
        }
      ),
      { name: 'useLayoutsStore', enabled: NODE_ENV === ENV.DEVELOPMENT }
    ),
    '[layoutsStore]'
  )
);
