import React, { ComponentType, FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PRODUCT_NAMES, Products } from '@organicapps/organictypes';
import { DYNAMIC_ROUTE_PARAMS, PAGES } from '../constants/pages';
import { useRouterWrapper } from '../hooks/useRouterWrapper';
import { includes } from '../helpers/includes';

export interface IProductPageParams {
  [DYNAMIC_ROUTE_PARAMS.PRODUCT_NAME]: Products.Names;
}

const withProductGuard = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof IProductPageParams>> => {
  return (props: Omit<P, keyof IProductPageParams>) => {
    const { productName } = useParams<keyof IProductPageParams>() as IProductPageParams;
    const go = useRouterWrapper();
    const newProps = { ...props, productName };
    const [isRouteValid, setIsRouteValid] = useState<boolean>(false);

    useEffect(() => {
      if (productName && !includes(Object.values(PRODUCT_NAMES), productName)) {
        go({ routeChangedPart: { page: PAGES.NOT_FOUND }, replace: true });
      } else {
        setIsRouteValid(true);
      }
    }, []);

    return <>{isRouteValid && <Component {...(newProps as P & IProductPageParams)} />}</>;
  };
};

export default withProductGuard;
