import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';
import { Locales, LOCALES_ARRAY, LOCALES_LIST, Products } from '@organicapps/organictypes';
import { ENV, NODE_ENV } from './constants/envKeysMapper';
import ApiClients from './api';
import { useSettingsStore } from './store';
import { ApiError } from './api/baseApi';

const params = new URLSearchParams(window.location.search.toLowerCase());
const processor = new PhraseInContextEditorPostProcessor({
  phraseEnabled: params.get('incontexteditorenabled') === 'true' && NODE_ENV === ENV.DEVELOPMENT,
  projectId: '0daa7d83dcc43962b46f325a7499a448',
});

const options = {
  order: ['path', 'navigator'],
  lookupFromPathIndex: 0,
};

const requestedKeys = new Set();

const i18nInstance = (keyPrefix: Products.Names, lang?: Locales.List) => {
  return i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(processor)
    .use(ChainedBackend)
    .init({
      lng: lang,
      fallbackLng: LOCALES_LIST.EN,
      debug: false, // enable, if needed or set NODE_ENV === ENV.DEVELOPMENT
      backend: {
        backends: [HttpBackend],
      },
      saveMissing: true,
      missingKeyHandler: async (locales: readonly string[], ns: string, key: string) => {
        try {
          if (!requestedKeys.has(key)) {
            requestedKeys.add(key);
            const { forceMissedKeysUpdate } = useSettingsStore.getState();
            const lang = i18n.language;
            const userClient = ApiClients.getUserClient();
            const translation = await userClient.getTranslation(lang, key, forceMissedKeysUpdate);
            i18n.addResource(lang, ns, key, translation);
          }
        } catch (e) {
          if (NODE_ENV === ENV.DEVELOPMENT) {
            if (e instanceof ApiError) {
              console.error(e.message);
            } else {
              console.error('Something went wrong with missingKeyHandler request');
            }
          }
        }
      },
      react: {
        bindI18nStore: 'added',
        useSuspense: true,
        keyPrefix,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 'em', 'span', 's'],
      },
      detection: options,
      supportedLngs: LOCALES_ARRAY,
      keySeparator: '.',
      interpolation: {
        escapeValue: false,
      },
      postProcess: ['phraseInContextEditor'],
    });
};

export default i18nInstance;
