import { createTheme } from '@mui/material/styles';
import { DARK_THEME_COLORS, GREY_THEME_COLORS, LIGHT_THEME_COLORS } from '../constants/colors';
import { darkTheme, greyTheme, lightTheme, THEME_MODES } from './common';

const danceLightTheme = createTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    primary: {
      main: LIGHT_THEME_COLORS.PRIMARY_DANCE,
      dark: LIGHT_THEME_COLORS.PRIMARY_DANCE_DARK,
      light: LIGHT_THEME_COLORS.PRIMARY_DANCE_LIGHT,
    },
    secondary: {
      main: LIGHT_THEME_COLORS.SECONDARY_DANCE,
      light: LIGHT_THEME_COLORS.SECONDARY_DANCE_LIGHT,
      dark: LIGHT_THEME_COLORS.SECONDARY_DANCE_DARK,
    },
    gradient: {
      main: LIGHT_THEME_COLORS.SPECIAL_OFFER_DANCE_GRADIENT,
    },
  },
});

const danceGreyTheme = createTheme({
  ...greyTheme,
  palette: {
    ...greyTheme.palette,
    primary: {
      main: GREY_THEME_COLORS.PRIMARY_DANCE,
      dark: GREY_THEME_COLORS.PRIMARY_DANCE_DARK,
      light: GREY_THEME_COLORS.PRIMARY_DANCE_LIGHT,
    },
    secondary: {
      main: GREY_THEME_COLORS.SECONDARY_DANCE,
      light: GREY_THEME_COLORS.SECONDARY_DANCE_LIGHT,
      dark: GREY_THEME_COLORS.SECONDARY_DANCE_DARK,
    },
    gradient: {
      main: GREY_THEME_COLORS.SPECIAL_OFFER_DANCE_GRADIENT,
    },
  },
});

const danceDarkTheme = createTheme({
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    primary: {
      main: DARK_THEME_COLORS.PRIMARY_DANCE,
      dark: DARK_THEME_COLORS.PRIMARY_DANCE_DARK,
      light: DARK_THEME_COLORS.PRIMARY_DANCE_LIGHT,
    },
    secondary: {
      main: DARK_THEME_COLORS.SECONDARY_DANCE,
      light: DARK_THEME_COLORS.SECONDARY_DANCE_LIGHT,
      dark: DARK_THEME_COLORS.SECONDARY_DANCE_DARK,
    },
    gradient: {
      main: DARK_THEME_COLORS.SPECIAL_OFFER_DANCE_GRADIENT,
    },
  },
});

export const danceThemes = {
  [THEME_MODES.DARK]: danceDarkTheme,
  [THEME_MODES.LIGHT]: danceLightTheme,
  [THEME_MODES.GREY]: danceGreyTheme,
};
