import React, { ComponentType, FC } from 'react';
import { IProductPageParams } from './withProductGuard';
import { useInitDivkitState } from '../hooks/useInitDivkitState';

export interface IWithPaywallStateProps extends Object {
  isLoading: boolean;
}

const withPaywallDivkitState = <P extends IWithPaywallStateProps>(
  Component: ComponentType<P>
): FC<Omit<P, keyof IWithPaywallStateProps> & IProductPageParams> => {
  return (props: Omit<P, keyof IWithPaywallStateProps> & IProductPageParams) => {
    const { productName } = props;
    const [isLoading] = useInitDivkitState(productName);
    const newProps = { ...props, isLoading };

    return <Component {...(newProps as P & IWithPaywallStateProps & IProductPageParams)} />;
  };
};

export default withPaywallDivkitState;
