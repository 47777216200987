import { PRODUCT_NAMES } from '@organicapps/organictypes';

export const APP_CLIP_KEYS = {
  [PRODUCT_NAMES.DANCE]: {
    bundleId: 'com.myorganicapps.organicman.Clip',
    appId: '6443817461',
  },
  [PRODUCT_NAMES.FIT]: {
    bundleId: 'com.myorganicapps.organicman.Clip',
    appId: '6443817461',
  },
  [PRODUCT_NAMES.FASTING]: {
    bundleId: 'com.myorganicapps.organicman.Clip',
    appId: '6443817461',
  },
  [PRODUCT_NAMES.KETO]: {
    bundleId: 'com.myorganicapps.organicman.Clip',
    appId: '6443817461',
  },
  [PRODUCT_NAMES.VEGAN]: {
    bundleId: 'com.myorganicapps.organicman.Clip',
    appId: '6443817461',
  },
  [PRODUCT_NAMES.WALK]: {
    bundleId: 'com.myorganicapps.organicman.Clip',
    appId: '6443817461',
  },
  [PRODUCT_NAMES.MAN]: {
    bundleId: 'com.myorganicapps.organicman.Clip',
    appId: '6443817461',
  },
};
