import type { CommonTypes } from '@organicapps/organictypes';
import type { ILoggerContext, ILoggerEngine, ILoggerOptions, ILoggerUser } from './types';
import DefaultEngine from './engines';

export default class LoggerFactory {
  private readonly engines: ILoggerEngine[];

  constructor(opts?: ILoggerOptions) {
    this.engines = opts?.engines ?? [new DefaultEngine()];
  }

  public async setUser(user: ILoggerUser): Promise<void> {
    Promise.all(this.engines.map((engine) => engine.setUser(user)));
  }

  public async setContext(context: ILoggerContext): Promise<void> {
    Promise.all(this.engines.map((engine) => engine.setContext(context)));
  }

  public async clearContext(): Promise<void> {
    Promise.all(this.engines.map((engine) => engine.clearContext()));
  }

  public async logFatal(error: Error): Promise<void> {
    Promise.all(this.engines.map((engine) => engine.logFatal(error)));
  }

  public async logError(error: Error): Promise<void> {
    Promise.all(this.engines.map((engine) => engine.logError(error)));
  }

  public async logWarning(data: CommonTypes.SerializableValue, message?: string): Promise<void> {
    Promise.all(this.engines.map((engine) => engine.logWarning(data, message)));
  }

  public async logInfo(data: CommonTypes.SerializableValue, message?: string): Promise<void> {
    Promise.all(this.engines.map((engine) => engine.logInfo(data, message)));
  }

  public async logDebug(data: CommonTypes.SerializableValue, message?: string): Promise<void> {
    Promise.all(this.engines.map((engine) => engine.logDebug(data, message)));
  }

  public async log(data: CommonTypes.SerializableValue, message?: string): Promise<void> {
    Promise.all(this.engines.map((engine) => engine.log(data, message)));
  }
}
