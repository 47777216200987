import type { FC } from 'react';
import React, { createContext, useState } from 'react';
import FingerprintFactory, { Fingerprint } from '../modules/fingerprint';
import { useLogger } from '../hooks/useLogger';

export const FingerprintContext = createContext<Fingerprint>({} as Fingerprint);

interface IProps {
  children: React.ReactNode;
}

export const FingerprintProvider: FC<IProps> = ({ children }) => {
  const logger = useLogger();
  const [fingerprint] = useState<Fingerprint>(
    FingerprintFactory({
      builders: [],
      logger,
    })
  );

  return <FingerprintContext.Provider value={fingerprint}>{children}</FingerprintContext.Provider>;
};
