import {
  FBQ_EVENT_NAMES,
  GOOGLE_EVENT_NAMES,
  PAYMENT_PROVIDERS,
  PAYMENT_SYSTEMS,
  Payments,
  PAYWALL_TYPE,
  Paywalls,
  PINTEREST_EVENT_NAMES,
} from '@organicapps/organictypes';

export const PAYMENT_COMMISSION_MAPPER: {
  [key in Payments.PaymentSystems]: { [key in Payments.PaymentProviders]?: (price: number) => number };
} = {
  [PAYMENT_SYSTEMS.STRIPE]: {
    [PAYMENT_PROVIDERS.CARD]: (price) => (price / 100) * 2.9 + 0.3,
    [PAYMENT_PROVIDERS.CASHAPP]: (price) => (price / 100) * 2.9 + 0.3,
    [PAYMENT_PROVIDERS.WALLET_PAY]: (price) => (price / 100) * 2.9 + 0.3,
  },
  [PAYMENT_SYSTEMS.AUTHORIZE]: {
    [PAYMENT_PROVIDERS.CARD]: (price) => price / 100 + 0.2,
  },
  [PAYMENT_SYSTEMS.PAYPAL]: {
    [PAYMENT_PROVIDERS.PAYPAL]: (price) => (price / 100) * 2.9 + 0.3,
  },
  [PAYMENT_SYSTEMS.BRAINTREE]: {
    [PAYMENT_PROVIDERS.CARD]: (price) => (price / 100) * 4 + (price / 100) * 0.06 + 0.06,
    [PAYMENT_PROVIDERS.PAYPAL]: (price) => (price / 100) * 2.78 + 0.29,
  },
};

export const ONE_TIME_PURCHASE_EVENT = 'WebOneTimePurchase';

export enum EVENTS {
  // Action events
  START_PURCHASE = 'Start purchase',
  START_UPSELLS_PURCHASE = 'Start upsell purchase',
  FINISH_PURCHASE = 'Finish purchase',
  FINISH_UPSELLS_PURCHASE = 'Finish upsell purchase',
  KETO_GUIDE_FINISH_PURCHASE = 'Keto Guide Finish purchase',
  MEAL_PLAN_FINISH_PURCHASE = 'Meal Plan Finish purchase',
  VEGAN_GUIDE_FINISH_PURCHASE = 'Vegan Guide Finish purchase',
  CALORIE_TRACKER_FINISH_PURCHASE = 'Calorie Tracker Finish purchase',
  GROCERY_LIST_FINISH_PURCHASE = 'Grocery List Finish purchase',
  EXPRESS_WORKOUTS_FINISH_PURCHASE = 'Express Workouts Finish purchase',
  UPPER_BODY_EXERCISES_FINISH_PURCHASE = 'Upper Body Exercises Finish purchase',
  WORKOUT_PLAN_FINISH_PURCHASE = 'Workout Plan Finish purchase',
  ABS_EXERCISES_FINISH_PURCHASE = 'Abs Exercises Finish purchase',
  LEGS_EXERCISES_FINISH_PURCHASE = 'Legs Exercises Finish purchase',
  HEALTHY_DESERTS_FINISH_PURCHASE = 'Healthy Deserts Finish purchase',
  BREAKFAST_PLAN_FINISH_PURCHASE = 'Breakfast Plan Finish purchase',
  LUNCH_PLAN_FINISH_PURCHASE = 'Lunch Plan Finish purchase',
  DINNER_PLAN_FINISH_PURCHASE = 'Dinner Plan Finish purchase',
  SNACK_LIST_FINISH_PURCHASE = 'Snack List Finish purchase',
  HEALTHY_DRINKS_GUIDE_FINISH_PURCHASE = 'Healthy Drinks Guide Finish purchase',
  LOW_CARB_GUIDE_FINISH_PURCHASE = 'Low Carb Guide Finish purchase',
  RECIPE_BOOK_FINISH_PURCHASE = 'Recipe Book Finish purchase',
  FASTING_PLAN_FINISH_PURCHASE = 'Fasting Plan Finish purchase',
  BRAZILIAN_BUTT_WORKOUTS_FINISH_PURCHASE = 'Brazilian Butt Workouts Finish purchase',
  ANTI_CELLULITE_WORKOUTS_FINISH_PURCHASE = 'Anti-Cellulite Workouts Finish purchase',
  BURN_FAT_WORKOUTS_FINISH_PURCHASE = 'Burn Fat Workouts Finish purchase',
  LOSE_WEIGHT_WORKOUTS_FINISH_PURCHASE = 'Lose Weight Workouts Finish purchase',
  FULL_BODY_WORKOUTS_FINISH_PURCHASE = 'Full Body Workouts Finish purchase',
  STRETCHING_EXERCISES_FINISH_PURCHASE = 'Stretching Exercises Finish purchase',
  PAYMENT_DRAWER_CLOSE = 'Payment drawer close',

  PAYMENT_STEP = 'Payment step',

  USER_REGISTERED = 'User registered',
  APPLE_CONNECTED = 'Apple connected',
  SELECT_SUBSCRIPTION = 'Select Subscription',
  CANCEL_SUBSCRIPTION = 'Cancel Subscription',
  CANCEL_SUBSCRIPTION_REASON_SELECTED = 'Cancel Subscription Reason Selected',
  DISCOUNT_SUBSCRIPTION = 'Discount Subscription',
  SEND_FEEDBACK = 'Send Feedback',
  SKIP_UPSELL = 'Skip upsell',

  // Action error events
  PAYMENT_TRANSACTION_ERROR = 'Payment transaction error',
  UPSELLS_PAYMENT_TRANSACTION_ERROR = 'Upsell payment transaction error',
  USER_REGISTRATION_ERROR = 'User registration error',
  APPLE_CONNECT_ERROR = 'Apple connect error',
  CANCEL_SUBSCRIPTION_ERROR = 'Cancel Subscription error',
  DISCOUNT_SUBSCRIPTION_ERROR = 'Discount Subscription error',
  SEND_FEEDBACK_ERROR = 'Send Feedback error',

  //View events
  PAGE_VIEW = 'PageView',
  PAYWALL = 'Paywall',
  MANFIT_UPSELLS = 'ManfitUpsells',
  DOWNLOAD = 'Download',
  REGISTER = 'Register',
  SUBSCRIPTION = 'Cancel Subscription',

  UTM_SOURCE_CHANGE = 'Utm Source Change',

  EMPTY_NAME = '',
}

export enum EVENT_TYPES {
  ACTION = 'Action',
  VIEW = 'View',
  SUBVIEW = 'SubView',
}

export const FBQ_PURCHASE_EVENTS = ['Purchase', 'Payment'];

export const secondaryProductsMapper: { [key in Exclude<Paywalls.PaywallType, 'offer'>]: string } = {
  [PAYWALL_TYPE.KETO]: 'Keto Guide',
  [PAYWALL_TYPE.MEALPLAN]: 'Meal Plan',
  [PAYWALL_TYPE.CALORIE]: 'Calorie Tracker',
  [PAYWALL_TYPE.VEGAN]: 'Vegan Guide',
  [PAYWALL_TYPE.GROCERY]: 'Grocery List',
  [PAYWALL_TYPE.EXPRESS_WORKOUTS]: 'Express Workouts',
  [PAYWALL_TYPE.UPPER_BODY_EXERCISES]: 'Upper Body Exercises',
  [PAYWALL_TYPE.WORKOUT_PLAN]: 'Workout Plan',
  [PAYWALL_TYPE.ABS_EXERCISES]: 'Abs Exercises',
  [PAYWALL_TYPE.LEGS_EXERCISES]: 'Legs Exercises',
  [PAYWALL_TYPE.HEALTHY_DESERTS]: 'Healthy Deserts',
  [PAYWALL_TYPE.BREAKFAST_PLAN]: 'Breakfast Plan',
  [PAYWALL_TYPE.LUNCH_PLAN]: 'Lunch Plan',
  [PAYWALL_TYPE.DINNER_PLAN]: 'Dinner Plan',
  [PAYWALL_TYPE.SNACK_LIST]: 'Snack List',
  [PAYWALL_TYPE.HEALTHY_DRINKS_GUIDE]: 'Healthy Drinks Guide',
  [PAYWALL_TYPE.LOW_CARB_GUIDE]: 'Low Carb Guide',
  [PAYWALL_TYPE.RECIPE_BOOK]: 'Recipe Book',
  [PAYWALL_TYPE.FASTING_PLAN]: 'Fasting Plan',
  [PAYWALL_TYPE.BRAZILIAN_BUTT_WORKOUTS]: 'Brazilian Butt Workouts',
  [PAYWALL_TYPE.ANTI_CELLULITE_WORKOUTS]: 'Anti-Cellulite Workouts',
  [PAYWALL_TYPE.BURN_FAT_WORKOUTS]: 'Burn Fat Workouts',
  [PAYWALL_TYPE.LOST_WEIGHT_WORKOUTS]: 'Lose Weight Workouts',
  [PAYWALL_TYPE.FULL_BODY_WORKOUTS]: 'Full Body Workouts',
  [PAYWALL_TYPE.STRETCHING_EXERCISES]: 'Stretching Exercises',
};

export const fbqMapper = {
  [EVENTS.PAGE_VIEW]: FBQ_EVENT_NAMES.PAGE_VIEW,
  [EVENTS.PAYWALL]: FBQ_EVENT_NAMES.LEAD,
  [EVENTS.FINISH_PURCHASE]: FBQ_EVENT_NAMES.PURCHASE,
};

export const googleEventsMapper = {
  [EVENTS.PAGE_VIEW]: GOOGLE_EVENT_NAMES.PAGE_VIEW,
  [EVENTS.PAYWALL]: GOOGLE_EVENT_NAMES.GENERATE_LEAD,
  [EVENTS.FINISH_PURCHASE]: GOOGLE_EVENT_NAMES.PURCHASE,
};

export const tiktokEventsMapper = {
  [EVENTS.PAYWALL]: 'InitiateCheckout',
  [EVENTS.FINISH_PURCHASE]: 'CompletePayment',
};

export const pinterestEventsMapper = {
  [EVENTS.PAGE_VIEW]: PINTEREST_EVENT_NAMES.PAGE_VISIT,
  [EVENTS.PAYWALL]: PINTEREST_EVENT_NAMES.LEAD,
  [EVENTS.FINISH_PURCHASE]: PINTEREST_EVENT_NAMES.CHECKOUT,
};

export const VWOMapper = {
  [EVENTS.FINISH_PURCHASE]: 'FinishPurchase',
};

export const upsellsMapper = {
  [EVENTS.PAYMENT_TRANSACTION_ERROR]: EVENTS.UPSELLS_PAYMENT_TRANSACTION_ERROR,
  [EVENTS.START_PURCHASE]: EVENTS.START_UPSELLS_PURCHASE,
  [EVENTS.FINISH_PURCHASE]: EVENTS.FINISH_UPSELLS_PURCHASE,
} as const;

export const upsellsPurchaseEvents = Object.values(upsellsMapper);

export const conversionMapper = {
  [EVENTS.PAYWALL]: 'WebPaywall',
  [EVENTS.FINISH_PURCHASE]: 'WebPurchase',
  [EVENTS.KETO_GUIDE_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.MEAL_PLAN_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.VEGAN_GUIDE_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.CALORIE_TRACKER_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.GROCERY_LIST_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.EXPRESS_WORKOUTS_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.ABS_EXERCISES_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.FULL_BODY_WORKOUTS_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.BRAZILIAN_BUTT_WORKOUTS_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.ANTI_CELLULITE_WORKOUTS_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.BREAKFAST_PLAN_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.UPPER_BODY_EXERCISES_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.WORKOUT_PLAN_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.LEGS_EXERCISES_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.HEALTHY_DESERTS_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.HEALTHY_DRINKS_GUIDE_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.LUNCH_PLAN_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.DINNER_PLAN_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.SNACK_LIST_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.LOW_CARB_GUIDE_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.RECIPE_BOOK_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.FASTING_PLAN_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.BURN_FAT_WORKOUTS_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.LOSE_WEIGHT_WORKOUTS_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
  [EVENTS.STRETCHING_EXERCISES_FINISH_PURCHASE]: ONE_TIME_PURCHASE_EVENT,
};
