export enum SEARCH_PARAMS {
  // To get user details on cancel page
  ACCESS_TOKEN = 'access_token',
  // To handle theme change (light / dark)
  THEME = 'theme',
  // To force (disable cache) update i18n missed keys from backend
  FORCE_MISSED_KEYS_UPDATE = 'force_missedKeys_update',
  // To toggle email specific changes on paywalls, currently enable_email_specific=@{EMAIL_SPECIFIC_VALUES}
  ENABLE_EMAIL_SPECIFIC = 'enable_email_specific',

  UTM_SOURCE = 'utm_source',
  UTM_CHANNEL = 'utm_channel',
  UTM_AD_ID = 'utm_ad_id',

  UTM_EMAIL_MESSAGE_ID = 'utm_email_message_id',
  UTM_EMAIL_WORKFLOW_ID = 'utm_email_workflow_id',

  // To Handle Redirect Payments
  SELECTED_PRICE_ID = 'selected_price_id',
  PAYMENT_METHOD = 'payment_method',
  STRIPE_PAYER_ID = 'stripe_payer_id',

  // ToDo: TEMP ALTERNATIVE API COZ OF TWO BACKENDS
  ENABLE_GATEWAY_API_URL = 'enable_gateway_api_url',

  // To change popupVariant in onboarding
  POPUP_VARIANT = 'popupVariant',

  // To process VWO offline events
  VWO_UUID = 'vwo_uuid',

  // ToDo: ManFit Upsells Test Temporary
  MANFIT_UPSELLS = 'manfit_upsells',

  // To hide Cardholder name on the drawer
  CARDHOLDER_HIDE = 'cardholder_hide',

  // Divkit migrations
  LANG = 'lang',

  TIKTOK_CLICK_ID = 'ttclid',

  CHECKOUT_VERSION = 'checkout',

  INSTANT_APP_ENABLED = 'instant_app_enabled',
}

export enum EMAIL_SPECIFIC_VALUES {
  EMAIL_CONFIRM = 'main_image_email_confirm',
  PLAN_UPDATED = 'main_image_plan_updated',
  HEALTHY_HABITS = 'main_image_healthy_habits',
}
