export const camelCaseSearchParams = (searchPartOfUrl: string): Record<string, string> => {
  const searchParams = new URLSearchParams(searchPartOfUrl);
  const result: Record<string, string> = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of searchParams) {
    const isCamelCase = !key.match(/[\s_-]/g);
    if (isCamelCase) {
      result[key] = value;
    } else {
      const camelCasedKey = key
        .replace(/[^a-zA-Z0-9]/g, ' ')
        .split(' ')
        .map((word, index) =>
          index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join('');
      result[camelCasedKey] = value;
    }
  }
  return result;
};
