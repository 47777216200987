import React, { FC } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';
import { CircularProgress } from '@mui/material';

const MyLoadingButton: FC<LoadingButtonProps> = ({ children, ...rest }) => {
  return (
    <LoadingButton {...rest} loadingIndicator={<CircularProgress color='inherit' size={22} />}>
      {children}
    </LoadingButton>
  );
};

export default MyLoadingButton;
