export const getCurrencySymbol = (locale: string, currency: string) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0,
  })
    .format(0)
    .replaceAll('0', '')
    .trim();
};

export const currencyFormat = (locale: string, currency: string, currencyDisplay = 'symbol') => {
  const closureLocale = locale;
  const closureCurrency = currency;
  return (amount: number) => {
    return new Intl.NumberFormat(closureLocale, {
      style: 'currency',
      currency: closureCurrency,
      currencyDisplay,
    }).format(amount);
  };
};

export const exchange = (exchangeRate: number) => {
  const rate = exchangeRate;
  return (price?: number) => {
    if (price) {
      return price * rate;
    }
    return 0;
  };
};

export const i18nPriceFormat = (locale: string, currency: string, exchangeRate: number, currencyDisplay: string) => {
  const getExchangedValue = exchange(exchangeRate);
  const format = currencyFormat(locale, currency, currencyDisplay);

  return (amount: number) => {
    if (amount) {
      return format(getExchangedValue(amount));
    }
    return '';
  };
};
