import React, { ComponentType, FC } from 'react';
import { useInitState } from '../hooks/useInitState';
import { IProductPageParams } from './withProductGuard';

export interface IWithPaywallStateProps extends Object {
  isLoading: boolean;
}

const withPaywallState = <P extends IWithPaywallStateProps>(
  Component: ComponentType<P>
): FC<Omit<P, keyof IWithPaywallStateProps> & IProductPageParams> => {
  return (props: Omit<P, keyof IWithPaywallStateProps> & IProductPageParams) => {
    const { productName } = props;
    const [isLoading] = useInitState(productName);
    const newProps = { ...props, isLoading };

    return <Component {...(newProps as P & IWithPaywallStateProps & IProductPageParams)} />;
  };
};

export default withPaywallState;
