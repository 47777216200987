import React, { FC, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { PAYWALL_TYPE, Paywalls, UPSELL_TYPES } from '@organicapps/organictypes';
import { useAnalytics } from '../../hooks/useAnalytics';
import { PAGES } from '../../constants/pages';
import { usePaywallsStore, useUserStore } from '../../store';
import withPaywallTypeGuard from '../../HOCs/withPaywallTypeGuard';
import withUpsellsPaidGuard from '../../HOCs/withUpsellsPaidGuard';
import { EVENT_TYPES, EVENTS } from '../../constants/analytics';
import { IRouteParamsToChange, useRouterWrapper } from '../../hooks/useRouterWrapper';
import ApiClients from '../../api';
import { useLogger } from '../../hooks/useLogger';
import { SEARCH_PARAMS } from '../../constants/searchParams';
import { useFingerprint } from '../../hooks/useFingerprint';
import { useInstantApp } from '../../hooks/useInstantApp';

const FastingDancePrograms = React.lazy(() => import('./fastingDancePrograms/index'));
const FastingDanceProgramsSimple = React.lazy(() => import('./fastingDanceProgramsSimple/index'));

const routes: { [key in Paywalls.UpsellType]: FC<any> } = {
  [UPSELL_TYPES.FASTING_DANCE_PROGRAMS]: FastingDancePrograms,
  [UPSELL_TYPES.FASTING_DANCE_PROGRAMS_SIMPLE]: FastingDanceProgramsSimple,
};

const UpsellsRouter = () => {
  const go = useRouterWrapper();
  const { logEvent } = useAnalytics();
  const [searchParams] = useSearchParams();
  const fingerprint = useFingerprint();

  const { upsellType, paywallVersion } = usePaywallsStore((state) => ({
    upsellType: state.settings?.upsellType,
    paywallVersion: state.settings.version,
  }));

  const { appUserToken, setUpsellsPaid } = useUserStore((state) => ({
    appUserToken: state.appUserToken,
    setUpsellsPaid: state.setUpsellsPaid,
  }));

  const userClient = ApiClients.getUserClient();
  const logger = useLogger();

  const { invoke } = useInstantApp();

  const handleGoRoute = (page: IRouteParamsToChange['page']) => {
    fingerprint
      .createFingerprint()
      .then((fingerprint) => {
        userClient.sendUserFingerprint(appUserToken, fingerprint).catch((e) => logger.logError(e as Error));
      })
      .catch((e) => logger.logError(e as Error));
    // ToDo: ManFit Upsells Test Temporary
    go({ routeChangedPart: { page: searchParams.has(SEARCH_PARAMS.MANFIT_UPSELLS) ? PAGES.MANFIT_UPSELLS : page } });
  };

  const nextPage = () => {
    invoke();
    setUpsellsPaid(true);
  };

  const onSkipUpsell = () => {
    invoke();
    logEvent({
      type: EVENT_TYPES.ACTION,
      name: EVENTS.SKIP_UPSELL,
      properties: {
        message: EVENTS.SKIP_UPSELL,
      },
    });

    handleGoRoute(PAGES.REGISTER);
  };

  useEffect(() => {
    logEvent({
      type: EVENT_TYPES.VIEW,
      name: EVENTS.PAYWALL,
      properties: {
        version: paywallVersion,
        upsellType,
      },
    });
  }, []);

  const Page = routes[upsellType as Paywalls.UpsellType];

  return <>{Page ? <Page onSkip={onSkipUpsell} onPaid={nextPage} /> : <Navigate to={PAGES.NOT_FOUND} replace />}</>;
};

export default withPaywallTypeGuard(withUpsellsPaidGuard(UpsellsRouter), PAYWALL_TYPE.OFFER);
