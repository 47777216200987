import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { ANALYTICS_PROVIDER, CURRENCIES } from '@organicapps/organictypes';
import logger from './middlewares/loggerMiddleware';
import { ENV, NODE_ENV } from '../constants/envKeysMapper';
import { IUserData } from '../api/userClient';

interface IUserState extends IUserData {
  setIsRegistered: (status: boolean) => void;
  setIsPayed: (status: boolean) => void;
  setUpsellsPaid: (status: boolean) => void;
  setUserData: (data: IUserData) => void;
  setEmail: (email: string) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (firstName: string) => void;
}

export const useUserStore = create<IUserState>()(
  logger(
    devtools(
      persist(
        (set, get) => ({
          _id: '',
          email: '',
          appUserId: '',
          appUserToken: '',
          popupVariant: '',
          onboardingVariant: '',
          city: '',
          country: '',
          currency: '',
          firstName: '',
          lastName: '',
          ip: '',
          lang: '',
          complete: false,
          paid: false,
          upsellsPaid: false,
          localCurrency: CURRENCIES.USD,
          exchangeRate: 1,
          weight: null,
          desiredWeight: null,
          pilatesWallWorkouts: '',
          bodyType: '',
          analyticsSettings: {
            [ANALYTICS_PROVIDER.AMPLITUDE]: { pixelId: '' },
            [ANALYTICS_PROVIDER.FACEBOOK]: { pixelId: '' },
            [ANALYTICS_PROVIDER.GOOGLE]: { pixelId: '' },
            [ANALYTICS_PROVIDER.PINTEREST]: { pixelId: '' },
            [ANALYTICS_PROVIDER.TIKTOK]: { pixelId: '' },
          },
          setUserData: (data: IUserData) => {
            const store = get();
            set(
              {
                setUserData: store.setUserData,
                setEmail: store.setEmail,
                setIsRegistered: store.setIsRegistered,
                setFirstName: store.setFirstName,
                setLastName: store.setLastName,
                setIsPayed: store.setIsPayed,
                setUpsellsPaid: store.setUpsellsPaid,
                ...data,
              },
              true,
              'user/setData'
            );
          },
          setEmail: (email: string) => set({ email }, false, 'user/setEmail'),
          setFirstName: (firstName: string) => set({ firstName }, false, 'user/setFirstName'),
          setLastName: (lastName: string) => set({ lastName }, false, 'user/setLastName'),
          setIsRegistered: (status: boolean) => set({ complete: status }, false, 'user/setIsRegistered'),
          setIsPayed: (status: boolean) => set({ paid: status }, false, 'user/setIsPayed'),
          setUpsellsPaid: (status: boolean) => set({ upsellsPaid: status }, false, 'user/setUpsellsPaid'),
        }),
        {
          name: 'userState',
          getStorage: () => sessionStorage,
        }
      ),
      { name: 'useUserStore', enabled: NODE_ENV === ENV.DEVELOPMENT }
    ),
    '[userStore]'
  )
);
