import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { BoxProps } from '@mui/material/Box/Box';

export enum PRICE_SIZE {
  SMALL = 'small',
  BIG = 'big',
}

const PriceTagBackground = styled(Box)<IInactive & { size: PRICE_SIZE }>(({ theme, inactive, size }) => ({
  position: 'relative',
  minWidth: '55px',
  width: 'max-content',
  minHeight: '44px',
  maxHeight: size === PRICE_SIZE.SMALL ? '44px' : 'none',
  backgroundColor: inactive ? theme.palette.grey[300] : theme.palette.primary.light,
  borderBottomRightRadius: '5px',
  borderTopRightRadius: '5px',
  borderTopLeftRadius: '5px',
  borderBottomLeftRadius: '5px',
  padding: theme.spacing(2, 1, 2, 0),
  display: 'flex',
  alignItems: 'center',
}));

const PriceSymbol = styled(Box)`
  position: absolute;
  top: -3px;
  right: 100%;
`;

const PriceWrapper = styled(Box)<{ size: PRICE_SIZE }>(({ size, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(1),
  justifyContent: 'flex-start',
  height: '100%',
  width: 'max-content',
  marginTop: size === PRICE_SIZE.SMALL ? '0.28em' : '1.1em',
}));

const PriceTagArrow = styled(Box)<IInactive & { size: PRICE_SIZE }>(({ theme, inactive, size }) => ({
  width: 0,
  height: 0,
  position: 'relative',
  '& .outer': {
    position: 'absolute',
    top: '1px',
    left: size === PRICE_SIZE.SMALL ? '-16px' : '-35px',
    width: '44px',
    height: size === PRICE_SIZE.SMALL ? '42px' : '68px',
    overflow: 'hidden',
  },
  '& .inner': {
    transform: 'rotate(55deg) skew(20deg, 0deg)',
    backgroundColor: inactive ? theme.palette.grey[300] : theme.palette.primary.light,
    position: 'relative',
    top: '0',
    left: size === PRICE_SIZE.SMALL ? '-4px' : '12px',
    width: size === PRICE_SIZE.SMALL ? '176px' : '145px',
    height: '100px',
    borderRadius: '5px',
  },
}));

const StrikeThrough = styled(Box)(({ theme }) => ({
  width: '120%',
  position: 'absolute',
  height: '1px',
  top: '50%',
  left: '-10%',
  backgroundColor: theme.palette.error.main,
}));

interface IInactive {
  inactive: number;
}

export interface IProps {
  wrapperStyles?: BoxProps;
  inactive: boolean;
  currencySymbol: string;
  oldPricePerAny?: string;
  intPrice: string;
  decPrice: string;
  size: PRICE_SIZE;
  priceDescription: string;
}

const PriceArrow: FC<IProps> = ({
  wrapperStyles,
  inactive,
  currencySymbol,
  oldPricePerAny,
  intPrice,
  decPrice,
  size,
  priceDescription,
}) => {
  return (
    <Box
      {...wrapperStyles}
      sx={{
        display: 'flex',
        flexDirection: size === PRICE_SIZE.SMALL ? 'row' : 'column-reverse',
        alignItems: size === PRICE_SIZE.SMALL ? 'stretch' : 'flex-start',
        flexWrap: 'wrap-reverse',
        justifyContent: 'flex-end',
      }}
    >
      {oldPricePerAny ? (
        <Box
          mt={size === PRICE_SIZE.SMALL ? 0 : 3}
          sx={{
            display: 'flex',
            alignSelf: 'baseline',
            position: 'relative',
          }}
        >
          <Typography variant={size === PRICE_SIZE.SMALL ? 'subtitle2' : 'subtitle1'} sx={{ lineHeight: 1 }}>
            {oldPricePerAny}
          </Typography>
          <StrikeThrough />
        </Box>
      ) : null}
      <Box sx={{ display: 'flex', pl: 4 }}>
        <PriceTagArrow size={size} inactive={+inactive}>
          <Box className='outer'>
            <Box className='inner' />
          </Box>
        </PriceTagArrow>
        <PriceTagBackground size={size} inactive={+inactive}>
          <PriceSymbol>
            <Typography variant={size === PRICE_SIZE.SMALL ? 'subtitle2' : 'subtitle1'}>{currencySymbol}</Typography>
          </PriceSymbol>
          <Box sx={{ display: 'flex' }}>
            <Typography variant={size === PRICE_SIZE.SMALL ? 'h2' : 'h0'}>{intPrice}</Typography>
          </Box>
          <PriceWrapper size={size}>
            <Typography variant={size === PRICE_SIZE.SMALL ? 'subtitle2' : 'subtitle1'} sx={{ lineHeight: 1 }}>
              {decPrice}
            </Typography>
            {priceDescription && (
              <Typography variant={size === PRICE_SIZE.SMALL ? 'h6' : 'subtitle2'} color='text.secondary'>
                {priceDescription}
              </Typography>
            )}
          </PriceWrapper>
        </PriceTagBackground>
      </Box>
    </Box>
  );
};

export default PriceArrow;
