import React, { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { PAYWALL_TYPE } from '@organicapps/organictypes';
import { EVENT_TYPES, EVENTS } from '../../constants/analytics';
import { useAnalytics } from '../../hooks/useAnalytics';
import { PAGES } from '../../constants/pages';
import { usePaywallsStore } from '../../store';
import withIsPayedRouteGuard from '../../HOCs/withIsPayedRouteGuard';
import withIsRegisteredRouteGuard from '../../HOCs/withIsRegisteredRouteGuard';

const DownloadOffer = React.lazy(() => import('./Offer/index'));
const DownloadCalorie = React.lazy(() => import('./Calorie/index'));
const DownloadBooks = React.lazy(() => import('./Books/index'));
const DownloadApps = React.lazy(() => import('./Apps/index'));

const routes: { [key in PAYWALL_TYPE]: FC<any> } = {
  [PAYWALL_TYPE.GROCERY]: DownloadBooks,
  [PAYWALL_TYPE.KETO]: DownloadBooks,
  [PAYWALL_TYPE.VEGAN]: DownloadBooks,
  [PAYWALL_TYPE.MEALPLAN]: DownloadBooks,
  [PAYWALL_TYPE.CALORIE]: DownloadCalorie,
  [PAYWALL_TYPE.OFFER]: withIsRegisteredRouteGuard(DownloadOffer),
  [PAYWALL_TYPE.STRETCHING_EXERCISES]: DownloadApps,
  [PAYWALL_TYPE.FULL_BODY_WORKOUTS]: DownloadApps,
  [PAYWALL_TYPE.RECIPE_BOOK]: DownloadApps,
  [PAYWALL_TYPE.FASTING_PLAN]: DownloadApps,
  [PAYWALL_TYPE.BRAZILIAN_BUTT_WORKOUTS]: DownloadApps,
  [PAYWALL_TYPE.ANTI_CELLULITE_WORKOUTS]: DownloadApps,
  [PAYWALL_TYPE.EXPRESS_WORKOUTS]: DownloadApps,
  [PAYWALL_TYPE.WORKOUT_PLAN]: DownloadApps,
  [PAYWALL_TYPE.LUNCH_PLAN]: DownloadApps,
  [PAYWALL_TYPE.SNACK_LIST]: DownloadApps,
  [PAYWALL_TYPE.DINNER_PLAN]: DownloadApps,
  [PAYWALL_TYPE.UPPER_BODY_EXERCISES]: DownloadApps,
  [PAYWALL_TYPE.LOST_WEIGHT_WORKOUTS]: DownloadApps,
  [PAYWALL_TYPE.ABS_EXERCISES]: DownloadApps,
  [PAYWALL_TYPE.LEGS_EXERCISES]: DownloadApps,
  [PAYWALL_TYPE.HEALTHY_DESERTS]: DownloadApps,
  [PAYWALL_TYPE.BREAKFAST_PLAN]: DownloadApps,
  [PAYWALL_TYPE.HEALTHY_DRINKS_GUIDE]: DownloadApps,
  [PAYWALL_TYPE.LOW_CARB_GUIDE]: DownloadApps,
  [PAYWALL_TYPE.BURN_FAT_WORKOUTS]: DownloadApps,
};

const DownloadRouter = () => {
  const { paywallType } = usePaywallsStore((state) => ({
    paywallType: state.settings.paywallType,
  }));
  const Page = routes[paywallType];

  const { logEvent } = useAnalytics();

  useEffect(() => {
    logEvent({
      type: EVENT_TYPES.VIEW,
      name: EVENTS.DOWNLOAD,
      properties: {},
    });
  }, []);

  return <>{Page ? <Page paywallType={paywallType} /> : <Navigate to={PAGES.NOT_FOUND} replace />}</>;
};

export default withIsPayedRouteGuard(DownloadRouter);
