import React from 'react';

const Dance = () => {
  return (
    <>
      <path
        d='M7.8636 16.186C6.65404 16.186 5.47987 16.4512 4.47785 17.0208C3.40047 17.6333 2.51753 18.5885 1.86726 19.6723C1.30946 20.6484 0.891113 21.9035 0.891113 23.1585C2.14616 23.1585 3.40121 22.7402 4.37736 22.1824C6.4691 20.9273 7.8636 18.8356 7.8636 16.186Z'
        fill='#9550F2'
      />
      <path
        className='title'
        d='M7.8636 16.186C6.65404 16.186 5.47987 16.4512 4.47785 17.0208C3.40047 17.6333 2.51753 18.5885 1.86726 19.6723C1.30946 20.6484 0.891113 21.9035 0.891113 23.1585C2.14616 23.1585 3.40121 22.7402 4.37736 22.1824C6.4691 20.9273 7.8636 18.8356 7.8636 16.186Z'
        fill='black'
        fillOpacity='0.25'
      />
      <path
        d='M14.8361 2.24097H7.8636C5.35351 2.24097 3.12231 3.63546 1.86726 5.72721C1.30946 6.70336 0.891113 7.95841 0.891113 9.21345V16.1859C0.891113 14.9309 1.30946 13.6758 1.86726 12.6997C2.42506 11.7235 3.40121 10.7474 4.37736 10.1896C5.35351 9.6318 6.60855 9.21345 7.8636 9.21345C10.3737 9.21345 12.6049 7.81896 13.8599 5.72721C14.4177 4.75106 14.8361 3.49601 14.8361 2.24097Z'
        fill='#F73C74'
      />
      <path
        d='M7.8636 9.2135C6.60855 9.2135 5.35351 9.63185 4.37736 10.1896C3.40121 10.7474 2.42506 11.7236 1.86726 12.6997C1.30946 13.6759 0.891113 14.9309 0.891113 16.186V23.1585C0.891113 21.9034 1.30946 20.6484 1.86726 19.6722C3.12231 17.5805 5.21406 16.186 7.8636 16.186C7.8636 16.186 8.83975 16.186 9.2581 16.0465C11.2104 15.6282 12.8838 14.3731 13.8599 12.6997C14.4177 11.7236 14.8361 10.4685 14.8361 9.2135H7.8636Z'
        fill='#9550F2'
      />
      <path
        className='title'
        d='M77.7947 14.3048C77.7947 16.3288 79.4402 17.6751 81.9922 17.6751C83.4353 17.6751 84.7729 17.2175 85.6088 16.496L86.3216 17.2351C86.5416 17.4639 86.8672 17.6047 87.1752 17.6047C87.7736 17.6047 88.2576 17.112 88.2576 16.5224C88.2576 16.2496 88.1608 16.0032 87.9672 15.8008L87.1752 14.9736C87.8088 14.1376 88.1784 12.9057 88.1784 11.5505V11.1985C88.1784 10.5561 87.712 10.0722 87.096 10.0722C86.4888 10.0722 86.0312 10.5562 86.0312 11.2161V11.4009C86.0312 12.3073 85.908 12.9673 85.6528 13.4161L83.5937 11.1809C85.2041 10.301 85.9784 9.28899 85.9784 8.06583C85.9784 6.37628 84.5617 5.22351 82.5377 5.22351C80.5226 5.22351 79.0266 6.43787 79.0266 8.11862C79.0266 8.9986 79.4226 9.73777 80.505 10.8465C80.2586 10.9785 79.9154 11.1633 79.7482 11.2777C78.4195 12.0785 77.7947 13.0729 77.7947 14.3048ZM82.3353 10.0106C81.5434 9.24499 81.2794 8.8226 81.2794 8.25942C81.2794 7.52904 81.7986 7.01866 82.5113 7.01866C83.2241 7.01866 83.7169 7.52024 83.7169 8.26822C83.7169 8.9898 83.3209 9.46498 82.3353 10.0106ZM81.2794 12.4745C81.3674 12.3953 81.605 12.2545 81.7018 12.2193L84.2449 14.9648C83.8049 15.4488 83.0657 15.748 82.3001 15.748C81.1122 15.748 80.3114 15.0792 80.3114 14.0848C80.3114 13.4513 80.6194 12.9145 81.2794 12.4745Z'
        fill='black'
      />
      <path
        className='title'
        d='M26.5116 5.0918H23.8923C23.1261 5.0918 22.5737 5.64416 22.5737 6.41035V16.246C22.5737 17.0122 23.1261 17.5646 23.8923 17.5646H26.5116C30.0396 17.5646 32.8905 15.3729 32.8905 11.2747C32.8905 7.28344 30.0396 5.0918 26.5116 5.0918ZM26.5116 15.5333H24.9792V7.12307H26.5116C29.0239 7.12307 30.485 8.40599 30.485 11.2747C30.485 14.2504 29.0239 15.5333 26.5116 15.5333Z'
        fill='black'
      />
      <path
        className='title'
        d='M42.1707 8.24562C41.5293 8.24562 40.9947 8.78017 40.9947 9.40381V9.70672C40.6383 8.86926 39.6227 8.19217 38.3576 8.19217C36.4154 8.19217 34.0456 9.76017 34.0456 12.9318C34.0456 16.1747 36.3976 17.6893 38.3576 17.6893C39.9078 17.6893 40.8522 16.7271 40.9947 16.1747V16.5133C40.9947 17.1547 41.5293 17.6715 42.1707 17.6715C42.7943 17.6715 43.3645 17.1547 43.3645 16.5133V9.40381C43.3645 8.78017 42.7943 8.24562 42.1707 8.24562ZM38.7852 15.6758C37.6092 15.6758 36.3798 14.7671 36.3798 12.9318C36.3798 11.1144 37.6092 10.1878 38.7852 10.1878C39.7831 10.1878 41.1016 10.9184 41.1016 12.9318C41.1016 14.9453 39.7831 15.6758 38.7852 15.6758Z'
        fill='black'
      />
      <path
        className='title'
        d='M50.7065 8.20999C48.7821 8.20999 47.7308 9.67108 47.6596 10.3125V9.42162C47.6596 8.78017 47.125 8.24562 46.5014 8.24562C45.8599 8.24562 45.3254 8.78017 45.3254 9.42162V16.4955C45.3254 17.1369 45.8599 17.6536 46.5014 17.6536C47.125 17.6536 47.6596 17.1369 47.6596 16.4955V12.2013C47.7308 11.7024 48.2654 10.2056 49.869 10.2056C50.9203 10.2056 51.7577 10.8649 51.7577 12.4685V16.4955C51.7577 17.1369 52.2923 17.6536 52.9159 17.6536C53.5574 17.6536 54.0919 17.1369 54.0919 16.4955V12.4685C54.0919 9.38599 52.5061 8.20999 50.7065 8.20999Z'
        fill='black'
      />
      <path
        className='title'
        d='M62.953 14.6958C62.5254 14.6958 62.1868 14.9453 62.0443 15.07C61.6345 15.462 61.0287 15.6224 60.5476 15.6224C59.1399 15.6224 58.0174 14.6424 58.0174 12.9318C58.0174 11.2034 59.1399 10.2234 60.5476 10.2234C61.0287 10.2234 61.6345 10.3838 62.0443 10.7758C62.1868 10.9005 62.5254 11.15 62.953 11.15C63.4341 11.15 63.8974 10.8114 63.8974 10.2591C63.8974 9.6889 63.4697 9.40381 63.2381 9.17217C62.4897 8.5129 61.6167 8.19217 60.4585 8.19217C58.3025 8.19217 55.701 9.74235 55.701 12.9318C55.701 16.1035 58.3025 17.6536 60.4585 17.6536C61.6167 17.6536 62.4897 17.3151 63.2381 16.6558C63.4697 16.442 63.8974 16.1569 63.8974 15.5867C63.8974 15.0344 63.4341 14.6958 62.953 14.6958Z'
        fill='black'
      />
      <path
        className='title'
        d='M69.6575 8.19217C67.6263 8.19217 64.9714 9.74235 64.9714 12.9496C64.9714 16.1925 67.4837 17.6536 69.8535 17.6536C70.9761 17.6536 71.867 17.3507 72.5263 16.8875C72.9717 16.5133 73.0965 16.1925 73.0965 15.9075C73.0965 15.462 72.7935 15.1056 72.2946 15.1056C71.9917 15.1056 71.7423 15.2482 71.4215 15.3907C70.887 15.6224 70.5841 15.7649 69.9426 15.7649C68.6419 15.7649 67.6084 15.07 67.3946 13.7693L72.6866 13.8049C73.631 13.8049 74.1477 13.4842 74.1477 12.5576C74.1477 9.79581 71.6888 8.19217 69.6575 8.19217ZM69.6575 10.2234C70.6732 10.2234 71.671 10.7936 71.8492 12.1656H67.3946C67.555 10.7936 68.6419 10.2234 69.6575 10.2234Z'
        fill='black'
      />
      <path
        className='title'
        d='M100.752 6.12503C100.752 5.55485 100.253 5.09158 99.6832 5.09158H93.7675C93.0013 5.09158 92.449 5.64394 92.449 6.41013V16.4596C92.449 17.1189 93.0013 17.6891 93.6606 17.6891C94.3199 17.6891 94.8544 17.1189 94.8544 16.4596V12.6287H98.8101C99.3803 12.6287 99.8614 12.1832 99.8614 11.613C99.8614 11.025 99.3803 10.5796 98.8101 10.5796H94.8544V7.19413H99.6832C100.253 7.19413 100.752 6.71304 100.752 6.12503Z'
        fill='black'
      />
      <path
        className='title'
        d='M103.453 6.98031C104.076 6.98031 104.629 6.44576 104.629 5.82212C104.629 5.19849 104.076 4.66394 103.453 4.66394C102.811 4.66394 102.259 5.19849 102.259 5.82212C102.259 6.44576 102.811 6.98031 103.453 6.98031ZM103.453 8.2454C102.811 8.2454 102.277 8.77995 102.277 9.42141V16.4774C102.277 17.1367 102.811 17.6712 103.453 17.6712C104.076 17.6712 104.611 17.1367 104.611 16.4774V9.42141C104.611 8.77995 104.076 8.2454 103.453 8.2454Z'
        fill='black'
      />
      <path
        className='title'
        d='M110.917 15.5687C110.721 15.5687 110.436 15.64 110.24 15.64C109.848 15.64 109.67 15.3727 109.67 14.856V10.1341H110.846C111.434 10.1341 111.79 9.75995 111.79 9.2254C111.79 8.67304 111.434 8.29886 110.846 8.29886H109.67V7.08722C109.67 6.44576 109.153 5.91122 108.512 5.91122C107.87 5.91122 107.336 6.44576 107.336 7.08722V8.29886H106.819C106.249 8.29886 105.875 8.67304 105.875 9.2254C105.875 9.75995 106.249 10.1341 106.819 10.1341H107.336V14.7847C107.336 17.2614 108.565 17.6534 109.777 17.6534C110.988 17.6534 111.79 17.2258 111.79 16.4061C111.79 15.8538 111.452 15.5331 110.917 15.5687Z'
        fill='black'
      />
    </>
  );
};

export default Dance;
