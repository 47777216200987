import React, { ComponentType, FC, useEffect, useState } from 'react';
import { useUserStore } from '../store';
import { PAGES } from '../constants/pages';
import { useRouterWrapper } from '../hooks/useRouterWrapper';

const withIsRegisteredRouteGuard = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return (props: P) => {
    const go = useRouterWrapper();

    const [isRouteValid, setIsRouteValid] = useState<boolean>(false);

    const { isRegistered } = useUserStore((state) => ({
      isRegistered: state.complete,
    }));

    useEffect(() => {
      if (!isRegistered) {
        go({ routeChangedPart: { page: PAGES.REGISTER } });
      } else {
        setIsRouteValid(true);
      }
    }, [isRegistered]);

    return <>{isRouteValid && <Component {...props} />}</>;
  };
};

export default withIsRegisteredRouteGuard;
