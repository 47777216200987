import React, { FC } from 'react';
import styled from '@emotion/styled';

const StyledSvg = styled.svg(({ theme }) => ({
  circle: {
    fill: theme.palette.secondary.dark,
    stroke: theme.palette.secondary.dark,
  },
}));

const CheckIconV2Icon: FC = () => {
  return (
    <StyledSvg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16.5' cy='16' r='15' strokeWidth='2' />
      <path
        d='M24.2028 10.802C23.8071 10.3999 23.1659 10.3993 22.7694 10.8002L14.286 19.0149L10.2319 14.9598C9.83548 14.5577 9.19336 14.5579 8.79724 14.9604C8.40092 15.3631 8.40092 16.0161 8.79724 16.4188L8.79824 16.4198L13.5666 21.1985C13.9624 21.6001 14.6033 21.6005 14.9996 21.1997L24.1995 12.2637L24.2028 12.2605C24.5991 11.8577 24.5991 11.2048 24.2028 10.802Z'
        fill='white'
      />
    </StyledSvg>
  );
};

export default CheckIconV2Icon;
