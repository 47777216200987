import type { StatCallbackActionType } from 'types';
import { ACTION_LOG_ID_TYPES } from 'types';
import type { IActionProcessor } from './types';

export default class ActiveStateToggleActionProcessor implements IActionProcessor {
  readonly binding = ACTION_LOG_ID_TYPES.ACTIVE_STATE_TOGGLE;

  private readonly callback: (...args: any[]) => void;

  constructor(callback: (...args: any[]) => void) {
    this.callback = callback;
  }

  process(action: StatCallbackActionType): ReturnType<IActionProcessor['process']> {
    const priceId = action?.payload?.priceId;
    if (this.callback && priceId) {
      this.callback(priceId);
    }
  }
}
