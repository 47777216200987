import type { CommonTypes } from '@organicapps/organictypes';
import type { ILoggerContext, ILoggerEngine, ILoggerUser } from './types';

export default class ConsoleLogger implements ILoggerEngine {
  private user: ILoggerUser = {};

  private context: ILoggerContext = {};

  setUser(user: ILoggerUser): CommonTypes.CanPromise<void> {
    this.user = { ...this.user, ...user };
  }

  setContext(context: ILoggerContext): CommonTypes.CanPromise<void> {
    this.context = { ...this.context, ...context };
  }

  clearContext(): CommonTypes.CanPromise<void> {
    this.context = {};
  }

  logError(error: Error): ReturnType<ILoggerEngine['logError']> {
    console.groupCollapsed(`%c[${this.createTimeStamp()}] - %c[Error]`, 'color: orange', 'color: red');
    console.groupCollapsed('%cUser', 'color: green');
    console.error(JSON.stringify(this.user, null, 2));
    console.groupEnd();
    console.groupCollapsed('%cContext', 'color: orange');
    console.error(JSON.stringify(this.context, null, 2));
    console.groupEnd();
    console.group('%cData', 'color: yellow');
    console.error(error);
    console.groupEnd();
    console.groupEnd();
    console.timeStamp();
  }

  logFatal(error: Error): ReturnType<ILoggerEngine['logFatal']> {
    console.groupCollapsed(`%c[${this.createTimeStamp()}] - %c[Fatal Error]`, 'color: orange', 'color: red');
    console.groupCollapsed('%cUser', 'color: green');
    console.error(JSON.stringify(this.user, null, 2));
    console.groupEnd();
    console.groupCollapsed('%cContext', 'color: orange');
    console.error(JSON.stringify(this.context, null, 2));
    console.groupEnd();
    console.group('%cData', 'color: yellow');
    console.error(error);
    console.groupEnd();
    console.groupEnd();
  }

  logDebug(data: CommonTypes.SerializableValue, message = ''): ReturnType<ILoggerEngine['logDebug']> {
    console.groupCollapsed(`%c[${this.createTimeStamp()}] - %c[${message}]`, 'color: orange', 'color: red');
    console.groupCollapsed('%cUser', 'color: green');
    console.info(JSON.stringify(this.user, null, 2));
    console.groupEnd();
    console.groupCollapsed('%cContext', 'color: orange');
    console.info(JSON.stringify(this.context, null, 2));
    console.groupEnd();
    console.group('%cData', 'color: yellow');
    console.info(data instanceof Error ? data : JSON.stringify(data, null, 2));
    console.groupEnd();
    console.groupEnd();
  }

  logInfo(data: CommonTypes.SerializableValue, message = ''): ReturnType<ILoggerEngine['logInfo']> {
    console.groupCollapsed(`%c[${this.createTimeStamp()}] - %c[${message}]`, 'color: orange', 'color: DodgerBlue');
    console.groupCollapsed('%cUser', 'color: green');
    console.info(JSON.stringify(this.user, null, 2));
    console.groupEnd();
    console.groupCollapsed('%cContext', 'color: orange');
    console.info(JSON.stringify(this.context, null, 2));
    console.groupEnd();
    console.group('%cData', 'color: yellow');
    console.info(JSON.stringify(data, null, 2));
    console.groupEnd();
    console.groupEnd();
  }

  logWarning(data: CommonTypes.SerializableValue, message = ''): ReturnType<ILoggerEngine['logWarning']> {
    console.groupCollapsed(`%c[${this.createTimeStamp()}] - %c[${message}]`, 'color: orange', 'color: yellow');
    console.groupCollapsed('%cUser', 'color: green');
    console.info(JSON.stringify(this.user, null, 2));
    console.groupEnd();
    console.groupCollapsed('%cContext', 'color: orange');
    console.info(JSON.stringify(this.context, null, 2));
    console.groupEnd();
    console.group('%cData', 'color: yellow');
    console.warn(JSON.stringify(data, null, 2));
    console.groupEnd();
    console.groupEnd();
  }

  log(data: CommonTypes.SerializableValue, message?: string): ReturnType<ILoggerEngine['log']> {
    console.log(message, data);
  }

  private createTimeStamp() {
    return new Intl.DateTimeFormat('en', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      fractionalSecondDigits: 3,
      hour12: false,
      hour: '2-digit',
    }).format(Date.now());
  }
}
