import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { APP_CLIP_KEYS } from '../constants/keys';
import { IProductPageParams } from '../HOCs/withProductGuard';

// TODO: if ok, move to useInstantApp hook
export const useAppClipMeta = () => {
  const { productName } = useParams<keyof IProductPageParams>() as IProductPageParams;

  useEffect(() => {
    const metaName = 'apple-itunes-app';
    const element = document.head.querySelector(`meta[name="${metaName}"]`);

    if (productName) {
      const keys = APP_CLIP_KEYS[productName];
      if (!element) {
        const meta = document.createElement('meta');
        meta.setAttribute('name', metaName);
        meta.setAttribute(
          'content',
          `app-id=${keys.appId}, app-clip-bundle-id=${keys.bundleId}, app-clip-display=card`
        );
        document.head.append(meta);
      }
    }
  }, [productName]);
};
