import React from 'react';

const Fit = () => {
  return (
    <>
      <path
        d='M8.0441 16.4648C6.81602 16.4648 5.62387 16.7341 4.60651 17.3124C3.51263 17.9343 2.61616 18.9041 1.95594 20.0045C1.3896 20.9956 0.964844 22.2698 0.964844 23.5441C2.23911 23.5441 3.51338 23.1193 4.50447 22.553C6.62825 21.2787 8.0441 19.155 8.0441 16.4648Z'
        fill='#01C1AC'
      />
      <path
        d='M8.0441 16.4648C6.81602 16.4648 5.62387 16.7341 4.60651 17.3124C3.51263 17.9343 2.61616 18.9041 1.95594 20.0045C1.3896 20.9956 0.964844 22.2698 0.964844 23.5441C2.23911 23.5441 3.51338 23.1193 4.50447 22.553C6.62825 21.2787 8.0441 19.155 8.0441 16.4648Z'
        fill='#019181'
      />
      <path
        d='M15.1234 2.30664H8.0441C5.49557 2.30664 3.23021 3.72249 1.95594 5.84627C1.3896 6.83737 0.964844 8.11163 0.964844 9.3859V16.4652C0.964844 15.1909 1.3896 13.9166 1.95594 12.9255C2.52228 11.9344 3.51338 10.9433 4.50447 10.377C5.49557 9.81066 6.76984 9.3859 8.0441 9.3859C10.5926 9.3859 12.858 7.97005 14.1323 5.84627C14.6986 4.85517 15.1234 3.58091 15.1234 2.30664Z'
        fill='#FF7F6B'
      />
      <path
        d='M8.0441 9.38672C6.76984 9.38672 5.49557 9.81147 4.50447 10.3778C3.51338 10.9442 2.52228 11.9353 1.95594 12.9263C1.3896 13.9174 0.964844 15.1917 0.964844 16.466V23.5452C0.964844 22.271 1.3896 20.9967 1.95594 20.0056C3.23021 17.8818 5.35399 16.466 8.0441 16.466C8.0441 16.466 9.0352 16.466 9.45996 16.3244C11.4422 15.8996 13.1412 14.6254 14.1323 12.9263C14.6986 11.9353 15.1234 10.661 15.1234 9.38672H8.0441Z'
        fill='#01C1AC'
      />
      <path
        className='title'
        d='M60.6016 14.5544C60.6016 16.6093 62.2723 17.9763 64.8633 17.9763C66.3286 17.9763 67.6866 17.5117 68.5354 16.7791L69.2591 17.5296C69.4824 17.7619 69.813 17.9048 70.1257 17.9048C70.7333 17.9048 71.2247 17.4045 71.2247 16.8059C71.2247 16.5289 71.1264 16.2787 70.9298 16.0732L70.1257 15.2334C70.769 14.3846 71.1443 13.1338 71.1443 11.7579V11.4005C71.1443 10.7483 70.6707 10.2569 70.0453 10.2569C69.4288 10.2569 68.9642 10.7483 68.9642 11.4184V11.606C68.9642 12.5262 68.8392 13.1963 68.5801 13.652L66.4894 11.3826C68.1244 10.4892 68.9106 9.46172 68.9106 8.21983C68.9106 6.5044 67.4722 5.33398 65.4173 5.33398C63.3713 5.33398 61.8524 6.56694 61.8524 8.27343C61.8524 9.16688 62.2544 9.91738 63.3534 11.0431C63.1032 11.1771 62.7548 11.3648 62.585 11.4809C61.2359 12.294 60.6016 13.3035 60.6016 14.5544ZM65.2118 10.1943C64.4077 9.41705 64.1396 8.98819 64.1396 8.41638C64.1396 7.67482 64.6668 7.15662 65.3905 7.15662C66.1141 7.15662 66.6145 7.66589 66.6145 8.42532C66.6145 9.15795 66.2124 9.64041 65.2118 10.1943ZM64.1396 12.696C64.229 12.6156 64.4702 12.4726 64.5685 12.4369L67.1505 15.2245C66.7038 15.7159 65.9533 16.0196 65.176 16.0196C63.9699 16.0196 63.1568 15.3406 63.1568 14.331C63.1568 13.6877 63.4695 13.1427 64.1396 12.696Z'
        fill='black'
      />
      <path
        className='title'
        d='M31.4109 6.2485C31.4109 5.66959 30.9044 5.19922 30.3254 5.19922H24.3192C23.5413 5.19922 22.9805 5.76004 22.9805 6.53796V16.7413C22.9805 17.4107 23.5413 17.9896 24.2107 17.9896C24.88 17.9896 25.4228 17.4107 25.4228 16.7413V12.8517H29.439C30.0179 12.8517 30.5064 12.3995 30.5064 11.8206C30.5064 11.2235 30.0179 10.7713 29.439 10.7713H25.4228V7.33397H30.3254C30.9044 7.33397 31.4109 6.84551 31.4109 6.2485Z'
        fill='black'
      />
      <path
        className='title'
        d='M40.1038 8.40134C39.4525 8.40134 38.9098 8.94407 38.9098 9.57726V9.88481C38.548 9.03453 37.5168 8.34707 36.2323 8.34707C34.2604 8.34707 31.8543 9.93908 31.8543 13.1593C31.8543 16.4519 34.2423 17.9896 36.2323 17.9896C37.8063 17.9896 38.7651 17.0127 38.9098 16.4519V16.7956C38.9098 17.4469 39.4525 17.9715 40.1038 17.9715C40.737 17.9715 41.3159 17.4469 41.3159 16.7956V9.57726C41.3159 8.94407 40.737 8.40134 40.1038 8.40134ZM36.6665 15.9453C35.4725 15.9453 34.2242 15.0227 34.2242 13.1593C34.2242 11.314 35.4725 10.3733 36.6665 10.3733C37.6796 10.3733 39.0184 11.115 39.0184 13.1593C39.0184 15.2036 37.6796 15.9453 36.6665 15.9453Z'
        fill='black'
      />
      <path
        className='title'
        d='M46.527 8.34707C44.8083 8.34707 43.144 9.2878 43.144 11.0969C43.144 12.3814 43.9038 13.3583 45.731 13.8648L46.7622 14.1724C47.6306 14.4257 47.8296 14.8237 47.8296 15.1312C47.8296 15.6378 47.3411 16.0177 46.4546 16.0177C45.8395 16.0177 45.2606 15.8368 44.736 15.1855C44.5189 14.9141 44.2475 14.6066 43.7591 14.6066C43.1078 14.6066 42.8545 15.1131 42.8545 15.5111C42.8545 16.072 43.2525 16.5242 43.343 16.6509C43.8495 17.266 44.8264 17.9534 46.4546 17.9534C48.3361 17.9534 50.1814 16.9946 50.1814 15.0046C50.1814 13.3945 48.9693 12.707 47.522 12.309L46.4185 11.9834C45.5863 11.7482 45.4777 11.3683 45.4777 11.0788C45.4777 10.5723 45.8938 10.2285 46.5994 10.2285C47.124 10.2285 47.5401 10.4275 47.9019 10.8436C48.2095 11.2235 48.5532 11.3502 48.8969 11.3502C49.4216 11.3502 49.8196 11.0426 49.8196 10.4637C49.8196 10.0838 49.6387 9.83054 49.5301 9.66772C48.9331 8.83553 47.7572 8.34707 46.527 8.34707Z'
        fill='black'
      />
      <path
        className='title'
        d='M55.9315 15.8368C55.7325 15.8368 55.4431 15.9091 55.2441 15.9091C54.8461 15.9091 54.6652 15.6378 54.6652 15.1131V10.319H55.8592C56.4562 10.319 56.818 9.93908 56.818 9.39635C56.818 8.83553 56.4562 8.45561 55.8592 8.45561H54.6652V7.22542C54.6652 6.57414 54.1405 6.03141 53.4892 6.03141C52.838 6.03141 52.2952 6.57414 52.2952 7.22542V8.45561H51.7706C51.1917 8.45561 50.8118 8.83553 50.8118 9.39635C50.8118 9.93908 51.1917 10.319 51.7706 10.319H52.2952V15.0408C52.2952 17.5554 53.5435 17.9534 54.7737 17.9534C56.0039 17.9534 56.818 17.5192 56.818 16.6871C56.818 16.1262 56.4743 15.8006 55.9315 15.8368Z'
        fill='black'
      />
      <path
        className='title'
        d='M83.9109 6.24909C83.9109 5.67018 83.4044 5.19981 82.8254 5.19981H76.8192C76.0413 5.19981 75.4805 5.76063 75.4805 6.53855V16.7419C75.4805 17.4113 76.0413 17.9902 76.7107 17.9902C77.38 17.9902 77.9228 17.4113 77.9228 16.7419V12.8523H81.939C82.5179 12.8523 83.0064 12.4001 83.0064 11.8211C83.0064 11.2241 82.5179 10.7719 81.939 10.7719H77.9228V7.33456H82.8254C83.4044 7.33456 83.9109 6.8461 83.9109 6.24909Z'
        fill='black'
      />
      <path
        className='title'
        d='M86.6526 7.11747C87.2857 7.11747 87.8466 6.57473 87.8466 5.94155C87.8466 5.30836 87.2857 4.76562 86.6526 4.76562C86.0013 4.76562 85.4405 5.30836 85.4405 5.94155C85.4405 6.57473 86.0013 7.11747 86.6526 7.11747ZM86.6526 8.40193C86.0013 8.40193 85.4585 8.94466 85.4585 9.59594V16.76C85.4585 17.4294 86.0013 17.9721 86.6526 17.9721C87.2857 17.9721 87.8285 17.4294 87.8285 16.76V9.59594C87.8285 8.94466 87.2857 8.40193 86.6526 8.40193Z'
        fill='black'
      />
      <path
        className='title'
        d='M94.2314 15.8374C94.0324 15.8374 93.743 15.9097 93.544 15.9097C93.146 15.9097 92.9651 15.6384 92.9651 15.1137V10.3196H94.1591C94.7561 10.3196 95.1179 9.93967 95.1179 9.39694C95.1179 8.83612 94.7561 8.45621 94.1591 8.45621H92.9651V7.22601C92.9651 6.57473 92.4404 6.032 91.7892 6.032C91.1379 6.032 90.5951 6.57473 90.5951 7.22601V8.45621H90.0705C89.4916 8.45621 89.1117 8.83612 89.1117 9.39694C89.1117 9.93967 89.4916 10.3196 90.0705 10.3196H90.5951V15.0414C90.5951 17.556 91.8434 17.954 93.0736 17.954C94.3038 17.954 95.1179 17.5198 95.1179 16.6876C95.1179 16.1268 94.7742 15.8012 94.2314 15.8374Z'
        fill='black'
      />
    </>
  );
};

export default Fit;
