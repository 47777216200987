import type { StatCallbackActionType } from 'types';
import { ACTION_LOG_ID_TYPES } from 'types';
import type { IActionProcessor } from './types';

export default class PaywallCtaClickActionProcessor implements IActionProcessor {
  readonly binding = ACTION_LOG_ID_TYPES.PAYWALL_CTA_CLICK;

  private readonly callback: (...args: any[]) => void;

  constructor(callback: (...args: any[]) => void) {
    this.callback = callback;
  }

  process(action: StatCallbackActionType): ReturnType<IActionProcessor['process']> {
    if (this.callback) {
      this.callback();
    }
  }
}
