import React from 'react';

const Vegan = () => {
  return (
    <>
      <path
        className='title'
        d='M7.89351 18.2533C9.43208 18.2533 9.70824 17.6419 10.3197 16.0638L13.5744 6.6943C13.7717 6.04336 14.7185 6.00391 14.7185 5.27408C14.7185 4.80067 14.4029 4.52451 13.8506 4.52451H11.9175C11.2271 4.52451 10.9509 4.72177 10.9509 5.2149C10.9509 6.00391 12.0161 5.92501 11.7597 6.71403L9.11648 14.5055C8.99813 14.8803 8.68252 14.9198 8.54444 14.5253L5.7829 6.931C5.58565 6.04336 6.72971 6.26034 6.72971 5.33325C6.72971 4.82039 6.41411 4.58369 5.704 4.58369H1.97591C1.24608 4.58369 0.950195 4.93875 0.950195 5.31353C0.950195 6.20117 1.58141 5.78694 2.05481 7.20716L5.76317 16.4978C6.25631 17.7405 6.82834 18.2533 7.89351 18.2533Z'
        fill='#00715D'
      />
      <path
        className='title'
        d='M22.3798 14.3675C22.1825 14.3675 21.9852 14.4661 21.788 14.6633C21.0779 15.4129 20.5058 15.6496 19.6379 15.6496C18.0599 15.6496 17.0342 14.7028 16.7186 13.0261H21.4329C22.5178 13.0261 23.011 12.533 23.011 11.4284C23.011 9.61365 21.4329 7.8581 18.6911 7.8581C15.5153 7.8581 13.4639 10.1462 13.4639 13.2628C13.4639 16.2808 15.5548 18.2533 18.8489 18.2533C20.9793 18.2533 22.36 17.0698 22.8334 15.8074C22.9321 15.551 22.9912 15.3143 22.9912 15.0776C22.9912 14.6633 22.7151 14.3675 22.3798 14.3675ZM18.3558 9.37694C19.559 9.37694 19.8944 10.3238 19.8944 11.2508C19.8944 11.4087 19.8746 11.6059 19.8352 11.744H16.62C16.6594 10.4618 17.3103 9.37694 18.3558 9.37694Z'
        fill='#00715D'
      />
      <path
        className='title'
        d='M34.4144 6.55622C34.4144 5.60941 33.6845 4.93875 32.8955 4.93875C31.4358 4.93875 31.2978 5.90529 31.1202 7.16771C31.0808 7.60166 31.0216 7.937 30.7652 8.0948C30.1734 7.89755 29.4633 7.79892 28.694 7.79892C25.7944 7.79892 23.9994 9.25859 23.9994 11.1325C23.9994 12.3357 24.6503 13.2628 25.6366 13.8546C24.7489 14.2885 23.9994 15.0184 23.9994 16.1822C23.9994 17.8786 25.2618 18.5492 27.2343 18.5492L28.911 18.5295C30.1537 18.5295 30.7849 18.6478 30.7849 19.358C30.7849 19.9892 30.134 20.5415 28.7926 20.5415C27.2343 20.5415 26.6031 19.8708 26.1297 19.4763C25.7747 19.1804 25.4788 18.9437 25.0448 18.9437C24.4728 18.9437 23.9599 19.3185 23.9599 19.9103C23.9599 20.502 24.3939 20.9557 25.0843 21.2516C25.8338 21.5869 27.0568 21.9222 28.5757 21.9222C31.6725 21.9222 33.8423 20.6007 33.8423 18.2533C33.8423 16.695 32.5996 15.6299 30.5876 15.6299C30.0945 15.6299 29.4436 15.689 27.4316 15.689C26.6031 15.689 26.4059 15.334 26.4059 14.9592C26.4059 14.6831 26.5045 14.4858 26.7215 14.328C27.3132 14.5055 27.9839 14.6042 28.6546 14.6042C31.6528 14.6042 33.3492 13.4206 33.3492 11.2508C33.3492 10.1462 32.7772 9.17969 31.7514 8.54848C32.8166 8.1737 34.4144 8.0948 34.4144 6.55622ZM28.8518 13.2234C27.9247 13.2234 27.2738 11.8821 27.2738 10.6985C27.2738 9.90953 27.5499 9.17969 28.4573 9.17969C29.4041 9.17969 30.0551 10.4816 30.0551 11.7243C30.0551 12.533 29.7592 13.2234 28.8518 13.2234Z'
        fill='#00715D'
      />
      <path
        className='title'
        d='M35.5766 17.9772H38.4368C39.1074 17.9772 39.64 17.8983 39.64 17.2868C39.64 16.6161 38.7721 16.5767 38.7721 15.7877V4.30754C38.7721 3.69605 38.3776 3.24237 37.5294 3.24237C36.5431 3.24237 34.1169 3.87358 34.1169 4.58369C34.1169 4.99792 34.3536 5.09655 34.7087 5.35298C35.0046 5.58968 35.261 5.82639 35.261 6.59567V15.7877C35.261 16.5767 34.3931 16.6161 34.3931 17.2868C34.3931 17.8983 34.9257 17.9772 35.5766 17.9772Z'
        fill='#00715D'
      />
      <path
        className='title'
        d='M43.0619 6.79293C44.3243 6.79293 45.1725 6.04336 45.1725 4.8993C45.1725 3.75523 44.3243 3.02539 43.0619 3.02539C41.7995 3.02539 40.9316 3.75523 40.9316 4.8993C40.9316 6.04336 41.7995 6.79293 43.0619 6.79293ZM41.76 17.9772H44.6202C45.2711 17.9772 45.8235 17.8983 45.8235 17.2868C45.8235 16.6161 44.9555 16.5767 44.9555 15.7877V8.90354C44.9358 8.29205 44.5413 7.83837 43.6537 7.83837C42.6082 7.83837 40.2806 8.46958 40.2806 9.17969C40.2806 9.59392 40.5371 9.69255 40.8921 9.94898C41.188 10.1857 41.4247 10.4224 41.4247 11.1917V15.7877C41.4247 16.5767 40.5568 16.6161 40.5568 17.2868C40.5568 17.8983 41.1091 17.9772 41.76 17.9772Z'
        fill='#00715D'
      />
      <path
        className='title'
        d='M47.9393 17.9772H50.7995C51.4504 17.9772 52.0027 17.8983 52.0027 17.2868C52.0027 16.6161 51.1348 16.5767 51.1348 15.7877V9.71227H51.9238C52.6339 9.71227 52.8706 9.43612 52.8706 8.86409C52.8706 8.31178 52.4958 8.11452 51.9633 8.11452H50.543C50.0105 7.56221 49.7343 7.02963 49.7343 6.26034C49.7343 5.43188 50.1288 4.95847 50.6614 4.95847C51.0953 4.95847 51.2926 5.19518 51.4504 5.98419C51.5688 6.49705 51.8055 6.99018 52.7325 6.99018C53.4032 6.99018 54.0936 6.4576 54.0936 5.49106C54.0936 4.34699 53.1665 3.24237 51.4504 3.24237C49.537 3.24237 47.6829 4.26809 47.6829 7.58194V8.11452H47.2489C46.6966 8.11452 46.4007 8.43013 46.4007 8.88381C46.4007 9.29804 46.6374 9.71227 47.17 9.71227H47.604V15.8074C47.604 16.5964 46.7361 16.6161 46.7361 17.2868C46.7361 17.8983 47.2884 17.9772 47.9393 17.9772Z'
        fill='#00715D'
      />
      <path
        className='title'
        d='M61.9614 14.3675C61.7641 14.3675 61.5669 14.4661 61.3696 14.6633C60.6595 15.4129 60.0875 15.6496 59.2196 15.6496C57.6415 15.6496 56.6158 14.7028 56.3002 13.0261H61.0146C62.0995 13.0261 62.5926 12.533 62.5926 11.4284C62.5926 9.61365 61.0146 7.8581 58.2727 7.8581C55.097 7.8581 53.0455 10.1462 53.0455 13.2628C53.0455 16.2808 55.1364 18.2533 58.4305 18.2533C60.5609 18.2533 61.9417 17.0698 62.4151 15.8074C62.5137 15.551 62.5729 15.3143 62.5729 15.0776C62.5729 14.6633 62.2967 14.3675 61.9614 14.3675ZM57.9374 9.37694C59.1407 9.37694 59.476 10.3238 59.476 11.2508C59.476 11.4087 59.4563 11.6059 59.4168 11.744H56.2016C56.241 10.4618 56.892 9.37694 57.9374 9.37694Z'
        fill='#00715D'
      />
    </>
  );
};

export default Vegan;
