import React, { ComponentType, FC } from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { BoxProps } from '@mui/material/Box/Box';
import { SPACINGS } from '../constants/spacings';

const Wrapper = styled(Box)`
  width: calc(100% + ${SPACINGS.LAYOUT}*2);
  margin-left: -${SPACINGS.LAYOUT};
`;

interface IWithFullWidthProps {
  withFullWidthProps?: BoxProps;
}

const withFullWidth = <P extends object>(Component: ComponentType<P>): FC<P & IWithFullWidthProps> => {
  return (props: P & IWithFullWidthProps) => {
    const { withFullWidthProps, ...newProps } = props;
    return (
      <Wrapper {...withFullWidthProps}>
        <Component {...(newProps as P)} />
      </Wrapper>
    );
  };
};

export default withFullWidth;
