import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';
import { useTranslation } from 'react-i18next';
import { PAYWALL_TYPE, Paywalls, PRODUCT_NAMES, Products } from '@organicapps/organictypes';
import CheckListItem from '../../molecules/CheckListItem/CheckListItem';

const Container = styled(Box)(({ theme }) => ({
  'li:not(:first-of-type)': {
    marginTop: theme.spacing(3),
  },
}));

const dance = ['paywall.what_you_get_0', 'paywall.what_you_get_1', 'paywall.what_you_get_2', 'paywall.what_you_get_3'];

const fit = [
  'paywall.plan_list0',
  'paywall.plan_list1',
  'paywall.plan_list2',
  'paywall.plan_list3',
  'paywall.plan_list4',
  'paywall.plan_list5',
  'paywall.plan_list6',
];
const man = [
  'paywall.plan_list0',
  'paywall.plan_list1',
  'paywall.plan_list2',
  'paywall.plan_list3',
  'paywall.plan_list4',
  'paywall.plan_list5',
  'paywall.plan_list6',
];
const walk = [
  'paywall.plan_list0',
  'paywall.plan_list1',
  'paywall.plan_list2',
  'paywall.plan_list3',
  'paywall.plan_list4',
  'paywall.plan_list5',
  'paywall.plan_list6',
];
const keto = [
  'paywall.plan_list0',
  'paywall.plan_list1',
  'paywall.plan_list2',
  'paywall.plan_list3',
  'paywall.plan_list4',
  'paywall.plan_list5',
  'paywall.plan_list6',
  'paywall.plan_list7',
  'paywall.plan_list8',
  'paywall.plan_list9',
];
const vegan = [
  'paywall.plan_list0',
  'paywall.plan_list1',
  'paywall.plan_list2',
  'paywall.plan_list3',
  'paywall.plan_list4',
  'paywall.plan_list5',
  'paywall.plan_list6',
  'paywall.plan_list7',
  'paywall.plan_list8',
];
const fasting = [
  'paywall.plan_list0',
  'paywall.plan_list1',
  'paywall.plan_list2',
  'paywall.plan_list3',
  'paywall.plan_list4',
  'paywall.plan_list5',
  'paywall.plan_list6',
  'paywall.plan_list7',
  'paywall.plan_list8',
];
const ketoGuide = [
  'ebook.keto_guide.includes0',
  'ebook.keto_guide.includes1',
  'ebook.keto_guide.includes2',
  'ebook.keto_guide.includes3',
  'ebook.keto_guide.includes4',
];
const veganGuide = [
  'ebook.vegan_guide.includes0',
  'ebook.vegan_guide.includes1',
  'ebook.vegan_guide.includes2',
  'ebook.vegan_guide.includes3',
  'ebook.vegan_guide.includes4',
  'ebook.vegan_guide.includes5',
  'ebook.vegan_guide.includes6',
];
const mealplan = [
  'ebook.meal_plan.includes0',
  'ebook.meal_plan.includes1',
  'ebook.meal_plan.includes2',
  'ebook.meal_plan.includes3',
  'ebook.meal_plan.includes4',
];
const groceryList = [
  'ebook.grocery_list.includes0',
  'ebook.grocery_list.includes1',
  'ebook.grocery_list.includes2',
  'ebook.grocery_list.includes3',
  'ebook.grocery_list.includes4',
  'ebook.grocery_list.includes5',
  'ebook.grocery_list.includes6',
];
const expressWorkoutsList = [
  'ebook.express_workouts.includes0',
  'ebook.express_workouts.includes1',
  'ebook.express_workouts.includes2',
  'ebook.express_workouts.includes3',
];
const calorieTracker = [
  'calorie.calorie_tracker.includes0',
  'calorie.calorie_tracker.includes1',
  'calorie.calorie_tracker.includes2',
  'calorie.calorie_tracker.includes3',
  'calorie.calorie_tracker.includes4',
];
const upperBodyExercisesList = [
  'ebook.upper_body_exercises.includes0',
  'ebook.upper_body_exercises.includes1',
  'ebook.upper_body_exercises.includes2',
  'ebook.upper_body_exercises.includes3',
  'ebook.upper_body_exercises.includes4',
];
const workoutPlanList = [
  'ebook.workout_plan.includes0',
  'ebook.workout_plan.includes1',
  'ebook.workout_plan.includes2',
  'ebook.workout_plan.includes3',
  'ebook.workout_plan.includes4',
];
const absExercisesList = [
  'ebook.abs_exercises.includes0',
  'ebook.abs_exercises.includes1',
  'ebook.abs_exercises.includes2',
  'ebook.abs_exercises.includes3',
];
const legsExercisesList = [
  'ebook.legs_exercises.includes0',
  'ebook.legs_exercises.includes1',
  'ebook.legs_exercises.includes2',
  'ebook.legs_exercises.includes3',
  'ebook.legs_exercises.includes4',
];
const healthyDesertsList = [
  'ebook.healthy_deserts.includes0',
  'ebook.healthy_deserts.includes1',
  'ebook.healthy_deserts.includes2',
  'ebook.healthy_deserts.includes3',
  'ebook.healthy_deserts.includes4',
];
const breakfastPlanList = [
  'ebook.breakfast_plan.includes0',
  'ebook.breakfast_plan.includes1',
  'ebook.breakfast_plan.includes2',
  'ebook.breakfast_plan.includes3',
];
const lunchPlanList = [
  'ebook.lunch_plan.includes0',
  'ebook.lunch_plan.includes1',
  'ebook.lunch_plan.includes2',
  'ebook.lunch_plan.includes3',
  'ebook.lunch_plan.includes4',
];
const dinnerPlanList = [
  'ebook.dinner_plan.includes0',
  'ebook.dinner_plan.includes1',
  'ebook.dinner_plan.includes2',
  'ebook.dinner_plan.includes3',
  'ebook.dinner_plan.includes4',
];
const snackListList = [
  'ebook.snack_list.includes0',
  'ebook.snack_list.includes1',
  'ebook.snack_list.includes2',
  'ebook.snack_list.includes3',
  'ebook.snack_list.includes4',
];
const healthyDrinksList = [
  'ebook.healthy_drinks.includes0',
  'ebook.healthy_drinks.includes1',
  'ebook.healthy_drinks.includes2',
  'ebook.healthy_drinks.includes3',
  'ebook.healthy_drinks.includes4',
];
const lowCarbGuideList = [
  'ebook.low_carb_guide.includes0',
  'ebook.low_carb_guide.includes1',
  'ebook.low_carb_guide.includes2',
  'ebook.low_carb_guide.includes3',
  'ebook.low_carb_guide.includes4',
];
const recipeBookList = [
  'ebook.recipe_book.includes0',
  'ebook.recipe_book.includes1',
  'ebook.recipe_book.includes2',
  'ebook.recipe_book.includes3',
  'ebook.recipe_book.includes4',
];
const fastingPlanList = [
  'ebook.fasting_plan.includes0',
  'ebook.fasting_plan.includes1',
  'ebook.fasting_plan.includes2',
  'ebook.fasting_plan.includes3',
];
const brazilianButtWorkoutsList = [
  'ebook.brazilian_butt_workouts.includes0',
  'ebook.brazilian_butt_workouts.includes1',
  'ebook.brazilian_butt_workouts.includes2',
  'ebook.brazilian_butt_workouts.includes3',
];
const antiCelluliteWorkoutsList = [
  'ebook.anti_cellulite_workouts.includes0',
  'ebook.anti_cellulite_workouts.includes1',
  'ebook.anti_cellulite_workouts.includes2',
  'ebook.anti_cellulite_workouts.includes3',
  'ebook.anti_cellulite_workouts.includes4',
  'ebook.anti_cellulite_workouts.includes5',
];
const burnFatWorkoutsList = [
  'ebook.burn_fat_workouts.includes0',
  'ebook.burn_fat_workouts.includes1',
  'ebook.burn_fat_workouts.includes2',
  'ebook.burn_fat_workouts.includes3',
];
const lostWeightWorkoutsList = [
  'ebook.lost_weight_workouts.includes0',
  'ebook.lost_weight_workouts.includes1',
  'ebook.lost_weight_workouts.includes2',
  'ebook.lost_weight_workouts.includes3',
];
const fullBodyWorkoutsList = [
  'ebook.full_body_workouts.includes0',
  'ebook.full_body_workouts.includes1',
  'ebook.full_body_workouts.includes2',
  'ebook.full_body_workouts.includes3',
];
const stretchingExercisesList = [
  'ebook.stretching_exercises.includes0',
  'ebook.stretching_exercises.includes1',
  'ebook.stretching_exercises.includes2',
  'ebook.stretching_exercises.includes3',
  'ebook.stretching_exercises.includes4',
];

const BOOKS_CONTENT: { [key in Exclude<PAYWALL_TYPE, PAYWALL_TYPE.OFFER | PAYWALL_TYPE.D_OFFER>]: string[] } = {
  [PAYWALL_TYPE.KETO]: ketoGuide,
  [PAYWALL_TYPE.VEGAN]: veganGuide,
  [PAYWALL_TYPE.MEALPLAN]: mealplan,
  [PAYWALL_TYPE.CALORIE]: calorieTracker,
  [PAYWALL_TYPE.GROCERY]: groceryList,
  [PAYWALL_TYPE.EXPRESS_WORKOUTS]: expressWorkoutsList,
  [PAYWALL_TYPE.UPPER_BODY_EXERCISES]: upperBodyExercisesList,
  [PAYWALL_TYPE.WORKOUT_PLAN]: workoutPlanList,
  [PAYWALL_TYPE.ABS_EXERCISES]: absExercisesList,
  [PAYWALL_TYPE.LEGS_EXERCISES]: legsExercisesList,
  [PAYWALL_TYPE.HEALTHY_DESERTS]: healthyDesertsList,
  [PAYWALL_TYPE.BREAKFAST_PLAN]: breakfastPlanList,
  [PAYWALL_TYPE.LUNCH_PLAN]: lunchPlanList,
  [PAYWALL_TYPE.DINNER_PLAN]: dinnerPlanList,
  [PAYWALL_TYPE.SNACK_LIST]: snackListList,
  [PAYWALL_TYPE.HEALTHY_DRINKS_GUIDE]: healthyDrinksList,
  [PAYWALL_TYPE.LOW_CARB_GUIDE]: lowCarbGuideList,
  [PAYWALL_TYPE.RECIPE_BOOK]: recipeBookList,
  [PAYWALL_TYPE.FASTING_PLAN]: fastingPlanList,
  [PAYWALL_TYPE.BRAZILIAN_BUTT_WORKOUTS]: brazilianButtWorkoutsList,
  [PAYWALL_TYPE.ANTI_CELLULITE_WORKOUTS]: antiCelluliteWorkoutsList,
  [PAYWALL_TYPE.BURN_FAT_WORKOUTS]: burnFatWorkoutsList,
  [PAYWALL_TYPE.LOST_WEIGHT_WORKOUTS]: lostWeightWorkoutsList,
  [PAYWALL_TYPE.FULL_BODY_WORKOUTS]: fullBodyWorkoutsList,
  [PAYWALL_TYPE.STRETCHING_EXERCISES]: stretchingExercisesList,
};

const OFFER_CONTENT = {
  [PRODUCT_NAMES.FIT]: fit,
  [PRODUCT_NAMES.DANCE]: dance,
  [PRODUCT_NAMES.WALK]: walk,
  [PRODUCT_NAMES.MAN]: man,
  [PRODUCT_NAMES.KETO]: keto,
  [PRODUCT_NAMES.FASTING]: fasting,
  [PRODUCT_NAMES.VEGAN]: vegan,
};

export interface IProps extends BoxProps {
  productName?: Products.Names;
  paywallType?: Paywalls.PaywallType;
  customContent?: string[];
  customIcon?: React.ReactNode;
  isPlainText?: boolean;
}

const CheckList: FC<IProps> = ({
  customContent,
  customIcon,
  productName,
  paywallType,
  isPlainText = false,
  ...rest
}) => {
  const { t } = useTranslation();

  let customText;
  if (customContent) {
    customText = customContent;
  } else if (paywallType === PAYWALL_TYPE.OFFER && productName) {
    customText = OFFER_CONTENT[productName];
  } else {
    customText = BOOKS_CONTENT[paywallType as Exclude<PAYWALL_TYPE, PAYWALL_TYPE.OFFER | PAYWALL_TYPE.D_OFFER>];
  }

  return (
    <Container {...rest}>
      {(customText || []).map((item) => (
        <CheckListItem customIcon={customIcon} key={item} text={isPlainText ? item : t(item)} />
      ))}
    </Container>
  );
};

export default CheckList;
