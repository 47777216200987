import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import D_OFFER from 'pages/D_Offer';
import DefaultLayout from '../layouts/DefaultLayout';
import ProductLayout from '../layouts/ProductLayout';
import NotFound from '../pages/NotFound';
import Register from '../pages/Register';
import Success from '../pages/Success';
import OfferRouter from '../pages/Offer';
import { DYNAMIC_ROUTE_PARAMS, PAGES } from '../constants/pages';
import KetoRouter from '../pages/Keto';
import MealRouter from '../pages/Meal';
import VeganRouter from '../pages/Vegan';
import GroceryRouter from '../pages/Grocery';
import CalorieRouter from '../pages/Calorie';
import DownloadRouter from '../pages/Download';
import CancelSubscriptionLayout from '../layouts/CancelSubscriptionLayout';
import ExpressWorkoutsRouter from '../pages/ExpressWorkouts';
import CancelRouter from '../pages/CancelSubscription';
import { FingerprintProvider, LoggerProvider } from '../providers';
import UpperBodyExercisesRouter from '../pages/UpperBodyExercises';
import WorkoutPlanRouter from '../pages/WorkoutPlan';
import AbsExercisesRouter from '../pages/AbsExercises';
import LegsExercisesRouter from '../pages/LegsExercises';
import HealthyDesertsRouter from '../pages/HealthyDeserts';
import BreakfastPlanRouter from '../pages/BreakfastPlan';
import LunchPlanRouter from '../pages/LunchPlan';
import DinnerPlanRouter from '../pages/DinnerPlan';
import SnackListRouter from '../pages/SnackList';
import HealthyDrinksGuideRouter from '../pages/HealthyDrinksGuide';
import LowCarbGuideRouter from '../pages/LowCarbGuide';
import RecipeBookRouter from '../pages/RecipeBook';
import FastingPlanRouter from '../pages/FastingPlan';
import BrazilianButtWorkoutsRouter from '../pages/BrazilianButtWorkouts';
import AntiCelluliteWorkoutsRouter from '../pages/AntiCelluliteWorkouts';
import BurnFatWorkoutsRouter from '../pages/BurnFatWorkouts';
import LoseWeightWorkoutsRouter from '../pages/LoseWeightWorkouts';
import FullBodyWorkoutsRouter from '../pages/FullBodyWorkouts';
import StretchingExercisesRouter from '../pages/StretchingExercises';
import UpsellsRouter from '../pages/Upsells';
import ManfitUpsells from '../pages/ManfitUpsells';
import DivKitLayout from '../layouts/DivkitLayout';
import DivkitProductLayout from '../layouts/DivkitProductLayout';

const AppRouter = () => {
  const SentryRoutes = withSentryReactRouterV6Routing(Routes);

  return (
    <BrowserRouter>
      <LoggerProvider>
        <FingerprintProvider>
          <SentryRoutes>
            <Route
              path={`/:${DYNAMIC_ROUTE_PARAMS.LOCALE}/:${DYNAMIC_ROUTE_PARAMS.PRODUCT_NAME}`}
              element={<DivKitLayout />}
            >
              <Route element={<DivkitProductLayout />}>
                <Route path={`${PAGES.D_OFFER}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`} element={<D_OFFER />} />
              </Route>
            </Route>
            <Route element={<DefaultLayout />}>
              <Route
                path={`/:${DYNAMIC_ROUTE_PARAMS.LOCALE}/:${DYNAMIC_ROUTE_PARAMS.PRODUCT_NAME}`}
                element={<ProductLayout />}
              >
                <Route path='' element={<Navigate to={PAGES.NOT_FOUND} replace />} />
                <Route
                  path={`${PAGES.KETO}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<KetoRouter />}
                />
                <Route
                  path={`${PAGES.GROCERY}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<GroceryRouter />}
                />
                <Route
                  path={`${PAGES.CALORIE}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<CalorieRouter />}
                />
                <Route
                  path={`${PAGES.MEALPLAN}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<MealRouter />}
                />
                <Route
                  path={`${PAGES.VEGAN}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<VeganRouter />}
                />
                <Route
                  path={`${PAGES.EXPRESS_WORKOUTS}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<ExpressWorkoutsRouter />}
                />
                <Route
                  path={`${PAGES.UPPER_BODY_EXERCISES}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<UpperBodyExercisesRouter />}
                />
                <Route
                  path={`${PAGES.WORKOUT_PLAN}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<WorkoutPlanRouter />}
                />
                <Route
                  path={`${PAGES.ABS_EXERCISES}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<AbsExercisesRouter />}
                />
                <Route
                  path={`${PAGES.LEGS_EXERCISES}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<LegsExercisesRouter />}
                />
                <Route
                  path={`${PAGES.HEALTHY_DESERTS}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<HealthyDesertsRouter />}
                />
                <Route
                  path={`${PAGES.BREAKFAST_PLAN}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<BreakfastPlanRouter />}
                />
                <Route
                  path={`${PAGES.LUNCH_PLAN}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<LunchPlanRouter />}
                />
                <Route
                  path={`${PAGES.DINNER_PLAN}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<DinnerPlanRouter />}
                />
                <Route
                  path={`${PAGES.SNACK_LIST}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<SnackListRouter />}
                />
                <Route
                  path={`${PAGES.HEALTHY_DRINKS_GUIDE}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<HealthyDrinksGuideRouter />}
                />
                <Route
                  path={`${PAGES.LOW_CARB_GUIDE}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<LowCarbGuideRouter />}
                />
                <Route
                  path={`${PAGES.RECIPE_BOOK}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<RecipeBookRouter />}
                />
                <Route
                  path={`${PAGES.FASTING_PLAN}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<FastingPlanRouter />}
                />
                <Route
                  path={`${PAGES.BRAZILIAN_BUTT_WORKOUTS}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<BrazilianButtWorkoutsRouter />}
                />
                <Route
                  path={`${PAGES.ANTI_CELLULITE_WORKOUTS}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<AntiCelluliteWorkoutsRouter />}
                />
                <Route
                  path={`${PAGES.BURN_FAT_WORKOUTS}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<BurnFatWorkoutsRouter />}
                />
                <Route
                  path={`${PAGES.LOSE_WEIGHT_WORKOUTS}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<LoseWeightWorkoutsRouter />}
                />
                <Route
                  path={`${PAGES.FULL_BODY_WORKOUTS}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<FullBodyWorkoutsRouter />}
                />
                <Route
                  path={`${PAGES.STRETCHING_EXERCISES}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<StretchingExercisesRouter />}
                />
                <Route
                  path={`${PAGES.OFFER}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<OfferRouter />}
                />
                <Route
                  path={`${PAGES.SUCCESS}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<Success />}
                />
                <Route
                  path={`${PAGES.UPSELLS}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<UpsellsRouter />}
                />
                {/* ToDo: ManFit Upsells Test Temporary */}
                <Route
                  path={`${PAGES.MANFIT_UPSELLS}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<ManfitUpsells />}
                />
                <Route
                  path={`${PAGES.REGISTER}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<Register />}
                />
                <Route
                  path={`${PAGES.DOWNLOAD}/:${DYNAMIC_ROUTE_PARAMS.VERSION}/:${DYNAMIC_ROUTE_PARAMS.ONBOARDING_ID}`}
                  element={<DownloadRouter />}
                />
              </Route>
              <Route
                path={`/:${DYNAMIC_ROUTE_PARAMS.LOCALE}/:${DYNAMIC_ROUTE_PARAMS.PRODUCT_NAME}/${PAGES.CANCEL}/`}
                element={<CancelSubscriptionLayout />}
              >
                <Route path='' element={<Navigate to={PAGES.NOT_FOUND} replace />} />
                <Route path={`:${DYNAMIC_ROUTE_PARAMS.VERSION}/*`} element={<CancelRouter />} />
              </Route>
              <Route path='*' element={<Navigate to={PAGES.NOT_FOUND} replace />} />
              <Route path={PAGES.NOT_FOUND} element={<NotFound />} />
            </Route>
          </SentryRoutes>
        </FingerprintProvider>
      </LoggerProvider>
    </BrowserRouter>
  );
};

export default AppRouter;
