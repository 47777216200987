import type { Logger } from 'modules/logger';
import type { ACTION_LOG_ID_TYPES, StatCallbackType } from 'types';
import type { IActionsProcessorOptions } from './types';
import type { IActionProcessor } from './processors';

export default class ActionsProcessorFactory {
  private readonly logger?: Logger;

  private processors: Map<ACTION_LOG_ID_TYPES, IActionProcessor> = new Map();

  constructor(eventProcessors: IActionProcessor[], opts?: IActionsProcessorOptions) {
    this.logger = opts?.logger;
    eventProcessors.forEach((item) => {
      this.processors.set(item.binding, item);
    });
    this.logger?.logInfo({ processors: [...this.processors.keys()] }, 'actionProcessorInitialized');
  }

  process(action: StatCallbackType) {
    try {
      const logIdType = action.action.log_id as ACTION_LOG_ID_TYPES;
      const actionData = action.action;
      const processor = this.processors.get(logIdType);
      if (processor) {
        processor.process(actionData);
        this?.logger?.logInfo({ processor: processor.binding }, 'actionProcessed');
      }
    } catch (e) {
      this?.logger?.logError(e as Error);
    }
  }
}
