import { Analytics, CURRENCIES, Payments } from '@organicapps/organictypes';
import BaseApi from './baseApi';
import { FingerprintType } from '../modules/fingerprint';

export interface IUserData {
  _id: string;
  email: string;
  appUserId: string;
  appUserToken: string;
  popupVariant: string;
  onboardingVariant: string;
  city: string;
  country: string;
  currency: string;
  firstName: string;
  lastName: string;
  ip: string;
  lang: string;
  complete: boolean;
  paid: boolean;
  upsellsPaid: boolean;
  localCurrency: CURRENCIES;
  exchangeRate: number;
  weight: string | null;
  desiredWeight: string | null;
  pilatesWallWorkouts?: string;
  bodyType?: string;
  analyticsSettings?: Analytics.UserAnalyticsSettings;
}

export interface ISubscription {
  email: string;
  appUserId: string;
  valid_due: Date;
  lastProvider: Payments.PaymentSystems;
  duration: string;
  currency: CURRENCIES;
  price: number;
  createdAt: Date;
  cancelledAt: Date | null;
  subscriptionId: string;
}

interface ISubscriptionCancel {
  success: boolean;
  message: string;
}

interface ISendFeedback extends ISubscriptionCancel {}

interface IRegisterPayload {
  name: string;
  email: string;
  password: string;
  appUserToken: string;
}

interface IAppleConnectPayload {
  authorizationCode: string;
  fullName?: string;
  redirectUrl: string;
  clientId: string;
}

interface IUserFingerprint {}

export default class UserClient {
  private baseApi: BaseApi;

  constructor(baseApi: BaseApi) {
    this.baseApi = baseApi;
  }

  async getUserData(onboardingId: string, popupVariant: string | null) {
    const url = popupVariant
      ? `/v1/onboarding/${onboardingId}?popupVariant=${popupVariant}`
      : `/v1/onboarding/${onboardingId}`;
    const response = await this.baseApi.get<IUserData>(url);
    return response.data;
  }

  async getUserAnalyticsSettings(appUserToken: string) {
    const response = await this.baseApi.get<Analytics.UserAnalyticsSettings>('/v1/analytics-settings', {
      headers: {
        'x-session-token': appUserToken,
      },
    });
    return response.data;
  }

  async getSubscription(appUserToken: string) {
    const response = await this.baseApi.get<ISubscription>('/subscription', {
      headers: {
        'x-session-token': appUserToken,
      },
    });
    return response.data;
  }

  async onSubscriptionCancel(appUserToken: string) {
    const response = await this.baseApi.post<ISubscriptionCancel>(
      '/subscription/cancel',
      {},
      {
        headers: {
          'x-session-token': appUserToken,
        },
      }
    );
    return response.data;
  }

  async onRegister({ appUserToken, name, email, password }: IRegisterPayload) {
    const response = await this.baseApi.post(
      '/onboarding/add-email',
      { name, email, password },
      {
        headers: {
          'x-session-token': appUserToken,
        },
      }
    );
    return response.data;
  }

  async onAppleConnect(payload: IAppleConnectPayload, appUserToken: string) {
    const response = await this.baseApi.post('/v1/auth/apple/connect', payload, {
      headers: {
        'x-session-token': appUserToken,
      },
    });
    return response.data;
  }

  async getTranslation(locale: string, key: string, force?: boolean) {
    let url = `/translations?locale=${locale}&key=${key}`;
    if (force) {
      url = `${url}&force=true`;
    }
    const response = await this.baseApi.get(url);
    return response.data;
  }

  async sendFeedback(appUserToken: string, message: string) {
    const response = await this.baseApi.post<ISendFeedback>(
      '/feedback',
      {
        message,
      },
      {
        headers: {
          'x-session-token': appUserToken,
        },
      }
    );
    return response.data;
  }

  async sendUserFingerprint(appUserToken: string, payload: FingerprintType, keepalive = false) {
    const response = await this.baseApi.post<IUserFingerprint>('/v1/user/fingerprint', payload, {
      keepalive,
      headers: {
        'x-session-token': appUserToken,
      },
    });
    return response.data;
  }
}
