import type { Logger } from '../modules/logger';

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
}

export interface IBaseApiOptions {
  forceGatewayApiUrl?: boolean;
  logger?: Logger;
}

export type ResponseType = 'json' | 'text' | 'arrayBuffer' | 'blob' | 'formData';

export type CustomRequest = RequestInit & {
  responseType?: ResponseType;
};
