import { createTheme } from '@mui/material/styles';
import { DARK_THEME_COLORS, GREY_THEME_COLORS, LIGHT_THEME_COLORS } from '../constants/colors';
import { darkTheme, greyTheme, lightTheme, THEME_MODES } from './common';

const manDarkTheme = createTheme({
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    primary: {
      main: DARK_THEME_COLORS.PRIMARY_MAN,
      dark: DARK_THEME_COLORS.PRIMARY_MAN_DARK,
      light: DARK_THEME_COLORS.PRIMARY_MAN_LIGHT,
    },
    secondary: {
      main: DARK_THEME_COLORS.SECONDARY_MAN,
      light: DARK_THEME_COLORS.SECONDARY_MAN_LIGHT,
      dark: DARK_THEME_COLORS.SECONDARY_MAN_DARK,
    },
    gradient: {
      main: DARK_THEME_COLORS.SPECIAL_OFFER_MAN_GRADIENT,
    },
  },
});

const manGreyTheme = createTheme({
  ...greyTheme,
  palette: {
    ...greyTheme.palette,
    primary: {
      main: GREY_THEME_COLORS.PRIMARY_MAN,
      dark: GREY_THEME_COLORS.PRIMARY_MAN_DARK,
      light: GREY_THEME_COLORS.PRIMARY_MAN_LIGHT,
    },
    secondary: {
      main: GREY_THEME_COLORS.SECONDARY_MAN,
      light: GREY_THEME_COLORS.SECONDARY_MAN_LIGHT,
      dark: GREY_THEME_COLORS.SECONDARY_MAN_DARK,
    },
    gradient: {
      main: GREY_THEME_COLORS.SPECIAL_OFFER_MAN_GRADIENT,
    },
  },
});

const manLightTheme = createTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    primary: {
      main: LIGHT_THEME_COLORS.PRIMARY_MAN,
      dark: LIGHT_THEME_COLORS.PRIMARY_MAN_DARK,
      light: LIGHT_THEME_COLORS.PRIMARY_MAN_LIGHT,
    },
    secondary: {
      main: LIGHT_THEME_COLORS.SECONDARY_MAN,
      light: LIGHT_THEME_COLORS.SECONDARY_MAN_LIGHT,
      dark: LIGHT_THEME_COLORS.SECONDARY_MAN_DARK,
    },
    gradient: {
      main: LIGHT_THEME_COLORS.SPECIAL_OFFER_MAN_GRADIENT,
    },
  },
});

export const manThemes = {
  [THEME_MODES.DARK]: manDarkTheme,
  [THEME_MODES.LIGHT]: manLightTheme,
  [THEME_MODES.GREY]: manGreyTheme,
};
