import React, { ComponentType } from 'react';
import { Box, Fade } from '@mui/material';
import styled from '@emotion/styled';
import { BoxProps } from '@mui/material/Box/Box';
import { SPACINGS } from '../constants/spacings';

const Wrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 50%;
  max-width: ${SPACINGS.MAX_SCREEN_WIDTH_PX};
  display: flex;
  transform: translateX(-50%);
  justify-content: center;
  padding: 0 ${SPACINGS.LAYOUT};
`;

interface IWithPositionFixedProps {
  withPositionFixedProps?: BoxProps & { hidden?: boolean };
  fixed?: boolean;
}

const withPositionFixed = <P extends object>(Component: ComponentType<P>, isFixedByDefault = true) => {
  return React.forwardRef((props: P & IWithPositionFixedProps, ref) => {
    const { withPositionFixedProps, fixed = isFixedByDefault, ...newProps } = props;
    const hidden = withPositionFixedProps?.hidden;
    return (
      <Fade in={!hidden}>
        {fixed ? (
          <Wrapper {...withPositionFixedProps}>
            <Component ref={ref} {...(newProps as P)} />
          </Wrapper>
        ) : (
          <Component ref={ref} {...(newProps as P)} />
        )}
      </Fade>
    );
  });
};

export default withPositionFixed;
