import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { CURRENCIES, PAYMENT_SYSTEMS } from '@organicapps/organictypes';
import logger from './middlewares/loggerMiddleware';
import { ENV, NODE_ENV } from '../constants/envKeysMapper';
import { ISubscription } from '../api/userClient';

interface ISubscriptionState extends ISubscription {
  setIsCancelled: (date: Date) => void;
  setSubscriptionData: (data: ISubscription & { appUserToken: string }) => void;
  appUserToken: string;
}

export const useSubscriptionStore = create<ISubscriptionState>()(
  logger(
    devtools(
      persist(
        (set, get) => ({
          cancelled: false,
          subscriptionId: '',
          appUserId: '',
          lastProvider: PAYMENT_SYSTEMS.STRIPE,
          productName: null,
          duration: '',
          currency: CURRENCIES.USD,
          price: 0,
          valid_due: new Date(),
          createdAt: new Date(),
          email: '',
          appUserToken: '',
          cancelledAt: null,
          setSubscriptionData: (data: ISubscription & { appUserToken: string }) => {
            const store = get();
            set(
              {
                setSubscriptionData: store.setSubscriptionData,
                setIsCancelled: store.setIsCancelled,
                ...data,
              },
              true,
              'subscription/setData'
            );
          },
          setIsCancelled: (date: Date) => set({ cancelledAt: date }, false, 'subscription/setIsCancelled'),
        }),
        {
          name: 'subscriptionState',
          getStorage: () => sessionStorage,
        }
      ),
      { name: 'useSubscriptionStore', enabled: NODE_ENV === ENV.DEVELOPMENT }
    ),
    '[subscriptionStore]'
  )
);
