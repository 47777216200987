import { LOCALES_LIST } from '@organicapps/organictypes';
import { CURRENCIES } from '@organicapps/organictypes/lib/modules/paywalls';
import { i18nPriceFormat } from '../../helpers/currencies';

export const priceFormat = (value: string | number, lng: string | undefined, options: Record<string, any>) => {
  const locale = lng || LOCALES_LIST.EN;
  const exchangeRate = options?.userData?.exchangeRate || 1;
  const localCurrency = options?.userData?.localCurrency || options?.priceListItem.price.currency || CURRENCIES.USD;
  const currencyDisplay = options?.currencyDisplay || 'symbol';

  return i18nPriceFormat(locale, localCurrency, exchangeRate, currencyDisplay)(Number(value));
};
