import React, { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

import CheckIconV2 from './icons/CheckIconV2';

const variantMapper: { [key: string]: JSX.Element } = {
  checkIconV2: <CheckIconV2 />,
};

interface IProps extends SvgIconProps {
  variant?: string;
}
const CheckIcon: FC<IProps> = ({ variant, ...props }) => {
  if (variant && variantMapper?.[variant]) {
    return variantMapper[variant];
  }
  return <CheckCircleIcon sx={{ scale: '1.1' }} color='primary' {...props} />;
};

export default CheckIcon;
