import React, { FC, ReactElement, useMemo } from 'react';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import { CommonTypes } from '@organicapps/organictypes';

export type Props = TextFieldProps & {
  complete?: boolean;
  customIcon?: ReactElement;
};

const FormInput: FC<Props> = ({ error, complete, customIcon, ...rest }) => {
  const icon: CommonTypes.Nullable<ReactElement> = useMemo(() => {
    if (customIcon) {
      return customIcon;
    }
    if (complete) {
      return <DoneIcon color='success' />;
    }
    if (error) {
      return <ErrorIcon color='error' />;
    }

    return null;
  }, [customIcon, complete, error]);

  return <TextField error={error} {...rest} InputProps={{ endAdornment: icon }} />;
};

export default FormInput;
