import { createGlobalVariablesController, createVariable } from '@divkitframework/divkit/client-hydratable';
import type { VariableValue } from '@divkitframework/divkit/typings/variables';
import type { IGlobalVariablesOptions, VariablesStateType } from './types';
import type { Logger } from '../logger';
import { GLOBAL_VARIABLES_SETTINGS } from './constants';

export default class GlobalVariablesFactory {
  private readonly logger?: Logger;

  readonly controller = createGlobalVariablesController();

  readonly variables: VariablesStateType = Object.entries(GLOBAL_VARIABLES_SETTINGS).reduce((res, [name, settings]) => {
    const { type, initial } = settings;
    const viewVersionName = this.getViewVersionName(name);
    return {
      ...res,
      [name]: createVariable(name, type, initial),
      [viewVersionName]: createVariable(viewVersionName, type, initial),
    };
  }, {} as VariablesStateType);

  constructor(opts?: IGlobalVariablesOptions) {
    this.logger = opts?.logger;
    Object.entries(this.variables).forEach(([name, variable]) => {
      variable.subscribe((value: VariableValue) => {
        this.logger?.logInfo({ [name]: value }, 'globalVariableValueChanged');
        // ToDo: add viewMapper
      });

      this.controller.setVariable(variable);
    });

    this?.logger?.logInfo({ variables: Object.keys(this.variables) }, 'globalVariablesInitialized');
  }

  private getViewVersionName(name: string) {
    return `${name}View`;
  }
}
