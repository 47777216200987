import React, { FC, Suspense } from 'react';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../../../constants/searchParams';
import { EVENT_TYPES, EVENTS } from '../../../constants/analytics';
import { useAnalytics } from '../../../hooks/useAnalytics';

const v1 = React.lazy(() => import('../../molecules/OfferPaymentDrawerItem/v1/OfferPaymentDrawerItem'));
const v2 = React.lazy(() => import('../../molecules/OfferPaymentDrawerItem/v2/OfferPaymentDrawerItem'));

const routes: { [key: string]: FC<IDrawerWrappedProps> } = {
  v1,
  v2,
};
export interface IDrawerProps extends DrawerProps {
  onPaid?: () => void;
}

export interface IDrawerWrappedProps extends IDrawerProps {
  onClose: (e: React.MouseEvent) => void;
}

const OfferPaymentDrawer: FC<IDrawerProps> = ({ open, onClose, onPaid, ...rest }) => {
  const [searchParams] = useSearchParams();
  const { logEvent } = useAnalytics();
  let OfferPaymentDrawerItem = routes.v1;
  const checkoutVersion = searchParams.get(SEARCH_PARAMS.CHECKOUT_VERSION);

  if (checkoutVersion) {
    OfferPaymentDrawerItem = routes[checkoutVersion] || OfferPaymentDrawerItem;
  }
  const handleClose = (e: React.MouseEvent) => {
    if (onClose) {
      onClose(e, 'backdropClick');
    }
    logEvent({
      type: EVENT_TYPES.ACTION,
      name: EVENTS.PAYMENT_DRAWER_CLOSE,
      properties: {},
    });
  };

  return (
    <Suspense fallback={null}>
      <OfferPaymentDrawerItem keepMounted open={open} onClose={handleClose} onPaid={onPaid} {...rest} />
    </Suspense>
  );
};

export default OfferPaymentDrawer;
