import { ANALYTICS_PROVIDER, type CommonTypes, PRODUCT_NAMES } from '@organicapps/organictypes';

export enum ENV {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}

export const NODE_ENV = process.env.NODE_ENV!;

export const IS_DEV = NODE_ENV === ENV.DEVELOPMENT;

export const API_URLS = {
  [PRODUCT_NAMES.DANCE]: process.env.API_URL_DANCE,
  [PRODUCT_NAMES.FIT]: process.env.API_URL_FIT,
  [PRODUCT_NAMES.WALK]: process.env.API_URL_WALK,
  [PRODUCT_NAMES.MAN]: process.env.API_URL_MAN,
  [PRODUCT_NAMES.KETO]: process.env.API_URL_KETO,
  [PRODUCT_NAMES.FASTING]: process.env.API_URL_FASTING,
  [PRODUCT_NAMES.VEGAN]: process.env.API_URL_VEGAN,
};

// ToDo: TEMP ALTERNATIVE API COZ OF TWO BACKENDS
export const GATEWAY_API_URL = process.env.GATEWAY_API_URL!;

export const STRIPE_KEYS = {
  [PRODUCT_NAMES.DANCE]: process.env.STRIPE_KEY_DANCE,
  [PRODUCT_NAMES.FIT]: process.env.STRIPE_KEY_FIT,
  [PRODUCT_NAMES.WALK]: process.env.STRIPE_KEY_WALK,
  [PRODUCT_NAMES.MAN]: process.env.STRIPE_KEY_MAN,
  [PRODUCT_NAMES.KETO]: process.env.STRIPE_KEY_KETO,
  [PRODUCT_NAMES.FASTING]: process.env.STRIPE_KEY_FASTING,
  [PRODUCT_NAMES.VEGAN]: process.env.STRIPE_KEY_VEGAN,
};

export const AUTHORIZE_KEYS = {
  [PRODUCT_NAMES.DANCE]: {
    apiLoginID: process.env.AUTHORIZE_DANCE_ID!,
    clientKey: process.env.AUTHORIZE_DANCE_KEY!,
  },
  [PRODUCT_NAMES.FIT]: {
    apiLoginID: process.env.AUTHORIZE_FIT_ID!,
    clientKey: process.env.AUTHORIZE_FIT_KEY!,
  },
  [PRODUCT_NAMES.FASTING]: {
    apiLoginID: process.env.AUTHORIZE_FASTING_ID!,
    clientKey: process.env.AUTHORIZE_FASTING_KEY!,
  },
  [PRODUCT_NAMES.KETO]: {
    apiLoginID: process.env.AUTHORIZE_KETO_ID!,
    clientKey: process.env.AUTHORIZE_KETO_KEY!,
  },
  [PRODUCT_NAMES.VEGAN]: {
    apiLoginID: process.env.AUTHORIZE_VEGAN_ID!,
    clientKey: process.env.AUTHORIZE_VEGAN_KEY!,
  },
  [PRODUCT_NAMES.WALK]: {
    apiLoginID: process.env.AUTHORIZE_WALK_ID!,
    clientKey: process.env.AUTHORIZE_WALK_KEY!,
  },
  [PRODUCT_NAMES.MAN]: {
    apiLoginID: process.env.AUTHORIZE_MAN_ID!,
    clientKey: process.env.AUTHORIZE_MAN_KEY!,
  },
};

export const PAYPAL_CLIENT_IDS = {
  [PRODUCT_NAMES.DANCE]: process.env.PAYPAL_CLIENT_ID_DANCE!,
  [PRODUCT_NAMES.FIT]: process.env.PAYPAL_CLIENT_ID_FIT!,
  [PRODUCT_NAMES.WALK]: process.env.PAYPAL_CLIENT_ID_WALK!,
  [PRODUCT_NAMES.MAN]: process.env.PAYPAL_CLIENT_ID_MAN!,
  [PRODUCT_NAMES.KETO]: process.env.PAYPAL_CLIENT_ID_KETO!,
  [PRODUCT_NAMES.FASTING]: process.env.PAYPAL_CLIENT_ID_FASTING!,
  [PRODUCT_NAMES.VEGAN]: process.env.PAYPAL_CLIENT_ID_VEGAN!,
};

export const BRAINTREE_PK = process.env.BRAINTREE_PK!;
export const BRAINTREE_PP_CLIENT_ID = process.env.BRAINTREE_PP_CLIENT_ID!;

export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID!;
export const INTERCOM_API = process.env.INTERCOM_API!;

export const AMPLITUDE_KEYS = {
  [PRODUCT_NAMES.DANCE]: process.env.AMPLITUDE_DANCE,
  [PRODUCT_NAMES.FIT]: process.env.AMPLITUDE_FIT,
  [PRODUCT_NAMES.WALK]: process.env.AMPLITUDE_WALK,
  [PRODUCT_NAMES.MAN]: process.env.AMPLITUDE_MAN,
  [PRODUCT_NAMES.KETO]: process.env.AMPLITUDE_KETO,
  [PRODUCT_NAMES.FASTING]: process.env.AMPLITUDE_FASTING,
  [PRODUCT_NAMES.VEGAN]: process.env.AMPLITUDE_VEGAN,
};

export const FB_PIXEL_KEYS = {
  [PRODUCT_NAMES.DANCE]: process.env.FB_PIXEL_DANCE,
  [PRODUCT_NAMES.FIT]: process.env.FB_PIXEL_FIT,
  [PRODUCT_NAMES.WALK]: process.env.FB_PIXEL_WALK,
  [PRODUCT_NAMES.MAN]: process.env.FB_PIXEL_MAN,
  [PRODUCT_NAMES.KETO]: process.env.FB_PIXEL_KETO,
  [PRODUCT_NAMES.FASTING]: process.env.FB_PIXEL_FASTING,
  [PRODUCT_NAMES.VEGAN]: process.env.FB_PIXEL_VEGAN,
};

export const GOOGLE_TAG_KEYS = {
  [PRODUCT_NAMES.DANCE]: process.env.GOOGLE_TAG_DANCE,
  [PRODUCT_NAMES.FIT]: process.env.GOOGLE_TAG_FIT,
  [PRODUCT_NAMES.WALK]: process.env.GOOGLE_TAG_WALK,
  [PRODUCT_NAMES.MAN]: process.env.GOOGLE_TAG_MAN,
  [PRODUCT_NAMES.KETO]: process.env.GOOGLE_TAG_KETO,
  [PRODUCT_NAMES.FASTING]: process.env.GOOGLE_TAG_FASTING,
  [PRODUCT_NAMES.VEGAN]: process.env.GOOGLE_TAG_VEGAN,
};

export const TIKTOK_KEYS = {
  [PRODUCT_NAMES.DANCE]: process.env.TIKTOK_DANCE,
  [PRODUCT_NAMES.FIT]: process.env.TIKTOK_FIT,
  [PRODUCT_NAMES.WALK]: process.env.TIKTOK_WALK,
  [PRODUCT_NAMES.MAN]: process.env.TIKTOK_MAN,
  [PRODUCT_NAMES.KETO]: process.env.TIKTOK_KETO,
  [PRODUCT_NAMES.FASTING]: process.env.TIKTOK_FASTING,
  [PRODUCT_NAMES.VEGAN]: process.env.TIKTOK_VEGAN,
};

export const PINTEREST_KEYS = {
  [PRODUCT_NAMES.DANCE]: process.env.PINTEREST_DANCE,
  [PRODUCT_NAMES.FIT]: process.env.PINTEREST_FIT,
  [PRODUCT_NAMES.WALK]: process.env.PINTEREST_WALK,
  [PRODUCT_NAMES.MAN]: process.env.PINTEREST_MAN,
  [PRODUCT_NAMES.KETO]: process.env.PINTEREST_KETO,
  [PRODUCT_NAMES.FASTING]: process.env.PINTEREST_FASTING,
  [PRODUCT_NAMES.VEGAN]: process.env.PINTEREST_VEGAN,
};

export const ANALYTICS_KEYS: { [key in ANALYTICS_PROVIDER]: { [key in PRODUCT_NAMES]: CommonTypes.Nullable<string> } } =
  {
    [ANALYTICS_PROVIDER.AMPLITUDE]: AMPLITUDE_KEYS,
    [ANALYTICS_PROVIDER.FACEBOOK]: FB_PIXEL_KEYS,
    [ANALYTICS_PROVIDER.GOOGLE]: GOOGLE_TAG_KEYS,
    [ANALYTICS_PROVIDER.TIKTOK]: TIKTOK_KEYS,
    [ANALYTICS_PROVIDER.PINTEREST]: PINTEREST_KEYS,
  };

export const SENTRY_DSN = process.env.SENTRY_DSN!;

export const VWO_ACCOUNT_ID = process.env.VWO_ACCOUNT_ID!;
export const VWO_API_URL = process.env.VWO_API_URL!;
