import { createTheme } from '@mui/material/styles';
import { DARK_THEME_COLORS, GREY_THEME_COLORS, LIGHT_THEME_COLORS } from '../constants/colors';
import { darkTheme, greyTheme, lightTheme, THEME_MODES } from './common';

const fitLightTheme = createTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    primary: {
      main: LIGHT_THEME_COLORS.PRIMARY_FIT,
      dark: LIGHT_THEME_COLORS.PRIMARY_FIT_DARK,
      light: LIGHT_THEME_COLORS.PRIMARY_FIT_LIGHT,
    },
    secondary: {
      main: LIGHT_THEME_COLORS.SECONDARY_FIT,
      light: LIGHT_THEME_COLORS.SECONDARY_FIT_LIGHT,
      dark: LIGHT_THEME_COLORS.SECONDARY_FIT_DARK,
    },
    gradient: {
      main: LIGHT_THEME_COLORS.SPECIAL_OFFER_FIT_GRADIENT,
    },
  },
});

const fitGreyTheme = createTheme({
  ...greyTheme,
  palette: {
    ...greyTheme.palette,
    primary: {
      main: GREY_THEME_COLORS.PRIMARY_FIT,
      dark: GREY_THEME_COLORS.PRIMARY_FIT_DARK,
      light: GREY_THEME_COLORS.PRIMARY_FIT_LIGHT,
    },
    secondary: {
      main: GREY_THEME_COLORS.SECONDARY_FIT,
      light: GREY_THEME_COLORS.SECONDARY_FIT_LIGHT,
      dark: GREY_THEME_COLORS.SECONDARY_FIT_DARK,
    },
    gradient: {
      main: GREY_THEME_COLORS.SPECIAL_OFFER_FIT_GRADIENT,
    },
  },
});

const fitDarkTheme = createTheme({
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    primary: {
      main: DARK_THEME_COLORS.PRIMARY_FIT,
      dark: DARK_THEME_COLORS.PRIMARY_FIT_DARK,
      light: DARK_THEME_COLORS.PRIMARY_FIT_LIGHT,
    },
    secondary: {
      main: DARK_THEME_COLORS.SECONDARY_FIT,
      light: DARK_THEME_COLORS.SECONDARY_FIT_LIGHT,
      dark: DARK_THEME_COLORS.SECONDARY_FIT_DARK,
    },
    gradient: {
      main: DARK_THEME_COLORS.SPECIAL_OFFER_FIT_GRADIENT,
    },
  },
});

export const fitThemes = {
  [THEME_MODES.DARK]: fitDarkTheme,
  [THEME_MODES.LIGHT]: fitLightTheme,
  [THEME_MODES.GREY]: fitGreyTheme,
};
