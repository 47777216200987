import { EVENT_TYPES } from '@organicapps/organictypes';
import type { IEventProcessor, IEventProcessorOpts } from './types';
import { ACTION_LOG_ID_TYPES, type StatCallbackActionType } from '../../../types';
import { EVENTS } from '../../../constants/analytics';

export default class ShowCardEventProcessor implements IEventProcessor {
  binding = ACTION_LOG_ID_TYPES.SHOW_CARD;

  private readonly logger?: IEventProcessorOpts['logger'];

  private readonly analytics: IEventProcessorOpts['analytics'];

  constructor(opts: IEventProcessorOpts) {
    this.logger = opts?.logger;
    this.analytics = opts.analytics;
  }

  process(action: StatCallbackActionType): ReturnType<IEventProcessor['process']> {
    const { payload } = action;
    const pageName = payload?.pageName as string;
    if (pageName) {
      const event = {
        type: EVENT_TYPES.VIEW,
        name: EVENTS.PAYWALL,
        properties: {
          version: pageName,
        },
      };

      this.analytics.logEvent(event);
      this?.logger?.logInfo({ event }, 'eventEmitted');
    }
  }
}
