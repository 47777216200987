import type { DivExtension, DivExtensionContext, IExtensionBuilder } from './types';
import { EXTENSION_NAMES } from './constants';
import type { Logger } from '../../logger';

class IntercomExtension implements DivExtension {
  static logger?: Logger;

  private opened = false;

  private readonly cb = () => {
    try {
      if (this.opened) {
        window?.Intercom('hide');
        this.opened = false;
      } else {
        window?.Intercom('show');
        this.opened = true;
      }
    } catch (e) {
      IntercomExtension?.logger?.logError(e as Error);
    }
  };

  mountView(node: HTMLElement, context: DivExtensionContext) {
    if (node) {
      try {
        // eslint-disable-next-line no-param-reassign
        node.style.cursor = 'pointer';
        node.addEventListener('click', this.cb);
        if (window?.Intercom) {
          window?.Intercom('onHide', () => {
            this.opened = false;
          });
        }
      } catch (e) {
        IntercomExtension?.logger?.logError(e as Error);
      }
    }
  }

  unmountView(node: HTMLElement, context: DivExtensionContext) {
    if (node) {
      try {
        node.removeEventListener('click', this.cb);
      } catch (e) {
        IntercomExtension?.logger?.logError(e as Error);
      }
    }
  }
}

export default class IntercomExtensionBuilder implements IExtensionBuilder {
  readonly name = EXTENSION_NAMES.INTERCOM;

  readonly extension: IExtensionBuilder['extension'];

  constructor() {
    this.extension = IntercomExtension;
  }
}
