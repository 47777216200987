import CustomComponentsFactory from './factory';
import type { ICustomComponent, ICustomComponentsOptions } from './types';

export type { ICustomComponent, CustomComponents, ICustomComponentsOptions } from './types';

export { default as PriceListComponent } from './components/price-list';
export { CUSTOM_COMPONENTS } from './components';

export default function factory(
  customComponents: ICustomComponent[],
  opts?: ICustomComponentsOptions
): InstanceType<typeof CustomComponentsFactory> {
  return new CustomComponentsFactory(customComponents, opts);
}
