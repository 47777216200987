import React, { FC, useEffect, useMemo, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { i18n as i18nBase } from 'i18next';
import { PRODUCT_NAMES } from '@organicapps/organictypes';
import Loader from '../components/molecules/Loader/Loader';
import { danceThemes, fastingThemes, fitThemes, ketoThemes, manThemes, veganThemes, walkThemes } from '../themes';
import { IProductPageParams } from '../HOCs/withProductGuard';
import i18nInstance from '../i18n';
import { THEME_MODES, THEME_MODS_LIST } from '../themes/common';
import { IWithPaywallStateProps } from '../HOCs/withPaywallState';
import { IWithSubscriptionStateProps } from '../HOCs/withSubscriptionState';
import { SEARCH_PARAMS } from '../constants/searchParams';
import { useSettingsStore } from '../store';
import { useLogger } from '../hooks/useLogger';
import { includes } from '../helpers/includes';

const themes = {
  [PRODUCT_NAMES.DANCE]: danceThemes,
  [PRODUCT_NAMES.FIT]: fitThemes,
  [PRODUCT_NAMES.WALK]: walkThemes,
  [PRODUCT_NAMES.MAN]: manThemes,
  [PRODUCT_NAMES.KETO]: ketoThemes,
  [PRODUCT_NAMES.FASTING]: fastingThemes,
  [PRODUCT_NAMES.VEGAN]: veganThemes,
};

const defaultThemeValues = {
  [PRODUCT_NAMES.DANCE]: THEME_MODES.LIGHT,
  [PRODUCT_NAMES.FIT]: THEME_MODES.LIGHT,
  [PRODUCT_NAMES.WALK]: THEME_MODES.LIGHT,
  [PRODUCT_NAMES.MAN]: THEME_MODES.DARK,
  [PRODUCT_NAMES.KETO]: THEME_MODES.LIGHT,
  [PRODUCT_NAMES.FASTING]: THEME_MODES.LIGHT,
  [PRODUCT_NAMES.VEGAN]: THEME_MODES.LIGHT,
};

const LangProvider: FC = () => {
  const logger = useLogger();
  const { i18n } = useTranslation();

  useEffect(() => {
    logger.setContext({ lang: i18n.language });
  }, [i18n.language]);

  return null;
};

export type CommonLayoutProps = IProductPageParams & (IWithPaywallStateProps | IWithSubscriptionStateProps);

const CommonDivkitLayout: FC<CommonLayoutProps> = ({ isLoading, productName }) => {
  const [i18n, set18n] = useState<i18nBase>();
  const [searchParams] = useSearchParams();
  const { setForceMissedKeysUpdate } = useSettingsStore((state) => ({
    setForceMissedKeysUpdate: state.setForceMissedKeysUpdate,
  }));
  const themeQueryParam = searchParams.get(SEARCH_PARAMS.THEME);
  const forceMissedKeysUpdate = searchParams.has(SEARCH_PARAMS.FORCE_MISSED_KEYS_UPDATE);
  const logger = useLogger();

  const resolveI18n = async () => {
    set18n(await i18nInstance(productName));
  };

  useEffect(() => {
    resolveI18n();
  }, []);

  const theme = useMemo(() => {
    const productThemes = themes[productName];
    if (themeQueryParam && includes(THEME_MODS_LIST, themeQueryParam)) {
      logger.setContext({ theme: themeQueryParam });
      return productThemes[themeQueryParam];
    }
    const defaultTheme = defaultThemeValues[productName];
    logger.setContext({ theme: defaultTheme });
    return productThemes[defaultTheme];
  }, [themeQueryParam]);

  useEffect(() => {
    if (forceMissedKeysUpdate) {
      setForceMissedKeysUpdate(true);
    } else {
      setForceMissedKeysUpdate(false);
    }
  }, [forceMissedKeysUpdate]);

  return (
    <I18nextProvider i18n={i18n as i18nBase}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <LangProvider />
            <Outlet />
          </>
        )}
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default CommonDivkitLayout;
