import React from 'react';
import { Outlet } from 'react-router';
import { Container } from '@mui/system';
import { ProvidersTree } from '../providers';
import { SPACINGS } from '../constants/spacings';
import '@divkitframework/divkit/dist/client.css';

const DivkitLayout = () => {
  return (
    <ProvidersTree>
      <Container maxWidth='xs' sx={{ maxWidth: `${SPACINGS.MAX_SCREEN_WIDTH_PX} !important` }} disableGutters>
        <Outlet />
      </Container>
    </ProvidersTree>
  );
};

export default DivkitLayout;
