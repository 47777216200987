import type { CommonTypes } from '@organicapps/organictypes';

export interface InterceptorManagerOpts {
  initRequestInterceptors?: RequestInterceptor[];
  initResponseInterceptors?: ResponseInterceptor[];
}

interface InterceptorOpts {
  once?: boolean;
}

interface RequestInterceptorOpts extends InterceptorOpts {}

interface ResponseInterceptorOpts extends InterceptorOpts {}

export type HttpClientHandler<T> = (request: T) => CommonTypes.CanPromise<T>;

abstract class Interceptor<T extends RequestInit | Response> {
  readonly once: boolean;

  protected constructor(opts?: InterceptorOpts) {
    this.once = opts?.once ?? false;
  }

  protected abstract handler: (data: T) => CommonTypes.CanPromise<T>;

  abstract apply(data: T): Promise<ReturnType<HttpClientHandler<T>>>;
}

export class RequestInterceptor extends Interceptor<RequestInit> {
  protected readonly handler: HttpClientHandler<RequestInit>;

  constructor(handler: HttpClientHandler<RequestInit>, opts?: RequestInterceptorOpts) {
    super(opts);
    this.handler = handler;
  }

  async apply(request: RequestInit): Promise<ReturnType<HttpClientHandler<RequestInit>>> {
    return this.handler(request);
  }
}

export class ResponseInterceptor extends Interceptor<Response> {
  protected readonly handler: HttpClientHandler<Response>;

  constructor(handler: HttpClientHandler<Response>, opts?: ResponseInterceptorOpts) {
    super(opts);
    this.handler = handler;
  }

  async apply(response: Response): Promise<ReturnType<HttpClientHandler<Response>>> {
    return this.handler(response);
  }
}

export class InterceptorManager {
  private _requestInterceptors: RequestInterceptor[];

  private _responseInterceptors: ResponseInterceptor[];

  constructor(initInterceptors?: InterceptorManagerOpts) {
    this._requestInterceptors = initInterceptors?.initRequestInterceptors ?? [];
    this._responseInterceptors = initInterceptors?.initResponseInterceptors ?? [];
  }

  addRequestInterceptor(interceptor: RequestInterceptor): void {
    this._requestInterceptors.push(interceptor);
  }

  addResponseInterceptor(interceptor: ResponseInterceptor): void {
    this._responseInterceptors.push(interceptor);
  }

  get requestInterceptors(): RequestInterceptor[] {
    return this._requestInterceptors;
  }

  get responseInterceptors(): ResponseInterceptor[] {
    return this._responseInterceptors;
  }

  removeRequestInterceptor(interceptor: RequestInterceptor): void {
    this._requestInterceptors = this._requestInterceptors.filter((i) => i !== interceptor);
  }

  removeResponseInterceptor(interceptor: ResponseInterceptor): void {
    this._responseInterceptors = this._responseInterceptors.filter((i) => i !== interceptor);
  }

  async applyRequestInterceptors(request: RequestInit): Promise<RequestInit> {
    if (!this._requestInterceptors.length) {
      return request;
    }

    let result = request;

    for (let i = 0; i < this._requestInterceptors.length; i++) {
      result = await this._requestInterceptors[i].apply(result);
    }

    return result;
  }

  async applyResponseInterceptors(response: Response): Promise<Response> {
    if (!this._responseInterceptors.length) {
      return response;
    }

    let result = response;

    for (let i = 0; i < this._responseInterceptors.length; i++) {
      result = await this._responseInterceptors[i].apply(result);
    }

    return result;
  }

  clearOneTimeInterceptors<T extends RequestInterceptor | ResponseInterceptor>(interceptors: T[]) {
    // ToDo: implement
  }
}
