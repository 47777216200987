import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import {
  CURRENCIES,
  INTERVALS,
  PAYMENT_PROVIDERS,
  PAYMENT_SYSTEMS,
  PAYWALL_TYPE,
  PAYWALL_VERSION,
  Paywalls,
} from '@organicapps/organictypes';
import logger from './middlewares/loggerMiddleware';
import { ENV, NODE_ENV } from '../constants/envKeysMapper';

interface IPaywallsState extends Paywalls.IPaywallData {
  setPaywallData: (data: Paywalls.IPaywallData) => void;
  showSpecialPrices: boolean;
  setShowSpecialPrices: (show: boolean) => void;
  selectedPaywallList: Paywalls.IPaywallList | null;
  setSelectedPaywallList: (paywallList: Paywalls.IPaywallList) => void;
}

export const usePaywallsStore = create<IPaywallsState>()(
  logger(
    devtools(
      persist(
        (set, get) => ({
          _id: '',
          showSpecialPrices: false,
          name: '',
          slug: '',
          isDefault: false,
          type: '',
          landingId: '',
          currency: CURRENCIES.USD,
          layouts: {
            modalLayoutId: '',
          },
          settings: {
            variant: '',
            paywallType: PAYWALL_TYPE.OFFER,
            version: PAYWALL_VERSION.V1,
            passOnboarding: false,
            onlyVisaEnabled: false,
            onlyMasterCardEnabled: false,
            termsClickable: false,
            termsPopup: false,
            requiredBillingAddress: false,
            paymentSystems: [{ provider: PAYMENT_PROVIDERS.CARD, system: PAYMENT_SYSTEMS.STRIPE }],
            secondaryPaywallDiscount: 0,
            secondaryPaywallTimer: 0,
          },
          list: [
            {
              position: 0,
              translations: {
                offerTitle: '',
                priceTitle: '',
                priceDescription: '',
              },
              price: {
                _id: '',
                currency: '',
                price: 0,
                priceUnit: 0,
                interval: INTERVALS.MONTH,
                intervalCount: 0,
                isTrialUsed: false,
                priceDetails: {
                  perMonth: 0,
                  perWeek: 0,
                  perDay: 0,
                },
                createdAt: new Date(),
                updatedAt: new Date(),
              },
              options: {
                soldOut: true,
                lifetime: true,
                upsells: false,
              },
              defaultSelected: true,
            },
          ],
          selectedPaywallList: null,
          setPaywallData: (data: Paywalls.IPaywallData) => {
            const store = get();
            set(
              {
                setPaywallData: store.setPaywallData,
                setShowSpecialPrices: store.setShowSpecialPrices,
                setSelectedPaywallList: store.setSelectedPaywallList,
                ...data,
              },
              true,
              'paywalls/setData'
            );
          },
          setShowSpecialPrices: (show: boolean) =>
            set({ showSpecialPrices: show }, false, 'paywalls/setShowSpecialPrices'),
          setSelectedPaywallList: (paywallList: Paywalls.IPaywallList) =>
            set({ selectedPaywallList: paywallList }, false, 'paywalls/setSelectedPaywallList'),
        }),
        {
          name: 'paywallsState',
          getStorage: () => sessionStorage,
        }
      ),
      { name: 'usePaywallsStore', enabled: NODE_ENV === ENV.DEVELOPMENT }
    ),
    '[paywallStore]'
  )
);
