import React, { ComponentType, FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParams';
import { PAGES } from '../constants/pages';
import { useRouterWrapper } from '../hooks/useRouterWrapper';

const withSearchParamsGuard = <P extends object>(
  Component: ComponentType<P>,
  requiredSearchParams: SEARCH_PARAMS[]
): FC<P> => {
  return (props: P) => {
    const [searchParams] = useSearchParams();
    const go = useRouterWrapper();
    const [isRouteValid, setIsRouteValid] = useState<boolean>(false);

    useEffect(() => {
      if (!requiredSearchParams.every((param) => searchParams.has(param))) {
        go({ routeChangedPart: { page: PAGES.NOT_FOUND }, replace: true });
      } else {
        setIsRouteValid(true);
      }
    }, [searchParams, requiredSearchParams]);

    return <>{isRouteValid && <Component {...props} />}</>;
  };
};

export default withSearchParamsGuard;
