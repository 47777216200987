import type { ResponseType } from './types';

export class CustomResponse<T> extends Response {
  private readonly responseType?: ResponseType;

  constructor(res: Response, responseType?: ResponseType) {
    super(res.body, res);
    this.responseType = responseType;
    Object.assign(this, res);
  }

  get data() {
    return (async () => {
      try {
        const resFunc = this?.[this.responseType ?? 'json'].bind(this);
        if (typeof resFunc === 'function') {
          return (await resFunc()) as T;
        }
        throw new Error('ResponseType Parser is not implemented');
      } catch (e) {
        return null;
      }
    })();
  }
}
