import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Link } from '@mui/material';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { Products } from '@organicapps/organictypes';
import { useTranslation } from 'react-i18next';
import { PAGES } from '../../../constants/pages';
import useElementOnScreen from '../../../hooks/useElementOnScreen';
import Logo from '../../atoms/Logo/Logo';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 0),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  img: {
    width: '130px',
  },
  '&.hidden + .sticky': {
    '.special-offer-container': {
      padding: theme.spacing(2),
    },
    '.special-offer-timer': {
      marginTop: theme.spacing(1),
    },
    '.special-offer-title': {
      display: 'none',
    },
    '.special-offer-subtitle': {
      marginTop: 0,
    },
    '.special-offer-gift': {
      width: '100px',
      bottom: '-25px',
      left: '-16px',
    },
    '.special-offer-curly1': {
      top: 0,
    },
  },
}));

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HelpLink = styled(Link)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: '10px',
  color: theme.palette.secondary.main,
}));

interface IProps {
  productName: Products.Names;
}

const Header: FC<IProps> = ({ productName }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [isIntercomOpened, setIsIntercomOpened] = useState(false);
  const isIntercomHandlerAddedRef = useRef<boolean>(false);

  const toggleIntercom = () => {
    if (isIntercomOpened) {
      window?.Intercom('hide');
      setIsIntercomOpened(false);
    } else {
      window?.Intercom('show');
      setIsIntercomOpened(true);
    }
  };

  useEffect(() => {
    if (window?.Intercom && !isIntercomHandlerAddedRef.current) {
      window?.Intercom('onHide', () => {
        setIsIntercomOpened(false);
      });
      isIntercomHandlerAddedRef.current = true;
    }
  }, [window?.Intercom]);

  const hideHeader = pathname.includes(`/${PAGES.SUCCESS}`);

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  });

  if (hideHeader) {
    return null;
  }

  return (
    <StyledBox ref={containerRef} className={isVisible ? undefined : 'hidden'}>
      <StyledWrapper>
        <Logo productName={productName} />
      </StyledWrapper>
      <HelpLink underline='none' href='#' onClick={toggleIntercom}>
        {t('header.help')}
      </HelpLink>
    </StyledBox>
  );
};

export default Header;
