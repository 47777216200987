import { createTheme } from '@mui/material/styles';
import { DARK_THEME_COLORS, GREY_THEME_COLORS, LIGHT_THEME_COLORS } from '../constants/colors';
import { darkTheme, greyTheme, lightTheme, THEME_MODES } from './common';

const walkLightTheme = createTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    primary: {
      main: LIGHT_THEME_COLORS.PRIMARY_WALK,
      dark: LIGHT_THEME_COLORS.PRIMARY_WALK_DARK,
      light: LIGHT_THEME_COLORS.PRIMARY_WALK_LIGHT,
    },
    secondary: {
      main: LIGHT_THEME_COLORS.SECONDARY_WALK,
      light: LIGHT_THEME_COLORS.SECONDARY_WALK_LIGHT,
      dark: LIGHT_THEME_COLORS.SECONDARY_WALK_DARK,
    },
    gradient: {
      main: LIGHT_THEME_COLORS.SPECIAL_OFFER_WALK_GRADIENT,
    },
  },
});

const walkGreyTheme = createTheme({
  ...greyTheme,
  palette: {
    ...greyTheme.palette,
    primary: {
      main: GREY_THEME_COLORS.PRIMARY_WALK,
      dark: GREY_THEME_COLORS.PRIMARY_WALK_DARK,
      light: GREY_THEME_COLORS.PRIMARY_WALK_LIGHT,
    },
    secondary: {
      main: GREY_THEME_COLORS.SECONDARY_WALK,
      light: GREY_THEME_COLORS.SECONDARY_WALK_LIGHT,
      dark: GREY_THEME_COLORS.SECONDARY_WALK_DARK,
    },
    gradient: {
      main: GREY_THEME_COLORS.SPECIAL_OFFER_WALK_GRADIENT,
    },
  },
});

const walkDarkTheme = createTheme({
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    primary: {
      main: DARK_THEME_COLORS.PRIMARY_WALK,
      dark: DARK_THEME_COLORS.PRIMARY_WALK_DARK,
      light: DARK_THEME_COLORS.PRIMARY_WALK_LIGHT,
    },
    secondary: {
      main: DARK_THEME_COLORS.SECONDARY_WALK,
      light: DARK_THEME_COLORS.SECONDARY_WALK_LIGHT,
      dark: DARK_THEME_COLORS.SECONDARY_WALK_DARK,
    },
    gradient: {
      main: DARK_THEME_COLORS.SPECIAL_OFFER_WALK_GRADIENT,
    },
  },
});

export const walkThemes = {
  [THEME_MODES.DARK]: walkDarkTheme,
  [THEME_MODES.LIGHT]: walkLightTheme,
  [THEME_MODES.GREY]: walkGreyTheme,
};
