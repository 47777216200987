import ActionsProcessorFactory from './factory';
import type { IActionProcessor, IActionsProcessorOptions } from './types';

export type { IActionsProcessorOptions, ActionsProcessor, IActionProcessor } from './types';

export { default as PaywallCtaClickActionProcessor } from './processors/paywall-cta-click';
export { default as PaywallCtaScrollActionProcessor } from './processors/paywall-cta-scroll';
export { default as ActiveStateToggleActionProcessor } from './processors/active-state-toggle';

export default function factory(
  actionsProcessors: IActionProcessor[],
  opts?: IActionsProcessorOptions
): InstanceType<typeof ActionsProcessorFactory> {
  return new ActionsProcessorFactory(actionsProcessors, opts);
}
