import React, { FC, forwardRef, useEffect } from 'react';
import Box, { BoxProps } from '@mui/material/Box/Box';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import { Paywalls } from '@organicapps/organictypes';
import { usePaywallsStore, useUserStore } from '../../../store';
import { IPriceListItemProps } from '../../molecules/PriceListItem/v1/PriceListItem';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { EVENT_TYPES, EVENTS } from '../../../constants/analytics';
import { SEARCH_PARAMS } from '../../../constants/searchParams';

const ab = React.lazy(() => import('../../molecules/PriceListItem/ab/PriceListItem'));
const v3 = React.lazy(() => import('../../molecules/PriceListItem/v3/PriceListItem'));
const v2 = React.lazy(() => import('../../molecules/PriceListItem/v2/PriceListItem'));
const v1 = React.lazy(() => import('../../molecules/PriceListItem/v1/PriceListItem'));
const upsells = React.lazy(() => import('../../molecules/PriceListItem/upsells/PriceListItem'));
const upsellsV2 = React.lazy(() => import('../../molecules/PriceListItem/upsells/PriceListItemV2'));

const Container = styled(Box)(({ theme }) => ({
  '& > div': {
    marginTop: theme.spacing(5),
  },
}));

const routes: { [key: string]: FC<IPriceListItemProps> } = {
  1: v1,
  2: v2,
  3: v3,
  ab,
  upsells,
  upsellsV2,
};

interface IProps extends BoxProps {
  variant?: string;
  customList?: Paywalls.IPaywallList[];
  insertAfter?: {
    index: number;
    node: React.ReactNode;
  };
}

const PriceList: FC<IProps> = forwardRef(({ variant, customList, insertAfter, ...props }, ref) => {
  const {
    list,
    secondaryList,
    showSpecialPrices,
    setSelectedPaywallList,
    selectedPaywallList,
    slug,
    paywallId,
    paywallVariant,
    paywallVersion,
  } = usePaywallsStore((state) => ({
    secondaryList: state?.secondaryList,
    list: state.list,
    slug: state.slug,
    showSpecialPrices: state.showSpecialPrices,
    setSelectedPaywallList: state.setSelectedPaywallList,
    selectedPaywallList: state.selectedPaywallList,
    settings: state.settings,
    paywallId: state._id,
    paywallVariant: state.settings.variant,
    paywallVersion: state.settings.version,
  }));

  const { localCurrency, exchangeRate } = useUserStore((state) => ({
    localCurrency: state.localCurrency,
    exchangeRate: state.exchangeRate,
  }));

  const { logEvent } = useAnalytics();
  const [searchParams] = useSearchParams();

  let listToDisplay = showSpecialPrices && secondaryList?.length ? secondaryList : list;
  let PriceListItem = routes[paywallVariant] || routes[1];

  if (customList?.length && variant) {
    listToDisplay = customList;
    PriceListItem = routes[variant];
  }

  const handleSelectPaywallList = (paywallList: Paywalls.IPaywallList) => {
    setSelectedPaywallList(paywallList);
    logEvent({
      name: EVENTS.SELECT_SUBSCRIPTION,
      type: EVENT_TYPES.ACTION,
      properties: {
        Price: paywallList.price.price,
        Interval: paywallList.price.interval,
        'Interval Count': paywallList.price.intervalCount,
        Description: slug,
        internalId: paywallList.price._id,
        currency: paywallList.price.currency,
        localCurrency,
        exchangeRate,
        paywallVariant,
        paywallVersion,
        trial: !!paywallList.price?.trial,
        trialPrice: paywallList.price.trial?.price,
        trialInterval: paywallList.price.trial?.interval,
        trialIntervalCount: paywallList.price.trial?.intervalCount,
        priceId: paywallList.price._id,
        oldPrice: !!paywallList?.priceDetails,
        oldPriceDiscount: paywallList?.priceDetails?.discount,
        oldPriceOriginalPrice: paywallList?.priceDetails?.originalPrice,
        popupVariant: slug,
        paywallId,
      },
    });
  };

  useEffect(() => {
    let notSoldOut: Paywalls.IPaywallList | null = null;
    let defaultSelected: Paywalls.IPaywallList | null = null;
    if (listToDisplay) {
      const selectedPriceId = searchParams.get(SEARCH_PARAMS.SELECTED_PRICE_ID);
      if (selectedPriceId) {
        const selected = listToDisplay.find((item) => item.price._id === selectedPriceId);
        if (selected) {
          setSelectedPaywallList(selected);
          return;
        }
      }
      listToDisplay.forEach((item) => {
        const isNotSoldOut = !item.options.soldOut;
        if (isNotSoldOut && !notSoldOut) {
          notSoldOut = item;
        }
        if (isNotSoldOut && item.defaultSelected) {
          defaultSelected = item;
        }
      });
      if (defaultSelected) {
        setSelectedPaywallList(defaultSelected);
      } else if (notSoldOut) {
        setSelectedPaywallList(notSoldOut);
      }
    }
  }, [listToDisplay, setSelectedPaywallList, searchParams]);

  return (
    <Container ref={ref} {...props}>
      {[...listToDisplay]
        .sort((a, b) => a.position - b.position)
        .map((item, index) => (
          <div key={index}>
            <PriceListItem
              paywallListData={item}
              onSelectPaywallList={handleSelectPaywallList}
              checked={item.position === selectedPaywallList?.position}
            />
            {insertAfter?.index === index ? insertAfter?.node : null}
          </div>
        ))}
    </Container>
  );
});

export default PriceList;
