import i18n from 'i18next';
import type { Logger } from 'modules/logger';
import { DivExtension, DivExtensionContext, IExtensionBuilder } from './types';
import { EXTENSION_NAMES } from './constants';
import { priceFormat } from '../../../utils/I18nFormatters/priceFormat';

class LocalizationExtension implements DivExtension {
  private readonly key: string = '';

  private readonly reRenderOnVariable: string | null = null;

  static logger?: Logger;

  constructor(props: object) {
    this.addLocalizationFormatters();

    if (Object.hasOwn(props, 'key')) {
      this.key = (props as { key: string }).key;
    }

    if (Object.hasOwn(props, 'reRenderOnVariable')) {
      this.reRenderOnVariable = (props as { reRenderOnVariable: string }).reRenderOnVariable;
    }
  }

  async mountView(node: HTMLElement, context: DivExtensionContext) {
    if (i18n.exists(this.key)) {
      this.attachLocalization(node, context);
      if (this.reRenderOnVariable && context.variables.has(this.reRenderOnVariable)) {
        context.variables.get(this.reRenderOnVariable)?.subscribe(() => {
          this.attachLocalization(node, context);
        });
      }
    }
  }

  private attachLocalization(node: HTMLElement, context: DivExtensionContext) {
    const [element] = node.children;
    const segments = this.splitIntoSegments(i18n.t(this.key, this.getContextVariables(context)));
    Array.prototype.map.call(element.children, (childElement: Element, index: number) => {
      if (segments[index]) {
        // eslint-disable-next-line no-param-reassign
        childElement.innerHTML = segments[index];
      }
    });
  }

  private getContextVariables(context: DivExtensionContext) {
    const values = Array.from(context.variables);

    return values.reduce((acc, [name, variable]) => {
      return {
        ...acc,
        [variable.getName()]: variable.getValue(),
      };
    }, {});
  }

  private addLocalizationFormatters() {
    i18n.services.formatter?.add('priceFormat', priceFormat);
  }

  private splitIntoSegments(input: string): string[] {
    const numericTagRegex = /<\d+>(.*?)<\/\d+>/g;
    return input.split(numericTagRegex).filter((segment) => !!segment.trim());
  }
}

export default class LocalizationExtensionBuilder implements IExtensionBuilder {
  readonly name = EXTENSION_NAMES.LOCALIZATION;

  readonly extension: IExtensionBuilder['extension'];

  constructor() {
    this.extension = LocalizationExtension;
  }
}
