import React, { ComponentType, FC, useEffect, useState } from 'react';
import { usePaywallsStore, useUserStore } from '../store';
import { useRouterWrapper } from '../hooks/useRouterWrapper';

const withIsPayedRouteGuard = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return (props: P) => {
    const go = useRouterWrapper();

    const [isRouteValid, setIsRouteValid] = useState<boolean>(false);

    const { paywallType } = usePaywallsStore((state) => ({
      paywallType: state.settings.paywallType,
    }));
    const { isPayed } = useUserStore((state) => ({
      isPayed: state.paid,
    }));

    useEffect(() => {
      if (!isPayed) {
        go({ routeChangedPart: { page: paywallType } });
      } else {
        setIsRouteValid(true);
      }
    }, [isPayed]);

    return <>{isRouteValid && <Component {...props} />}</>;
  };
};

export default withIsPayedRouteGuard;
