import type { Logger } from 'modules/logger';
import type { ICustomComponentsOptions } from './types';
import { CUSTOM_COMPONENTS, ICustomComponent } from './components';

export default class CustomComponentsFactory {
  private readonly logger?: Logger;

  readonly components: Map<CUSTOM_COMPONENTS, ICustomComponent> = new Map();

  constructor(customComponents: ICustomComponent[], opts?: ICustomComponentsOptions) {
    this.logger = opts?.logger;
    customComponents.forEach((item) => {
      this.components.set(item.binding, item);
    });
    this.logger?.logInfo({ components: [...this.components.keys()] }, 'customComponentsInitialized');
  }
}
