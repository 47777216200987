import { PRODUCT_NAMES } from '@organicapps/organictypes';
import { PLATFORM } from './platforms';

export enum LINKS {
  TERMS_OF_SERVICE = 'https://myorganicapps.com/en/terms/terms-of-service',
  PRIVACY_POLICY = 'https://myorganicapps.com/en/terms/privacy-policy',
  MONEY_BACK_POLICY = 'https://myorganicapps.com/en/terms/money-back-policy',
  CALORIE_TRACKER = 'https://tracker.myorganicapps.com/',
  WEBSITE = 'https://myorganicapps.com/',
  SUBSCRIPTION_TERMS = 'https://myorganicapps.com/terms/subscription-terms',
}

export const SUPPORT_LINKS = {
  [PRODUCT_NAMES.DANCE]: 'support@dance.myorganicapps.com',
  [PRODUCT_NAMES.FIT]: 'support@myorganicapps.com',
  [PRODUCT_NAMES.MAN]: 'support@man.myorganicapps.com',
  [PRODUCT_NAMES.WALK]: 'support@walk.myorganicapps.com',
  [PRODUCT_NAMES.KETO]: 'support@meals.myorganicapps.com',
  [PRODUCT_NAMES.FASTING]: 'support@meals.myorganicapps.com',
  [PRODUCT_NAMES.VEGAN]: 'support@meals.myorganicapps.com',
};

export const APPS_LINKS = {
  [PRODUCT_NAMES.DANCE]: 'https://apps.apple.com/by/app/organic-dance-weight-loss-app/id1535972173',
  [PRODUCT_NAMES.FIT]: 'https://apps.apple.com/by/app/organic-fit-workout-for-women/id1188810254',
  [PRODUCT_NAMES.WALK]: 'https://apps.apple.com/sg/app/organic-walk-weight-loss-app/id1442823191',
  [PRODUCT_NAMES.MAN]: 'https://apps.apple.com/by/app/organic-man/id6443817461',
  [PRODUCT_NAMES.KETO]: 'https://apps.apple.com/us/app/organic-meals-fasting-keto/id1593030112',
  [PRODUCT_NAMES.FASTING]: 'https://apps.apple.com/us/app/organic-meals-fasting-keto/id1593030112',
  [PRODUCT_NAMES.VEGAN]: 'https://apps.apple.com/us/app/organic-meals-fasting-keto/id1593030112',
};

export const GOOGLE_PLAY_LINKS = {
  [PRODUCT_NAMES.DANCE]: 'https://play.google.com/store/apps/details?id=com.organicapps.dance',
  [PRODUCT_NAMES.FIT]: 'https://play.google.com/store/apps/details?id=com.organicapps.fit',
  [PRODUCT_NAMES.MAN]: 'https://play.google.com/store/apps/details?id=com.organicapps.man',
  [PRODUCT_NAMES.WALK]: 'https://play.google.com/store/apps/details?id=com.organicapps.dance',
  [PRODUCT_NAMES.KETO]: 'https://play.google.com/store/apps/details?id=com.organicapps.dance',
  [PRODUCT_NAMES.FASTING]: 'https://play.google.com/store/apps/details?id=com.organicapps.dance',
  [PRODUCT_NAMES.VEGAN]: 'https://play.google.com/store/apps/details?id=com.organicapps.dance',
};

export const APPLE_SIGN_IN_LINKS = {
  [PRODUCT_NAMES.DANCE]: 'com.myorganicapps.organicdance.webauth',
  [PRODUCT_NAMES.FIT]: 'com.myorganicapps.organicfit.webauth',
  [PRODUCT_NAMES.WALK]: 'com.myorganicapps.organicwalk.webauth',
  [PRODUCT_NAMES.MAN]: 'com.myorganicapps.organicman.webauth',
  [PRODUCT_NAMES.KETO]: 'com.myorganicapps.organicmeals.webauth',
  [PRODUCT_NAMES.FASTING]: 'com.myorganicapps.organicmeals.webauth',
  [PRODUCT_NAMES.VEGAN]: 'com.myorganicapps.organicmeals.webauth',
};

export const INSTANT_APP_LINK = {
  [PLATFORM.APPLE]: {
    [PRODUCT_NAMES.DANCE]: '',
    [PRODUCT_NAMES.FIT]: '',
    [PRODUCT_NAMES.WALK]: '',
    [PRODUCT_NAMES.MAN]: 'https://appclip.apple.com/id?p=com.myorganicapps.organicman.Clip',
    [PRODUCT_NAMES.KETO]: '',
    [PRODUCT_NAMES.FASTING]: '',
    [PRODUCT_NAMES.VEGAN]: '',
  },
  [PLATFORM.GOOGLE]: {
    [PRODUCT_NAMES.DANCE]: '',
    [PRODUCT_NAMES.FIT]: '',
    [PRODUCT_NAMES.WALK]: '',
    [PRODUCT_NAMES.MAN]: '',
    [PRODUCT_NAMES.KETO]: '',
    [PRODUCT_NAMES.FASTING]: '',
    [PRODUCT_NAMES.VEGAN]: '',
  },
};
