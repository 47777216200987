import React from 'react';

const Man = () => {
  return (
    <>
      <path
        d='M7.80602 16.0977C6.60233 16.0977 5.43388 16.3616 4.43673 16.9284C3.36459 17.5379 2.48594 18.4884 1.83883 19.5669C1.28375 20.5384 0.867432 21.7873 0.867432 23.0362C2.11638 23.0362 3.36532 22.6199 4.33672 22.0648C6.4183 20.8159 7.80602 18.7343 7.80602 16.0977Z'
        fill='#4151E0'
      />
      <path
        d='M7.80602 16.0977C6.60233 16.0977 5.43388 16.3616 4.43673 16.9284C3.36459 17.5379 2.48594 18.4884 1.83883 19.5669C1.28375 20.5384 0.867432 21.7873 0.867432 23.0362C2.11638 23.0362 3.36532 22.6199 4.33672 22.0648C6.4183 20.8159 7.80602 18.7343 7.80602 16.0977Z'
        fill='black'
        fillOpacity='0.25'
      />
      <path
        d='M14.7446 2.22046H7.80602C5.30813 2.22046 3.08778 3.60818 1.83883 5.68975C1.28375 6.66115 0.867432 7.9101 0.867432 9.15904V16.0976C0.867432 14.8487 1.28375 13.5997 1.83883 12.6283C2.39392 11.6569 3.36532 10.6855 4.33672 10.1304C5.30813 9.57536 6.55707 9.15904 7.80602 9.15904C10.3039 9.15904 12.5243 7.77133 13.7732 5.68975C14.3283 4.71835 14.7446 3.4694 14.7446 2.22046Z'
        fill='#00CFBD'
      />
      <path
        d='M7.80602 9.15894C6.55707 9.15894 5.30813 9.57525 4.33672 10.1303C3.36532 10.6854 2.39392 11.6568 1.83883 12.6282C1.28375 13.5996 0.867432 14.8486 0.867432 16.0975V23.0361C0.867432 21.7872 1.28375 20.5382 1.83883 19.5668C3.08778 17.4852 5.16935 16.0975 7.80602 16.0975C7.80602 16.0975 8.77742 16.0975 9.19373 15.9587C11.1365 15.5424 12.8018 14.2935 13.7732 12.6282C14.3283 11.6568 14.7446 10.4079 14.7446 9.15894H7.80602Z'
        fill='#4151E0'
      />
      <path
        className='title'
        d='M35.6546 5.05796H33.3495L29.0585 14.3493L24.7497 5.05796H22.4446V17.4701H24.8383V10.3065L28.0478 17.4701H30.0337L33.2608 10.271V17.4701H35.6546V5.05796Z'
        fill='white'
      />
      <path
        className='title'
        d='M41.7407 8.09006C39.6306 8.09006 37.1659 9.6859 37.1659 12.8599C37.1659 16.1047 39.6306 17.6474 41.7407 17.6474C43.4252 17.6474 44.4004 16.6367 44.4714 16.1402V17.4701H46.7587V8.24964H44.4714V9.45539C44.4004 9.15395 43.4252 8.09006 41.7407 8.09006ZM42.0421 10.1115C43.372 10.1115 44.5777 11.0335 44.5777 12.8599C44.5777 14.7039 43.372 15.626 42.0421 15.626C40.7123 15.626 39.3824 14.6862 39.3824 12.8599C39.3824 11.0512 40.7123 10.1115 42.0421 10.1115Z'
        fill='white'
      />
      <path
        className='title'
        d='M53.7788 8.10779C51.9347 8.10779 50.924 9.54405 50.7467 10.0583V8.24964H48.4593V17.4701H50.7467V12.1861C50.9417 11.4413 51.8283 10.0937 53.2468 10.0937C54.3107 10.0937 55.0023 10.6966 55.0023 12.2924V17.4701H57.2719V12.1506C57.2719 9.26034 55.5342 8.10779 53.7788 8.10779Z'
        fill='white'
      />
      <path
        className='title'
        d='M71.8212 7.16802V5.05796H64.0902V17.4701H66.484V12.2215H70.8814V10.2356H66.484V7.16802H71.8212Z'
        fill='white'
      />
      <path
        className='title'
        d='M75.4961 4.93384H73.2087V6.88431H75.4961V4.93384ZM75.4961 8.24964H73.2087V17.4701H75.4961V8.24964Z'
        fill='white'
      />
      <path
        className='title'
        d='M81.1055 15.5728C80.5026 15.5728 80.3608 15.1472 80.3608 14.6862V10.1292H82.3112V8.24964H80.3608V6.08639H78.0734V8.24964H76.7967V10.1292H78.0734V14.7217C78.0734 17.0977 79.2082 17.5765 80.5204 17.5765C81.3715 17.5765 81.9389 17.4523 82.329 17.2573V15.36C81.9389 15.5019 81.602 15.5728 81.1055 15.5728Z'
        fill='white'
      />
    </>
  );
};

export default Man;
