import { Products } from '@organicapps/organictypes';
import { API_URLS, GATEWAY_API_URL } from '../constants/envKeysMapper';
import type { Logger } from '../modules/logger';
import { InterceptorManager, RequestInterceptor, ResponseInterceptor } from './interceptorManager';
import { CustomRequest, IBaseApiOptions, RequestMethod } from './types';
import { DefaultConfig, RequestConfig } from './config';
import { CustomResponse } from './customResponse';

type FetchErrorData = Partial<Omit<Response, 'headers' | 'body'>> & {
  headers: Record<string, string> | null;
  body: any;
};
export class ApiError extends Error {
  readonly cause: FetchErrorData | string;

  constructor(message: string, cause?: FetchErrorData) {
    super(message);
    this.cause = cause || message;
  }
}

export class FetchError extends Error {
  data: FetchErrorData;

  response: Response;

  constructor(response: Response) {
    super();
    this.response = response;
    this.data = {} as FetchErrorData;
  }

  async parse() {
    this.data = {
      headers: this.response?.headers ? Object.fromEntries(this.response?.headers) : null,
      status: this.response?.status,
      statusText: this.response?.statusText ?? null,
      url: this.response?.url,
      body: await this.response.json(),
    };
    this.message =
      this.data.body?.message ??
      (this.data.body ? JSON.stringify(this.data.body) : 'Something went wrong. Please try again.');
    return this;
  }
}

export default class BaseApi {
  private readonly logger?: Logger;

  private readonly defaultConfig: DefaultConfig;

  private readonly globalInterceptorManager: InterceptorManager;

  constructor(productName: Products.Names, opts?: IBaseApiOptions) {
    this.logger = opts?.logger;
    this.defaultConfig = new DefaultConfig({
      // ToDo: TEMP ALTERNATIVE API COZ OF TWO BACKENDS
      baseURL: opts?.forceGatewayApiUrl ? GATEWAY_API_URL : API_URLS[productName],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-landing': productName,
      },
    });
    this.globalInterceptorManager = new InterceptorManager();
    this.globalInterceptorManager.addResponseInterceptor(
      new ResponseInterceptor(async (response) => {
        if (!response.ok) {
          const fetchError = new FetchError(response);
          const parsedError = await fetchError.parse();

          this?.logger?.logDebug(parsedError.data, `Fetch API Error: ${parsedError.message}`);

          throw fetchError;
        }
        return response;
      })
    );
  }

  private async request<T, D = any>(url: string, config: CustomRequest, data?: D) {
    try {
      const requestConfig = new RequestConfig(this.defaultConfig, config, url);
      const localInterceptorManager = new InterceptorManager({
        initRequestInterceptors: [...this.globalInterceptorManager.requestInterceptors],
        initResponseInterceptors: [...this.globalInterceptorManager.responseInterceptors],
      });

      localInterceptorManager.addRequestInterceptor(
        new RequestInterceptor((req) => {
          if (!data) {
            return req;
          }
          // ToDo: temp solution, think how to make it in more elegance way (maybe another abstraction)?
          if (
            !(data instanceof Blob) &&
            !(data instanceof FormData) &&
            !(data instanceof ArrayBuffer) &&
            !(data instanceof URLSearchParams) &&
            typeof data !== 'string' &&
            !(data instanceof ReadableStream) &&
            typeof data === 'object'
          ) {
            req.body = JSON.stringify(data);
          } else {
            req.body = data as BodyInit;
          }
          return req;
        })
      );

      const configWithRequestInterceptors = await localInterceptorManager.applyRequestInterceptors(requestConfig);

      const response = await fetch(requestConfig.url, configWithRequestInterceptors);

      const responseWithInterceptors = await localInterceptorManager.applyResponseInterceptors(response);

      // ToDo: maybe it's better to make it as interceptor
      return new CustomResponse<T>(responseWithInterceptors, requestConfig?.responseType) as T;
    } catch (error: any) {
      this.logger?.logError(error);
      throw new ApiError((error as Error)?.message, (error as FetchError)?.data);
    }
  }

  public getConfig() {
    return this.defaultConfig;
  }

  public addRequestInterceptor(interceptor: RequestInterceptor): void {
    this.globalInterceptorManager.addRequestInterceptor(interceptor);
  }

  public addResponseInterceptor(interceptor: ResponseInterceptor): void {
    this.globalInterceptorManager.addResponseInterceptor(interceptor);
  }

  public async post<T = any, R = CustomResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: CustomRequest
  ): Promise<R> {
    return this.request<R, D>(url, { ...config, method: RequestMethod.POST }, data);
  }

  public async patch<T = any, R = CustomResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: CustomRequest
  ): Promise<R> {
    return this.request<R, D>(url, { ...config, method: RequestMethod.PATCH }, data);
  }

  public async get<T = any, R = CustomResponse<T>, D = any>(url: string, config?: CustomRequest): Promise<R> {
    return this.request<R>(url, { ...config, method: RequestMethod.GET });
  }
}
