import React, { ComponentType, FC, useEffect, useState } from 'react';
import { PAGES } from 'constants/pages';
import { useUserStore } from '../store';
import { useRouterWrapper } from '../hooks/useRouterWrapper';

const withUpsellsPaidGuard = <P extends object>(Component: ComponentType<P>): FC<P> => {
  return (props: P) => {
    const go = useRouterWrapper();

    const [isRouteValid, setIsRouteValid] = useState<boolean>(false);

    const { upsellsPaid } = useUserStore((state) => ({
      upsellsPaid: state.upsellsPaid,
    }));

    useEffect(() => {
      if (upsellsPaid) {
        go({ routeChangedPart: { page: PAGES.SUCCESS } });
      } else {
        setIsRouteValid(true);
      }
    }, [upsellsPaid]);

    return <>{isRouteValid && <Component {...props} />}</>;
  };
};

export default withUpsellsPaidGuard;
