import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PAYWALL_TYPE } from '@organicapps/organictypes';
import { useLocation } from 'react-router-dom';
import CheckAnimated from '../../components/atoms/CheckAnimated/CheckAnimated';
import { usePaywallsStore } from '../../store';
import { PAGES } from '../../constants/pages';
import { useRouterWrapper, IRouteParamsToChange } from '../../hooks/useRouterWrapper';

const Container = styled(Box)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Success = () => {
  const { t } = useTranslation();
  const go = useRouterWrapper();
  const { state } = useLocation();

  const { paywallType, upsellsList } = usePaywallsStore((state) => ({
    paywallType: state.settings.paywallType,
    upsellsList: state.upsellsList,
  }));

  useEffect(() => {
    let nextPage: IRouteParamsToChange['page'] = paywallType === PAYWALL_TYPE.OFFER ? PAGES.REGISTER : PAGES.DOWNLOAD;

    // if Paywall has upsells
    if (upsellsList?.length && state?.prevPage !== PAGES.UPSELLS) {
      nextPage = PAGES.UPSELLS;
    }
    setTimeout(() => {
      go({
        routeChangedPart: { page: nextPage },
        replace: true,
      });
    }, 1000);
  }, []);

  return (
    <Container>
      <CheckAnimated />
      <Typography align='center' variant='h2' mt={9}>
        {t('success_purchase.title')}
      </Typography>
      <Typography align='center' variant='body1' mt={5}>
        {t('success_purchase.description')}
      </Typography>
    </Container>
  );
};

export default Success;
