import LoggerFactory from './factory';
import type { ILoggerOptions } from './types';

export type { ILoggerEngine, ILoggerOptions, Logger } from './types';

export { default as ConsoleEngine } from './engines/console';
export { default as SentryEngine } from './engines/sentry';

export default function factory(opts?: ILoggerOptions): InstanceType<typeof LoggerFactory> {
  return new LoggerFactory(opts);
}
