import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Step, StepLabel, Stepper as MuiStepper, StepperProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import withFullWidth from '../../../HOCs/withFullWidth';
import { SEARCH_PARAMS } from '../../../constants/searchParams';

interface IProps extends StepperProps {
  activeStep: number;
  steps: string[];
  subStepsGifts?: {
    count: number;
    activeOnStepIndex: number;
  };
}

const SubStep: FC = () => {
  return (
    <path
      d='M13.2578 22.4998V14.4998H19.2578V20.4998C19.2578 21.0302 19.0471 21.5389 18.672 21.914C18.297 22.2891 17.7882 22.4998 17.2578 22.4998H13.2578ZM11.2578 8.49979V12.4998H5.25781C4.9926 12.4998 4.73824 12.3944 4.55071 12.2069C4.36317 12.0194 4.25781 11.765 4.25781 11.4998V9.49979C4.25781 9.23458 4.36317 8.98022 4.55071 8.79269C4.73824 8.60515 4.9926 8.49979 5.25781 8.49979H5.79281C5.2955 7.64054 5.1355 6.62705 5.34392 5.65639C5.55233 4.68572 6.11427 3.82724 6.92048 3.24787C7.72668 2.6685 8.71953 2.40965 9.70598 2.52163C10.6924 2.63362 11.602 3.10845 12.2578 3.85379C12.9136 3.10845 13.8232 2.63362 14.8096 2.52163C15.7961 2.40965 16.7889 2.6685 17.5951 3.24787C18.4014 3.82724 18.9633 4.68572 19.1717 5.65639C19.3801 6.62705 19.2201 7.64054 18.7228 8.49979H19.2578C19.523 8.49979 19.7774 8.60515 19.9649 8.79269C20.1525 8.98022 20.2578 9.23458 20.2578 9.49979V11.4998C20.2578 11.765 20.1525 12.0194 19.9649 12.2069C19.7774 12.3944 19.523 12.4998 19.2578 12.4998H13.2578V8.49979H11.2578ZM11.2578 22.4998H7.25781C6.72738 22.4998 6.21867 22.2891 5.8436 21.914C5.46853 21.5389 5.25781 21.0302 5.25781 20.4998V14.4998H11.2578V22.4998ZM11.2578 8.49979V6.49979C11.2578 6.10423 11.1405 5.71755 10.9208 5.38865C10.701 5.05975 10.3886 4.80341 10.0232 4.65203C9.65773 4.50066 9.25559 4.46105 8.86763 4.53822C8.47967 4.61539 8.1233 4.80588 7.8436 5.08558C7.56389 5.36529 7.37341 5.72165 7.29624 6.10961C7.21907 6.49758 7.25868 6.89971 7.41005 7.26516C7.56143 7.63061 7.81777 7.94297 8.14667 8.16273C8.47557 8.3825 8.86225 8.49979 9.25781 8.49979H11.2578ZM13.2578 8.49979H15.2578C15.6534 8.49979 16.0401 8.3825 16.369 8.16273C16.6979 7.94297 16.9542 7.63061 17.1056 7.26516C17.2569 6.89971 17.2966 6.49758 17.2194 6.10961C17.1422 5.72165 16.9517 5.36529 16.672 5.08558C16.3923 4.80588 16.036 4.61539 15.648 4.53822C15.26 4.46105 14.8579 4.50066 14.4924 4.65203C14.127 4.80341 13.8146 5.05975 13.5949 5.38865C13.3751 5.71755 13.2578 6.10423 13.2578 6.49979V8.49979Z'
      fill='inherit'
    />
  );
};

const StyledSubStep = styled.svg<{ active: number }>(({ theme, active }) => ({
  position: 'absolute',
  top: '0',
  right: '0',
  transform: 'translateX(50%)',
  fill: active === 1 ? theme.palette.text.primary : theme.palette.grey[400],
}));

const StyledStepper = styled(MuiStepper)(({ theme }) => ({
  margin: 0,
}));

const Stepper: FC<IProps> = ({ activeStep, steps, subStepsGifts, ...rest }) => {
  const { t } = useTranslation();
  // ToDo: ManFit Upsells Test Temporary
  const [searchParams] = useSearchParams();
  const hasManfitUpsellsParam = searchParams.has(SEARCH_PARAMS.MANFIT_UPSELLS);
  const subStepsGiftsEnabled = { count: hasManfitUpsellsParam ? 1 : 0, activeOnStepIndex: -1, ...subStepsGifts };

  return (
    <StyledStepper activeStep={activeStep} alternativeLabel {...rest}>
      {steps.map((label, index) => (
        <Step
          key={label}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography fontWeight={index === activeStep ? 700 : 300} fontSize={11} mb={2}>
            {t(label)}
          </Typography>
          <StepLabel color='inherit' />
          {subStepsGiftsEnabled && index < subStepsGiftsEnabled.count ? (
            <StyledSubStep
              active={subStepsGiftsEnabled.activeOnStepIndex === index ? 1 : 0}
              width='25'
              height='25'
              viewBox='0 0 25 25'
            >
              <SubStep />
            </StyledSubStep>
          ) : null}
        </Step>
      ))}
    </StyledStepper>
  );
};

export default withFullWidth(Stepper);
