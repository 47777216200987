import { lottieExtensionBuilder } from '@divkitframework/divkit/client-hydratable';
import Lottie from 'lottie-web/build/player/lottie';
import type { IExtensionBuilder } from './types';
import { EXTENSION_NAMES } from './constants';

export default class LottieExtensionBuilder implements IExtensionBuilder {
  readonly name = EXTENSION_NAMES.LOTTIE;

  readonly extension: IExtensionBuilder['extension'];

  constructor() {
    this.extension = lottieExtensionBuilder(Lottie.loadAnimation);
  }
}
